import React from 'react'
import { Divider } from '@material-ui/core'
import styled from 'styled-components'
import FormHeader from '../ReferralForm/FormHeader'
import AttachedBundles from './AttachedBundles'
import { ReferralRequestFormData } from './types'

interface Props {
  form: ReferralRequestFormData
  practiceFacilityID?: number | null
}

const FormDivider = styled(Divider)`
  margin: 20px 0px;
`

const AttachedBundlesForm: React.FC<Props> = ({
  form,
  practiceFacilityID,
}: Props) => {
  return (
    <div>
      <FormHeader title="Attached Bundles" />
      <AttachedBundles
        ID={form.ID}
        viewOnly={!form.ContentEditable}
        practiceFacilityID={practiceFacilityID}
      />
      <FormDivider />
    </div>
  )
}

export default AttachedBundlesForm
