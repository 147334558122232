import React, { useEffect, useState } from 'react'
import { StalePageNotifier } from './StalePageNotifier'

interface PollerProps {
  callback: any
  interval: number
  timeout?: number
  active: boolean
}

const registerPoller = (fn: () => void, interval: number) => {
  return setInterval(() => fn(), interval)
}

const deregisterPoller = (pollID: number) => {
  return clearInterval(pollID)
}

export const Poller = ({
  active,
  callback,
  interval,
  timeout = 30,
}: PollerProps) => {
  const [pollID, setPollID] = useState(0)
  const [stale, setStale] = useState(false)

  /* register the poller or de-register if set to inactive */
  useEffect(() => {
    if (active) {
      const newPollID = registerPoller(callback, interval)
      setPollID(newPollID)
      console.debug(`Setting pollID ${newPollID}`)

      /* This is how we clean up the lingering intervals */
      return () => {
        deregisterPoller(newPollID)
        setPollID(0)
        console.debug(`Cleaning up pollID: ${newPollID}`)
      }
    } else {
      deregisterPoller(pollID)
      setPollID(0)
      console.debug('Clearing pollID')
    }
  }, [active])

  useEffect(() => {
    /* If we have a pollID, set a timeout so that it clears if this component stays rendered for a long time */
    if (pollID !== 0) {
      console.debug(`Setting a default timeout for pollID ${pollID}`)
      const timeoutID = setTimeout(
        () => {
          console.debug(`Default timeout reached, deregistered: ${pollID}`)
          deregisterPoller(pollID)
          setStale(true)
        },
        timeout * 60 * 1000
      )

      return () => {
        clearTimeout(timeoutID)
        console.debug('Cleaning up default timeout')
      }
    }
  }, [pollID])

  /*
  There's a small "dev utility" in here, whereas in a browser console you can set
    window['dev_pause_poll'] = true
  in order to pause the polling feature. To re-enable it:
    window['dev_pause_poll'] = false
  */

  // @ts-ignore
  const [devOverride, setDevOverride] = useState(window['dev_pause_poll'])
  useEffect(() => {
    if (pollID !== 0 && devOverride) {
      deregisterPoller(pollID)
    }
  }, [devOverride, pollID])

  return <StalePageNotifier open={stale} />
}
