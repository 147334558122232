import React from 'react'
import { Grid } from '@material-ui/core'
import FormHeader from './FormHeader'
import FormRow from './FormRow'
import TextField from '../TextField'
import PhoneField from '../PhoneField'
import PhoneExtField from '../PhoneExtField'
import { ReferralForm } from './types'
import { ReferralRequestForm } from '../ReferralRequestForm/types'

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
}

const PhysicianDetailsForm: React.FC<Props> = ({ form, showErrors }) => {
  return (
    <div>
      <FormHeader title="Ordering Physician Details" />

      <FormRow>
        <Grid item xs={4}>
          <TextField
            label="Name"
            value={form.data.OrderingPhysName || ''}
            onChange={form.setters.OrderingPhysName}
            readOnly={
              form.data.EntityName === 'referral'
                ? !form.data.Edit || !!form.data.ArchivedAt
                : !form.data.ContentEditable
            }
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneField
            label="Phone"
            value={form.data.OrderingPhysPhone || ''}
            onChange={form.setters.OrderingPhysPhone}
            error={showErrors ? form.errors.OrderingPhysPhone : null}
            readOnly={
              form.data.EntityName === 'referral'
                ? !form.data.Edit || !!form.data.ArchivedAt
                : !form.data.ContentEditable
            }
          />
        </Grid>
        <Grid item xs={2}>
          <PhoneExtField
            label="Phone Ext."
            value={form.data.OrderingPhysPhoneExt || ''}
            onChange={form.setters.OrderingPhysPhoneExt}
            error={showErrors ? form.errors.OrderingPhysPhoneExt : null}
            readOnly={
              form.data.EntityName === 'referral'
                ? !form.data.Edit || !!form.data.ArchivedAt
                : !form.data.ContentEditable
            }
          />
        </Grid>
      </FormRow>

      <FormRow>
        <Grid item xs={4}>
          <TextField
            label="Email"
            value={form.data.OrderingPhysEmail || ''}
            onChange={form.setters.OrderingPhysEmail}
            error={showErrors ? form.errors.OrderingPhysEmail : null}
            readOnly={
              form.data.EntityName === 'referral'
                ? !form.data.Edit || !!form.data.ArchivedAt
                : !form.data.ContentEditable
            }
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneField
            label="Fax"
            value={form.data.OrderingPhysFax || ''}
            onChange={form.setters.OrderingPhysFax}
            error={showErrors ? form.errors.OrderingPhysFax : null}
            readOnly={
              form.data.EntityName === 'referral'
                ? !form.data.Edit || !!form.data.ArchivedAt
                : !form.data.ContentEditable
            }
          />
        </Grid>
      </FormRow>
    </div>
  )
}

export default PhysicianDetailsForm
