import styled from 'styled-components'
import { COLORS } from '../../utils/colors'

export const MainComponent = styled.main`
  background-color: ${COLORS.BW.WHITE};
  padding: 24px;
  width: 100%;
  padding-bottom: 500px;
`
export const Root = styled.div`
  flexgrow: 1;
`

export const Content = styled.div`
  padding-top: 60px;
`

export const AppFrameStyled = styled.div`
  height: 100%;
  width: 100%;
  z-index: 1;
  position: relative;
  display: flex;
`
