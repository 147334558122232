import React from 'react'
import { ReactComponent as ZeroLogo } from '../../assets/tzc-zero-logo.svg'
import './index.css'

interface Props {
  spin?: boolean
}

const LoadingIndicator = (props: Props) => {
  return <ZeroLogo />
}

export default LoadingIndicator
