import React from 'react'
import SearchField from '../SearchField'
import AutocompleteField from '../AutocompleteField'
import SelectField from '../SelectField'
import useApi from '../../hooks/useApi'
import { useReferralInvoiceFilters } from './hooks'
import styled from 'styled-components'
import ManagedDateInput from '../Inputs/managedDateInput'

const FiltersContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-top: 15px;
`

interface Props {
  refetchReferralInvoices: (params: any) => void
}

const ReferralInvoiceFilters: React.FC<Props> = (props: Props) => {
  const {
    searchText,
    setSearchText,
    searchDob,
    setSearchDob,
    selectedOrg,
    setSelectedOrg,
    selectedStatus,
    setSelectedStatus,
    selectedEmployer,
    setSelectedEmployer,
  } = useReferralInvoiceFilters(props.refetchReferralInvoices)

  const { data: organizations } = useApi({
    route: '/invoice_organization',
  })

  const { data: statuses } = useApi({
    route: '/referral_invoice_statuses',
  })

  const { data: employers } = useApi({
    route: '/referral_employers',
  })

  if (!organizations || !statuses || !employers) {
    return null
  }

  return (
    <FiltersContainer>
      <SearchField
        label="Search Referrals"
        value={searchText}
        onChange={setSearchText}
      />
      <ManagedDateInput
        label="DOB"
        value={searchDob}
        setter={({ name, value }) => setSearchDob(value)}
        fullWidth={false}
      />
      <AutocompleteField
        label="Organization"
        value={selectedOrg}
        onChange={setSelectedOrg}
        options={organizations.map((org: any) => ({
          name: org.OrganizationName,
          value: org.OrganizationID,
        }))}
      />
      <SelectField
        multiple
        label="Status"
        value={selectedStatus}
        onChange={setSelectedStatus}
        options={statuses.map((stat: any) => ({
          name: stat.Description,
          value: stat.ID,
        }))}
      />
      <SelectField
        multiple
        label="Employer"
        value={selectedEmployer}
        onChange={setSelectedEmployer}
        options={employers.map((stat: any) => ({
          name: stat.EmployerName,
          value: stat.EmployerID,
        }))}
      />
    </FiltersContainer>
  )
}

export default ReferralInvoiceFilters
