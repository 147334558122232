import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'

const PermissionDenied = () => {
  return (
    <Alert severity="warning" style={{ marginTop: '20px' }}>
      <AlertTitle>Sorry, this page requires additional permission.</AlertTitle>
      Please contact{' '}
      <a href="mailto:providers@zero.health">providers@zero.health</a> for
      viewing or editing access.
    </Alert>
  )
}

export default PermissionDenied
