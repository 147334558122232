import React from 'react'
import { Card, CardContent, CardHeader, IconButton } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'
import { AgingActivitiesWarningBanner } from './WarningBanner'
import ActivityItem, { Activity } from './ActivityItem'
import SwitchField from '../SwitchField'

interface Props {
  activities: Activity[] | null
  title: string
  NoActivityItem: any
  onAck: Function
  refresh: () => void
  showBanner?: boolean
  allowAck?: boolean
  weekOld?: number
  monthOld?: number
  oldestFirstValue?: boolean
  oldestFirstSetter?: (value: boolean) => void
}

const Refresher: React.FC<{ refresh: () => void }> = (props) => {
  return (
    <IconButton onClick={() => props.refresh()}>
      <Refresh />
    </IconButton>
  )
}

const ActivityList: React.FC<Props> = ({
  allowAck,
  activities,
  weekOld = 0,
  monthOld = 0,
  onAck,
  refresh,
  title,
  NoActivityItem,
  showBanner,
  oldestFirstValue,
  oldestFirstSetter,
}) => {
  const hasActivities =
    activities && activities.length !== undefined && activities.length > 0

  return (
    <Card>
      <CardHeader
        title={
          <div>
            {title}
            {oldestFirstSetter && (
              <span style={{ float: 'right' }}>
                <SwitchField
                  label="Oldest First"
                  value={!!oldestFirstValue}
                  onChange={oldestFirstSetter}
                />
              </span>
            )}
          </div>
        }
        action={<Refresher refresh={refresh} />}
      />
      <CardContent>
        {hasActivities && showBanner && (
          <AgingActivitiesWarningBanner weekOld={weekOld} monthOld={monthOld} />
        )}
        {hasActivities ? (
          activities.map((activity, i) => {
            return (
              <ActivityItem
                key={activity.ID}
                activity={activity}
                allowAck={!!allowAck}
                onAck={onAck}
                growOpts={{ timeout: 350 * i, direction: 'left' }}
              />
            )
          })
        ) : (
          <NoActivityItem />
        )}
      </CardContent>
    </Card>
  )
}

export default ActivityList
