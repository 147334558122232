import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import styled from 'styled-components'

const InvoicedIndicator = styled(Grid)`
  margin-left: 5px;
  display: inline-flex;
  align-items: center;
  color: green;
`

const InvoicedIcon = styled(CheckCircleOutlineIcon)`
  margin-left: 5px;
`

interface Props {
  title: string
  nextToTitle?: React.ReactElement | null
  description?: string | React.ReactElement
  isInvoiced?: boolean
}

const FormHeader: React.FC<Props> = ({
  title,
  nextToTitle,
  description,
  isInvoiced,
}) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Grid container direction="row">
        <Typography variant="h5">{title}</Typography>
        {isInvoiced ? (
          <InvoicedIndicator>
            <Typography>- Invoiced</Typography>
            <InvoicedIcon />
          </InvoicedIndicator>
        ) : null}
        {nextToTitle}
      </Grid>
      {description && <Typography variant="caption">{description}</Typography>}
    </div>
  )
}

export default FormHeader
