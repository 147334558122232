import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Card, CardContent, Typography } from '@material-ui/core'
import ActivityList from '../Activity/ActivityList'
import { Poller } from '../Poller'
import useApi from '../../hooks/useApi'
import { Activity } from '../Activity/ActivityItem'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles: any = () => {
  return {
    activityCard: {
      margin: 5,
    },
    noActivityContent: {
      textAlign: 'center',
    },
  }
}

export const RecentActivity = () => {
  const [poll] = useState<boolean>(true)
  const [activities, setActivities] = useState<Activity[] | null>([])

  const { data = [], refetch } = useApi(
    {
      route: '/activity/recent',
      sort: ['referral_activity.id', 'desc'],
    },
    {
      errorMessage:
        'There was a problem fetching recent activity, please try refreshing the page or contact your administrator.',
    }
  )

  useEffect(() => {
    if (data) {
      setActivities(filterAck(data))
    } else {
      setActivities([])
    }
  }, [data])

  const filterAck = (activities: Activity[]) => {
    if (
      activities === null ||
      activities === undefined ||
      activities.length === 0
    )
      return null
    return activities.filter((item: Activity) => !item.RequiresAck)
  }

  return (
    <Card>
      <ActivityList
        title="Recent Activity"
        NoActivityItem={NoActivityItem}
        activities={activities}
        refresh={refetch}
        allowAck={false}
        onAck={() => {}}
      />
      <Poller active={poll} callback={refetch} interval={20000} />
    </Card>
  )
}

const NoActivityItem = withStyles(styles)(({
  classes,
}: {
  classes: ClassNameMap<string>
}) => {
  return (
    <Card className={classes.activityCard}>
      <CardContent className={classes.noActivityContent}>
        <Typography variant="body2">There is no recent activity.</Typography>
      </CardContent>
    </Card>
  )
})
