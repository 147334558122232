import React from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { RecentActivity } from '../RecentActivity'
import { NeedsReview } from '../NeedsReview'
import { ReferralStatusBar } from '../ReferralStatusBar'
import { useAuth } from '../../Auth'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles: any = () => {
  return {
    redirect: {
      display: 'inline-block',
      marginLeft: '10px',
    },
    stat: {
      maxWidth: 250,
      display: 'inline-block',
      margin: 10,
    },
    statHeader: {
      fontSize: 32,
      textAlign: 'center',
    },
  }
}

const dashboard = ({ classes }: { classes: ClassNameMap<string> }) => {
  const { access } = useAuth()
  if (!access) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={4} />
        <Grid item xs={4}>
          <CircularProgress />
          <Typography className={classes.redirect} variant="body1">
            Redirecting you to login...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const hasAccess =
    access.CanAccessInvoices ||
    access.CanAccessReferrals ||
    access.CanManageUsers ||
    access.CanViewOrgPaidClaimsReport ||
    access.CanViewOrgQuarterlyReport // case: report-only viewers

  if (!hasAccess) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Typography variant="h5">
            You are missing necessary permissions in order to use goZERO.
          </Typography>
          <br />
          <Typography variant="body1">
            If you have a local goZERO administrator, please contact them to
            receive permissions.
          </Typography>
          <Typography variant="body2">
            If you are an administrator or are continuing to have issues, please
            notify your ZERO contact or message us at{' '}
            <a href="mailto:providers@zero.health">providers@zero.health</a>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ReferralStatusBar />
      </Grid>
      <Grid item xs={6}>
        {access.CanAccessReferrals && <NeedsReview />}
      </Grid>
      <Grid item xs={6}>
        {access.CanAccessReferrals && <RecentActivity />}
      </Grid>
    </Grid>
  )
}

export const Dashboard = withStyles(styles)(dashboard)
