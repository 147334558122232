import React from 'react'
import styled from 'styled-components'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
} from '@material-ui/core'
import ReferenceTextField from './ReferenceTextField'

interface Props {
  label: string
  value: string
  onChange: (val: string) => void
  error?: string | null
  readOnly?: boolean
}

const PhoneExtContainer = styled.div`
  .MuiInputBase-input.Mui-disabled {
    color: initial;
  }

  .MuiInput-underline.Mui-disabled:before {
    border: none;
  }
`

const PhoneExtField: React.FC<Props> = (props: Props) => {
  const { label, value, onChange, error, readOnly } = props

  if (readOnly) {
    return <ReferenceTextField label={label} value={value} />
  }

  return (
    <PhoneExtContainer>
      <FormControl fullWidth error={!!error}>
        <InputLabel>{label}</InputLabel>
        <Input
          value={value}
          onChange={(e: any) => onChange(e.target.value)}
          disabled={readOnly}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </PhoneExtContainer>
  )
}

export default PhoneExtField
