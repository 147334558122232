import { setupWorker } from 'msw'

const API_URL = 'https://local.zero.health'

const routes: any = [
  // Add network intercepted routes here, example:
  // get users for convo, for mentions
  // rest.get(
  //   `${API_URL}/gozero/referral_request/:referralRequestId/users`,
  //   (_req, res, ctx) => {
  //     return res(ctx.status(200), ctx.json({ Data: [] }))
  //   }
  // ),
  // get convo
  // rest.get(
  //   `${API_URL}/gozero/referral_request/:referralRequestId/conversations`,
  //   (_req, res, ctx) => {
  //     return res(ctx.status(200), ctx.json({ Data: data.fakeConversations }))
  //   }
  // ),
  // get activities
  // rest.get(
  //   `${API_URL}/gozero/referral_request/:referralRequestId/activities`,
  //   (_req, res, ctx) => {
  //     return res(ctx.status(200), ctx.json({ Data: [] }))
  //   }
  // ),
  // post message
  // rest.post(
  //   `${API_URL}/gozero/referral_request/:referralRequestId/conversation/:conversationId/message`,
  //   (_req, res, ctx) => {
  //     return res(ctx.status(200))
  //   }
  // ),
]

const serviceWorker = setupWorker(...routes)

export default serviceWorker
