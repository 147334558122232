import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  LinearProgress,
  Typography,
  SvgIconTypeMap,
  SvgIconProps,
} from '@material-ui/core'
import {
  CheckCircle as IconCheckCircle,
  Create as IconCreate,
  CallReceived as IconCallReceived,
  Done as IconDone,
  Send as IconSend,
  HourglassEmpty as IconHourglass,
  SentimentVeryDissatisfied as IconNonResponsive,
} from '@material-ui/icons'
import _ from 'lodash'
import useApi from '../../hooks/useApi'
import { Poller } from '../Poller'
import AddNewReferralBtn from '../Referrals/AddNewReferralButton'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import referralStatusConsts from '../ReferralForm/statuses'

const styles = (theme: any) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
})

interface ReferralStatusBarProps {
  classes: ClassNameMap
}

interface ReferralsItem {
  Amount: number
  Status: string
  StatusID: number
}

const referralStatusBar = ({ classes }: ReferralStatusBarProps) => {
  const [poll] = useState(true)
  const [groupedReferrals, setGroupedReferrals] = useState<{
    [key: number]: number
  } | null>({})
  const { data: statusSummary, refetch: refetchStatusSummary } = useApi({
    route: '/referral_analytics/statuses',
  })

  const { data: referralStatuses = [] } = useApi({
    route: '/combined_statuses',
  })

  useEffect(() => {
    const grouped = groupReferrals(statusSummary)
    setGroupedReferrals(grouped)
  }, [statusSummary])

  const groupReferrals = (referrals: ReferralsItem[] | null) => {
    return _.reduce(
      referrals,
      (item: { [key: number]: number }, props: ReferralsItem) => {
        item[props.StatusID] = props.Amount
        return item
      },
      {}
    )
  }

  interface IconType {
    icon: SvgIconTypeMap | SvgIconProps
  }

  const statusInfo: { [key: number]: IconType } = {
    '999': {
      icon: <IconSend />,
    },
    [referralStatusConsts.REFERRAL_STATUS_NEW]: {
      icon: <IconCreate />,
    },
    [referralStatusConsts.REFERRAL_STATUS_ACCEPTED]: {
      icon: <IconCallReceived />,
    },
    [referralStatusConsts.REFERRAL_STATUS_AWAITING_ORDERS]: {
      icon: <IconHourglass />,
    },
    [referralStatusConsts.REFERRAL_STATUS_AWAITING_SCHEDULING]: {
      icon: <IconCheckCircle />,
    },
    [referralStatusConsts.REFERRAL_STATUS_PATIENT_NON_RESPONSIVE]: {
      icon: <IconNonResponsive />,
    },
    [referralStatusConsts.REFERRAL_STATUS_SCHEDULED]: {
      icon: <IconDone />,
    },
  }

  if (!referralStatuses || referralStatuses.length === 0 || !statusSummary)
    return <LinearProgress />

  return (
    <Card className={classes.root}>
      <CardHeader title="Referrals Snapshot" action={<AddNewReferralBtn />} />
      <CardContent>
        <Stepper activeStep={Object.keys(statusInfo).length} alternativeLabel>
          {referralStatuses.map((status: { ID: number; Descr: string }) => {
            if (statusInfo[status.ID] === undefined) return false

            const count =
              groupedReferrals &&
              groupedReferrals[status.ID] !== undefined &&
              Object.keys(groupedReferrals).length !== 0
                ? groupedReferrals[status.ID]
                : 0

            // @todo this needs to be integrated into the useQueryParams better
            const qsParams = `filter=${encodeURIComponent(
              `StatusID[]=${status.ID}`
            )}`

            return (
              <Step key={status.ID}>
                <StepLabel icon={<>{statusInfo[status.ID].icon}</>}>
                  <Typography variant="h6">{count}</Typography>
                  <Link
                    to={{
                      pathname: '/referrals',
                      search: qsParams,
                      state: { StatusID: status.ID },
                    }}>
                    {status.Descr}
                  </Link>
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Poller
          active={poll}
          callback={refetchStatusSummary}
          interval={15000}
        />
      </CardContent>
    </Card>
  )
}

export const ReferralStatusBar = withStyles(styles)(referralStatusBar)
