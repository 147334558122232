import React from 'react'
import { Typography } from '@material-ui/core'
import { ReferralRequestPrices } from '../ReferralRequestPrices'

interface Props {
  ID?: number | null
  practiceFacilityID?: number | null
  viewOnly?: boolean
}

const Message = () => {
  return (
    <Typography variant="caption">
      Before attaching bundles, the request must have an organization and
      facility selected.
    </Typography>
  )
}

const AttachedBundles: React.FC<Props> = ({
  ID,
  viewOnly,
  practiceFacilityID,
}: Props) => {
  // cannot use form data here, we need the referral state from server
  const canManageBundles = ID && practiceFacilityID

  if (!canManageBundles) {
    return <Message />
  }

  return (
    <ReferralRequestPrices
      referralRequestID={ID as number}
      hintMainCPT={''}
      viewOnly={viewOnly}
    />
  )
}

export default AttachedBundles
