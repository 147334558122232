import React from 'react'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core'
import ReferenceTextField from './ReferenceTextField'

interface Props {
  label: string
  value: string
  onChange: (val: string) => void
  error?: string | null
  multiline?: boolean
  readOnly?: boolean
  rows?: number
  disabled?: boolean
  readOnlyDisplay?: React.ReactNode | null
  InputProps?: any
}

const TextField: React.FC<Props> = ({
  label,
  value,
  onChange,
  error,
  multiline,
  readOnly,
  rows,
  disabled,
  readOnlyDisplay,
  InputProps,
}) => {
  // component may be intitializing
  if (!onChange) {
    return null
  }

  if (readOnly) {
    return <ReferenceTextField label={label} value={readOnlyDisplay || value} />
  }

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Input
        value={value}
        onChange={(evt: any) => onChange(evt.target.value)}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        maxRows={20}
        fullWidth
        {...InputProps}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default TextField
