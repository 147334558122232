import React, { useEffect, useState } from 'react'
import { Skeleton } from '@material-ui/lab'
import { Grid, Typography } from '@material-ui/core'
import Autocomplete from '../AutocompleteField'
import useApi from '../../hooks/useApi'
import { ReferralForm } from './types'
import ReferenceTextField from '../ReferenceTextField'
import { ReferralRequestForm } from '../ReferralRequestForm/types'
import styled from 'styled-components'
import { useAuth } from '../../Auth'

const HelperText = styled(Typography).attrs((props) => props as any)`
  cursor: pointer;
  overflow: hidden;
  white-space: ${(props) => (props.showLong ? 'inherit' : 'nowrap')};
  text-overflow: ${(props) => (props.showLong ? 'inherit' : 'ellipsis')};
  max-width: 250px;
`

const HelperTextLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
`

const Spacer = styled.div.attrs((props) => props as any)`
  margin-top: ${(props) => props.height};
`

// remove Quest from the organizations options
// @todo: SD-1539: is this still relevant?
const QUEST_ID = 1

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
  readOnly?: boolean
}

const SelectOrganization: React.FC<Props> = ({
  form,
  showErrors,
  readOnly,
}) => {
  const [selectedOrg, setSelectedOrg] = useState<any | null>(null)
  const [showLongText, setShowLongText] = useState<boolean>(true)
  const { access } = useAuth()
  // need to know if viewing vs creating, and use the correct route;
  // we need this to restrict which orgs the user can select when creating.
  // further, this organization selector should only ever be used in w/
  // a referral/ref request, such that a member is always in context (thus,
  // having an employerID via the member)
  const { data, loading } = useApi(
    readOnly
      ? { route: '/referral_organization' }
      : {
          route: '/referral_organization_editor',
          filter: {
            EmployerID: form.data?.EmployerMember?.EmployerID,
          },
        }
  )

  useEffect(() => {
    if (!data || !form) return
    if (!form.data.OrganizationID) setSelectedOrg(null)
    data.forEach((org: any) => {
      if (org.OrganizationID === form.data.OrganizationID) setSelectedOrg(org)
    })
  }, [data, form])

  if (loading || !data) {
    return <Skeleton />
  }
  const options = data
    .filter((org: any) => org.OrganizationID !== QUEST_ID)
    .map((org: any) => ({
      name: org.OrganizationName,
      value: org.OrganizationID,
      inNetwork: org.InNetwork,
    }))
    .sort((a: any, b: any) => {
      if (!!a.inNetwork && !b.inNetwork) return -1
      if (!a.inNetwork && !!b.inNetwork) return 1
      return a.name.localeCompare(b.name)
    })

  const displayHelperText = () => {
    if (!access.IsZeroUser) {
      return null
    }

    const { LoaOnly, PHANotes } = selectedOrg
    if (!LoaOnly && !PHANotes) return null

    let text = ''
    if (LoaOnly && !PHANotes) text = 'LOA Only'
    if (!LoaOnly && PHANotes) text = PHANotes
    if (LoaOnly && PHANotes) text = `LOA Only - ${PHANotes}`
    return (
      <Grid>
        <HelperTextLabel>Notes</HelperTextLabel>
        <HelperText
          showLong={showLongText}
          addTopMargin={!!readOnly}
          onClick={() => setShowLongText(!showLongText)}>
          {text}
        </HelperText>
      </Grid>
    )
  }

  if (readOnly) {
    return (
      <>
        <ReferenceTextField
          label="Organization"
          value={selectedOrg ? selectedOrg.OrganizationName : ''}
        />
        <Spacer height="8px" />
        {selectedOrg ? displayHelperText() : null}
      </>
    )
  }
  return (
    <>
      <Autocomplete
        label="Organization"
        value={form.data.OrganizationID}
        onChange={form.setters.OrganizationID}
        options={options}
        error={showErrors ? form.errors.OrganizationID : null}
        PassthroughProps={{
          groupBy: (opt: any) => {
            return opt.inNetwork ? 'In Network' : 'Out Of Network'
          },
          getOptionDisabled: (opt: any) => {
            return !opt.inNetwork
          },
        }}
      />
      <Spacer height="3px" />
      {selectedOrg ? displayHelperText() : null}
    </>
  )
}

export default SelectOrganization
