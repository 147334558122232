import React from 'react'
import { Grid } from '@material-ui/core'

const FormRow: React.FC<{ children: any }> = ({ children }) => (
  <Grid container spacing={4} style={{ marginBottom: '30px' }}>
    {children}
  </Grid>
)

export default FormRow
