/*
https://react.dev/reference/react/useSyncExternalStore#usage
*/

import { useSyncExternalStore } from 'react'

/*
Important thing to understand about the useLastValue hook: it's effectively
a global singleton... that means that if you pass the same constKey to the hook 
upon initialization, that value will be the same across all components that use it 
(which is by design). That same concept applies to all **instances of the same component**
as well. In order to encourage some kind of globally unique keys for the constKey values, 
instead of declaring them in components, you should articulate them here in lastValueKeys,
then import them into the components that need them. This will help to avoid collisions.
*/
export enum lastValueKeys {
  NeedsReviewOldestFirst = 'needsReview.OldestFirst',
}

const storageKey = 'last_value_cache'
let data = JSON.parse(localStorage.getItem(storageKey) || '{}')
let listeners: any[] = []

export default function useLastValue(
  constKey: lastValueKeys,
  initialValue: any
) {
  useSyncExternalStore(subscribe, getSnapshot)

  if (!constKey) {
    throw new Error('useLastValue: constKey is required')
  }
  if (initialValue === undefined) {
    throw new Error('useLastValue: initialValue is required')
  }

  if (!(constKey in data)) {
    set(constKey, initialValue)
  }

  return [data[constKey], (value: any) => set(constKey, value)]
}

function set(constKey: string, value: any) {
  data = { ...data, [constKey]: value }
  localStorage.setItem(storageKey, JSON.stringify(data))
  emitChange()
}

function getSnapshot() {
  return data
}

function subscribe(listener: any) {
  listeners = [...listeners, listener]
  return () => {
    listeners = listeners.filter((l: any) => l !== listener)
  }
}

function emitChange() {
  for (let l of listeners) {
    l()
  }
}
