import React from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { Tabs, Tab, Paper } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Users from './Users'
import PracticeFacilities from './PracticeFacilities'
import OrganizationNavigation from './OrganizationNavigation'
import { HeaderTitle } from './components'
import PermissionDenied from '../PermissionDenied'
import useQueryParams from '../../hooks/useQueryParams'
import * as T from './types'

const UserManagementContent = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0%;
    }
    to {
      opacity: 100%;
    }
  }

  animation-duration: 1s;
  animation-name: fadeIn;
  margin-top: 10px;
`

const LoadingUserManagment = () => {
  return (
    <div style={{ padding: '30px' }}>
      <Skeleton variant="text" width={200} height={40} />
      <div style={{ height: '10px' }} />
      <Skeleton variant="rect" width={800} height={300} />
    </div>
  )
}

const TabContent: React.FC<{ index: number; children: any[] }> = ({
  index,
  children,
}) => {
  return (
    <div style={{ marginTop: '10px' }}>
      <Paper elevation={3} style={{ padding: '10px', paddingTop: '5px' }}>
        {children[index] || null}
      </Paper>
    </div>
  )
}

const UserManagement = () => {
  const hist = useHistory()
  const loc = useLocation()
  const { params, setParams } = useQueryParams()
  const [selectedOrg, setSelectedOrg] = React.useState<T.UserOrganization>()

  const currentTabIndex = params.tab || 0
  const setCurrentTabIndex = (index: number) => setParams({ tab: index })

  React.useEffect(() => {
    if (selectedOrg) {
      hist.push({
        pathname: `/user_management/organization/${selectedOrg.ID}`,
        search: loc.search,
      })
    }
  }, [selectedOrg])

  return (
    <div style={{ display: 'flex', position: 'relative' }}>
      <OrganizationNavigation
        selectedOrg={selectedOrg}
        setSelectedOrg={setSelectedOrg}
      />
      {selectedOrg ? (
        <UserManagementContent>
          <HeaderTitle>{selectedOrg.Name}</HeaderTitle>
          {selectedOrg.CanManageOtherUsers ? (
            <>
              <Tabs
                value={currentTabIndex}
                onChange={(_evt, value: number) => setCurrentTabIndex(value)}>
                <Tab label="Users" />
                <Tab label="Practice Facilities" />
              </Tabs>
              <TabContent index={currentTabIndex}>
                <Users organizationId={selectedOrg.ID} />
                <PracticeFacilities organizationId={selectedOrg.ID} />
              </TabContent>
            </>
          ) : (
            <PermissionDenied />
          )}
        </UserManagementContent>
      ) : (
        <LoadingUserManagment />
      )}
    </div>
  )
}

export default UserManagement
