import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grow,
} from '@material-ui/core'
import { UserAvatar } from '../UserAvatar'
import { MentionDisplayer } from '../Mention'
import { ActivityActions } from '../../actions'
import { momentCalendarFormatter } from '../../utils'

interface Props {
  referralRequestId: number
  activity: any
  growOpts: any // this should probably be internal
  title: string
  ack?: boolean
  showViewLink?: boolean
  ackAndView?: boolean
  onAck: Function
  linkDescription: string
}

const ReferralRequestActivityItem: React.FC<Props> = (props) => {
  const hist = useHistory()
  const [ackd, setAckd] = React.useState(false)

  const practiceFacilityName = props.activity.PracticeFacilityName

  const ackHandler = async () => {
    const { activity, onAck } = props
    await ActivityActions.ackActivity({ id: activity.ID })
    setAckd(true)
    onAck(activity.ID)
  }

  const ackAndViewHandler = async () => {
    const { activity, onAck } = props
    await ActivityActions.ackActivity({ id: activity.ID })
    onAck(activity.ID)
    setAckd(true)
    hist.push(`/referral_request/${props.referralRequestId}`)
    return onAck(activity.ID)
  }

  const Subheader = () => {
    const createdAt = momentCalendarFormatter(props.activity.CreatedAt)
    const refLink = (
      <Link to={`/referral_request/${props.referralRequestId}`}>
        {props.linkDescription}
      </Link>
    )

    return (
      <span>
        {createdAt} for {refLink}{' '}
        {practiceFacilityName && `at ${practiceFacilityName}`}
      </span>
    )
  }

  return (
    <Grow key={props.activity.ID} in timeout={props.growOpts.timeout}>
      <Card style={{ margin: '5px' }}>
        <CardHeader
          avatar={
            <UserAvatar
              firstName={props.activity.ActivityPerformerUsername}
              lastName={props.activity.ActivityPerformerUsername}
            />
          }
          title={props.title}
          subheader={<Subheader />}
          titleTypographyProps={{ style: { fontSize: '16px' } }}
        />
        <CardContent style={{ paddingTop: '0px' }}>
          <MentionDisplayer
            variant={props.ack ? 'body1' : 'body2'}
            content={props.activity.Raw}
          />
        </CardContent>
        {props.ack && (
          <CardActions>
            {props.showViewLink && (
              <Button
                size="small"
                color="primary"
                component={Link}
                to={`/referral_request/${props.referralRequestId}`}>
                View
              </Button>
            )}
            {!ackd && !props.ackAndView && (
              <Button size="small" color="primary" onClick={ackHandler}>
                Acknowledge
              </Button>
            )}
            {!ackd && props.ackAndView && (
              <Button size="small" color="primary" onClick={ackAndViewHandler}>
                Acknowledge and View
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </Grow>
  )
}

export default ReferralRequestActivityItem
