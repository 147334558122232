import React from 'react'
import { Link } from 'react-router-dom'
import { Add as ContentAdd } from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { useAuth } from '../../Auth'

interface Props {
  stackButtons?: boolean
}

const AddNewReferralBtn: React.FC<Props> = ({ stackButtons }: Props) => {
  const { access } = useAuth()

  const canAddReferral = access.CanCreateReferrals
  const canAddReferralRequest = access.CanCreateReferralRequests

  const newRequestStyle = stackButtons
    ? {
        marginTop: '5px',
      }
    : {
        marginLeft: '10px',
      }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: stackButtons ? 'column' : 'row',
      }}>
      {canAddReferral && (
        <Button
          color="primary"
          variant="outlined"
          to="find_member?formType=referral"
          component={Link}
          startIcon={<ContentAdd />}>
          Add New Referral
        </Button>
      )}

      {canAddReferralRequest && (
        <Button
          color="primary"
          variant="outlined"
          to="find_member?formType=referralRequest"
          component={Link}
          startIcon={<ContentAdd />}
          style={newRequestStyle}>
          Request Referral
        </Button>
      )}
    </div>
  )
}

export default AddNewReferralBtn
