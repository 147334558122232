import React from 'react'
import { Assignment, CreditCard, Receipt } from '@material-ui/icons'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { Route, Switch } from 'react-router-dom'
import { ProtectedRoute } from './ProtectedRoute'
import ReferralList from './components/Referrals/ReferralList'
import { CreateReferral, EditReferral } from './components/ReferralForm'
import ReferralInvoiceList from './components/ReferralInvoice/ReferralInvoiceList'
import { BillingDashboard } from './components/BillingDashboard'
import { Dashboard } from './components/Dashboard'
import { FindMember } from './components/FindMember'
import { Profile } from './components/Profile'
import { Callback } from './components/Login'
import UserManagement from './components/UserManagement'
import UM_UserDetail from './components/UserManagement/UserDetail'
import UM_PracticeFacilityDetail from './components/UserManagement/PracticeFacilityDetail'
import ReferralInvoiceForm from './components/ReferralInvoiceForm'
import {
  CreateReferralRequest,
  EditReferralRequest,
} from './components/ReferralRequestForm'
import Reports from './components/Reports'

import AppFrame from './components/AppFrame'
import { NetworkDownContainer } from './components/NetworkDown'
import NoUser from './components/NoUser'
import { DownloadFile } from './components/DownloadFile'

let routeKey = 0

function getRouteKey() {
  routeKey++
  return routeKey
}

// Route keys are here to ensure rendering performance; the values don't matter, so long as they're unique to
// each route, hence getRouteKey() just increments each call to itself by one
let routes = [
  <Route key={getRouteKey()} exact path="/" component={Dashboard} />,
  <Route
    key={getRouteKey()}
    exact
    path="/login_callback"
    component={Callback}
  />,
  <Route key={getRouteKey()} exact path="/profile" component={Profile} />,
  <Route
    key={getRouteKey()}
    exact
    path="/find_member"
    component={FindMember}
  />,
  <Route key={getRouteKey()} exact path="/no_user" component={NoUser} />,
  <Route key={getRouteKey()} exact path="/download" component={DownloadFile} />,
  <Route
    key={getRouteKey()}
    exact
    path="/user_management/organization/:organizationId"
    component={UserManagement}
  />,
  <Route
    key={getRouteKey()}
    path="/user_management/organization/:organizationId/users/:userId/facility_permissions"
    component={UM_UserDetail}
  />,
  <Route
    key={getRouteKey()}
    path="/user_management/organization/:organizationId/facilities/:facilityId/user_permissions"
    component={UM_PracticeFacilityDetail}
  />,
  <Route
    key={getRouteKey()}
    exact
    path="/user_management"
    component={UserManagement}
  />,
  <ProtectedRoute
    key={getRouteKey()}
    path="/billing_dashboard"
    icon={Receipt}
    perm={`CanAccessInvoices`}
    component={BillingDashboard}
  />,
  <ProtectedRoute
    key={getRouteKey()}
    path="/referral_invoices"
    icon={CreditCard}
    perm={`CanAccessInvoices`}
    component={ReferralInvoiceList}
  />,
  <ProtectedRoute
    key={getRouteKey()}
    path="/referral_invoice/:id"
    perm={`CanAccessInvoices`}
    component={ReferralInvoiceForm}
  />,
  <ProtectedRoute
    key={getRouteKey()}
    path="/referrals"
    icon={Assignment}
    perm={`CanAccessReferrals`}
    component={ReferralList}
  />,
  <ProtectedRoute
    key={getRouteKey()}
    path="/referral/create"
    icon={Assignment}
    perm={`CanAccessReferrals`}
    component={CreateReferral}
  />,
  <ProtectedRoute
    key={getRouteKey()}
    path="/referral/:id"
    perm={`CanAccessReferrals`}
    component={EditReferral}
  />,
  <Route
    key={getRouteKey()}
    path="/referral_request/create"
    component={CreateReferralRequest}
  />,
  <Route
    key={getRouteKey()}
    path="/referral_request/:referralRequestId"
    component={EditReferralRequest}
  />,
  <Route key={getRouteKey()} path="/reports" component={Reports} />,
]

const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans',
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontWeight: '700',
      },
    },
    MuiDivider: {
      root: {
        margin: 10,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: '#0059c91c',
      },
    },
  },
})

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppFrame>
          <Switch>{routes}</Switch>
        </AppFrame>
        <NetworkDownContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
