import React from 'react'
import {
  Typography,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
} from '@material-ui/core'
import {
  CheckCircle as IconCheckCircle,
  Timelapse as IconTimelaps,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'

interface Props {
  exclusions: any[]
  hasInvalidDates: boolean
  deductibleStartDate: string
  deductibleEndDate: string
}

const useStyles = makeStyles((theme) => {
  return {
    chipper: {
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
    iconKey: {
      listStyle: 'none',
      padding: 0,
      margin: `0 0 ${theme.spacing(1)}px 0`,
      color: '#777',
      '& > li': {
        verticalAlign: 'middle',
        lineHeight: 1,
        '& svg': {
          verticalAlign: 'middle',
          display: 'inline-block',
        },
        '& span': {
          verticalAlign: 'middle',
        },
      },
    },
    exclusionDetails: {
      paddingTop: theme.spacing(1),
    },
  }
})

const Exclusions: React.FC<Props> = ({
  exclusions,
  hasInvalidDates,
  deductibleStartDate,
  deductibleEndDate,
}) => {
  const [visibleExclusion, setVisibleExclusion] = React.useState<any>()
  const classes = useStyles()
  const countExclusions = exclusions ? exclusions.length : 0

  const renderDates = () => (
    <>
      <strong>Accumulator Period: </strong>
      {deductibleStartDate} - {deductibleEndDate}
    </>
  )

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography className="subtitle2">
          Exclusions <strong>({countExclusions})</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <ul className={classes.iconKey}>
            <li>
              <IconCheckCircle />{' '}
              <span>
                indicates{' '}
                <strong>all services in the category are excluded</strong>.
              </span>
            </li>
            <li>
              <IconTimelaps />{' '}
              <span>
                indicates <strong>some services</strong> are excluded, and{' '}
                <i>further information is available</i>.
              </span>
            </li>
          </ul>
          <div>
            {exclusions.map((excl) => {
              return (
                <Chip
                  icon={
                    excl.Status === 1 ? <IconCheckCircle /> : <IconTimelaps />
                  }
                  className={classes.chipper}
                  key={excl.Descr}
                  label={excl.Descr}
                  color="primary"
                  onClick={() => setVisibleExclusion(excl)}
                />
              )
            })}
          </div>
          <ul className={classes.iconKey}>
            <li>
              <span>{!hasInvalidDates && renderDates()}</span>
            </li>
          </ul>
          {visibleExclusion ? (
            visibleExclusion.Status === 2 ? (
              <div className={classes.exclusionDetails}>
                <Typography variant="subtitle2">
                  {visibleExclusion.Descr}
                </Typography>
                <Typography
                  variant="caption"
                  style={{ whiteSpace: 'pre-line' }}>
                  {visibleExclusion.Note}
                </Typography>
              </div>
            ) : (
              <Typography variant="subtitle2">
                All services related to{' '}
                <strong>{visibleExclusion.Descr}</strong> are excluded.
              </Typography>
            )
          ) : null}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

export default Exclusions
