import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Chip } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { getReferralPrices } from '../../actions/PriceActions'
import ReferralPricesDialog from './dialog'
import _ from 'lodash'
import { ReferralPriceBundle } from './types'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles = (theme: any) => {
  return {
    chipper: {
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
  }
}

interface ReferralPricesProps {
  referralId: number
  hintMainCPT: string | null
  viewOnly?: boolean
  classes: ClassNameMap<string>
}

const referralPrices = ({
  referralId,
  hintMainCPT,
  viewOnly,
  classes,
}: ReferralPricesProps) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [existingSelections, setExistingSelections] = useState<
    (ReferralPriceBundle | null)[]
  >([])

  useEffect(() => {
    loadExisting()
  }, [])

  const loadExisting = () => {
    getReferralPrices({ referralId: referralId })
      .then((res: any) => {
        setExistingSelections(_.get(res, 'Data', []))
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  const closeDialog = () => {
    setOpenDialog(false)
  }

  const onSave = () => {
    closeDialog()
    loadExisting()
  }

  const addButton = viewOnly ? null : (
    <Button
      variant="contained"
      color="primary"
      startIcon={<Edit />}
      onClick={() => {
        setOpenDialog(true)
      }}>
      Manage Attached Bundles
    </Button>
  )

  return (
    <div>
      <div>
        {existingSelections.map((pr: ReferralPriceBundle | null) => {
          return (
            pr && (
              <Chip
                className={classes.chipper}
                key={pr.ID}
                label={pr.CostKey.Code}
                color="primary"
                variant="outlined"
              />
            )
          )
        })}
      </div>
      {addButton}
      {openDialog ? (
        <ReferralPricesDialog
          referralId={+referralId}
          existingSelections={existingSelections}
          onSave={onSave}
          hintMainCPT={hintMainCPT}
          open={true}
          closeDialog={closeDialog}
        />
      ) : null}
    </div>
  )
}

export const ReferralPrices = withStyles(styles)(referralPrices)
