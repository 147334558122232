import React, { ReactElement } from 'react'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import { Archive, Unarchive } from '@material-ui/icons'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { archiveReferral } from '../../actions/ReferralActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'

interface ArchiveButtonProps {
  data: any
  onArchive: (id: number) => void
  iconButtonOnly: boolean
  style?: any
}

export default function ArchiveButton({
  data,
  onArchive,
  iconButtonOnly,
  style,
}: ArchiveButtonProps): ReactElement<any, any> | null {
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  const isArchived = !!data.ArchivedAt
  const title = isArchived ? 'Unarchive' : 'Archive'
  const action = isArchived ? 'Unarchived' : 'Archived'
  const icon = isArchived ? <Unarchive /> : <Archive />

  const runArchive = (id: number, doArchive: boolean) => {
    archiveReferral([id], doArchive)
      .then(() => {
        showSnackbar(
          `Successfully ${action} Referral`,
          SnackbarTypeSuccess,
          3500
        )
        onArchive(id)
      })
      .catch(catchAPIError({ defaultMessage: `Failed to ${title} Referral` }))
  }
  return iconButtonOnly ? (
    <Tooltip title={title} aria-label={title}>
      <IconButton
        size="small"
        aria-label={title}
        onClick={() => runArchive(data.ID, !isArchived)}>
        {icon}
      </IconButton>
    </Tooltip>
  ) : (
    <Button
      variant="contained"
      color="primary"
      startIcon={icon}
      style={style}
      onClick={() => runArchive(data.ID, !isArchived)}>
      {title}
    </Button>
  )
}
