import React from 'react'
import styled from 'styled-components'
import { Grid, Divider, Typography } from '@material-ui/core'

export const FormGrid = styled(Grid)`
  padding: 16px;
  width: 100%;
`

export const SectionDivider = styled(Divider)`
  margin-left: 15px;
  margin-right: 15px;
`

export const MiddleGridCol = styled(Grid)`
  padding: 0px 10px;
`

export const StyledGridRow = styled(Grid)`
  padding: 8px;
`

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 16px 24px;
`

export const SectionSubheader: React.FC<any> = ({ children }) => {
  return (
    <Typography
      variant="body1"
      color="textSecondary"
      style={{ paddingBottom: '10px' }}>
      {children}
    </Typography>
  )
}

export const ReadOnlyField: React.FC<any> = (props) => {
  return (
    <div>
      {props.label ? (
        <Typography variant="subtitle2" style={{ paddingBottom: 10 }}>
          <strong>{props.label}</strong>
        </Typography>
      ) : null}
      <Typography variant="subtitle1">{props.value}</Typography>
    </div>
  )
}
