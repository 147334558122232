export const REFERRAL_STATUS_NEW = 1
export const REFERRAL_STATUS_ACCEPTED = 2
export const REFERRAL_STATUS_AWAITING_ORDERS = 9
export const REFERRAL_STATUS_AWAITING_SCHEDULING = 3
export const REFERRAL_STATUS_PATIENT_NON_RESPONSIVE = 8
export const REFERRAL_STATUS_DECLINED = 4
export const REFERRAL_STATUS_CANCELLED = 5
export const REFERRAL_STATUS_SCHEDULED = 6
export const REFERRAL_STATUS_COMPLETED = 7

export default {
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_ACCEPTED,
  REFERRAL_STATUS_AWAITING_ORDERS,
  REFERRAL_STATUS_AWAITING_SCHEDULING,
  REFERRAL_STATUS_PATIENT_NON_RESPONSIVE,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_CANCELLED,
  REFERRAL_STATUS_SCHEDULED,
  REFERRAL_STATUS_COMPLETED,
}
