import * as api from './ApiService'

const cloneReferral = async (SourceReferralID: number) => {
  const { Data: referral }: any = await api.post(`/spawn_new_referral`, {
    SourceReferralID,
  })
  const { Data: Member }: any = await api.get(`/member/${referral.MemberID}`)
  const EmployerMembership = Member.EmployerMemberships.find(
    (em: any) => em.ID === referral.EmployerMemberID
  )
  const EmployerMember = { ...Member, ...EmployerMembership }

  return {
    ...referral,
    EmployerMember: EmployerMember
      ? {
          ...EmployerMember,
          EmployerID: EmployerMember.EmployerId,
          IntegrationID: EmployerMember.IntegrationId,
        }
      : {},
    Notes: `Cloned from source referral: ${referral.SourceReferralID}`,
    Member,
  }
}

export default {
  cloneReferral,
}
