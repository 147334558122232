import React from 'react'
import { Divider } from '@material-ui/core'
import styled from 'styled-components'
import FormHeader from './FormHeader'
import AttachedBundles from '../Referrals/AttachedBundles'
import { ReferralForm } from './types'

interface Props {
  form: ReferralForm
}

const FormDivider = styled(Divider)`
  margin: 20px 0px;
`

const AttachedBundlesForm: React.FC<Props> = ({ form }) => {
  return (
    <div>
      <FormHeader title="Attached Bundles" />
      <AttachedBundles
        ID={form.data.ID || form.data.SourceReferralID}
        viewOnly={!form.data.Edit}
      />
      <FormDivider />
    </div>
  )
}

export default AttachedBundlesForm
