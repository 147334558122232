import React from 'react'
import { createRoot } from 'react-dom/client'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { JssProvider } from 'react-jss'

import './types'
import './index.css'
import App from './App'

import { AuthProvider } from './Auth'
import { ConfigProvider } from './Config'

import HardStop from './components/HardStop'
import requestMocksServiceWorker from './utils/networkRequestMocks'
import checkRelease from './CheckRelease'

const browserHistory = createBrowserHistory()
const onRedirectCallback = (appState) => {
  browserHistory.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const redirect = `${window.location.origin}/login_callback`

if (process.env.NODE_ENV === 'development') {
  requestMocksServiceWorker.start()
}

const container = document.getElementById('root')
const root = createRoot(container)

try {
  root.render(
    <ConfigProvider>
      <AuthProvider
        redirect_uri={redirect}
        onRedirectCallback={onRedirectCallback}>
        <JssProvider>
          <Router history={browserHistory}>
            <App />
          </Router>
        </JssProvider>
      </AuthProvider>
    </ConfigProvider>
  )
} catch {
  console.warn('Using an old version of IE, not rendering app')
  root.render(
    <HardStop message="Please use Chrome or Firefox to view GoZero" />
  )
}

checkRelease()
