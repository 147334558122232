import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
// @todo-refactor
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import isEmail from 'is-email'
import TextField from '../TextField'
import { createUser } from '../../services/UserManagementService'
import useForm from '../../hooks/useForm'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeError,
} from '../../hooks/useSnackbar'

interface Props {
  open: boolean
  onClose: () => void
}

const exists = (val: string) => !!val

const FormErrors: React.FC<{ formErrors: any }> = ({ formErrors }) => {
  const errors: string[] = Object.values(formErrors)
  return (
    <>
      {errors.length
        ? errors.map((err: string) => (
            <p style={{ color: 'firebrick' }}>{err}</p>
          ))
        : null}
    </>
  )
}

const AddUserDialog: React.FC<Props> = ({ open, onClose }) => {
  const { show: showSnackbar } = useSnackbar()
  const defaultState = {
    FirstName: '',
    LastName: '',
    Email: '',
  }

  const validators = {
    FirstName: { isValid: exists, msg: 'First name is required' },
    LastName: { isValid: exists, msg: 'Last name is required' },
    Email: { isValid: isEmail, msg: 'Valid email is required' },
  }

  const hist = useHistory()
  const params: any = useParams()
  const form = useForm(defaultState, validators)
  const [attempedSave, setAttemptedSave] = React.useState(false)

  const onSave = async () => {
    setAttemptedSave(true)

    if (form.isValid()) {
      try {
        const user: any = await createUser(params.organizationId, form.data)
        form.setData(defaultState)
        onClose()
        // redirect to user detail
        hist.push(
          `/user_management/organization/${params.organizationId}/users/${user.Data.ID}/facility_permissions`
        )
        showSnackbar('Success', SnackbarTypeSuccess)
      } catch (err) {
        console.warn('Error creating user', err)
        showSnackbar('Error creating user', SnackbarTypeError)
      }
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          value={form.data.FirstName}
          onChange={form.setField('FirstName')}
        />
        <TextField
          label="Last Name"
          value={form.data.LastName}
          onChange={form.setField('LastName')}
        />
        <TextField
          label="Email"
          value={form.data.Email}
          onChange={form.setField('Email')}
        />
        {attempedSave && <FormErrors formErrors={form.errors} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={onSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddUserDialog
