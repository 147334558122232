import React from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

type Option = {
  name: string
  value: string | number | boolean
  disabled?: boolean
}

interface Props {
  value: any
  onChange: (val: any) => void
  error?: string | null
  label?: string
  options: Option[]
  multiple?: boolean
  style?: any
  readOnly?: boolean
  PassthroughProps?: any
}

const AutocompleteField: React.FC<Props> = ({
  value,
  onChange,
  label,
  options,
  multiple,
  style,
  error,
  readOnly,
  PassthroughProps,
}) => {
  const [inputValue, setInputValue] = React.useState('')

  const findSelectedName = () => {
    const selected = options.find((opt) => opt.value == value)
    return selected ? selected.name : ''
  }

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: Option) => option.name || ''}
      getOptionSelected={(option: Option, val: any) => option.value === val}
      getOptionDisabled={(option: Option) => option.disabled}
      value={value}
      // selection of the item in the list
      onChange={(_evt: any, val: any) => {
        if (val && Array.isArray(val)) {
          onChange(val.map((v) => v.value))
        } else if (val && val.value) {
          onChange(val.value)
        }
      }}
      onInputChange={(_evt: any, val: any, reason: any) => {
        if (reason === 'input') {
          setInputValue(val)
        }

        if (reason === 'clear') {
          setInputValue('')
          onChange(undefined)
        }
      }}
      inputValue={findSelectedName() || inputValue}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label={label}
          error={!!error}
          helperText={error}
        />
      )}
      multiple={multiple}
      style={{ flex: 1, ...style }}
      onBlur={() => setInputValue('')}
      disabled={readOnly}
      {...PassthroughProps}
    />
  )
}

export default AutocompleteField
