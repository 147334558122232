import React from 'react'
import { Route, RouteProps } from 'react-router'
import { Forbidden } from './components/Forbidden'
import { useAuth } from './Auth'

export interface PermissionGate {
  perm: string
}

type ProtectedRouteProps = RouteProps & PermissionGate

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const { access } = useAuth() as any
  const { perm, component, ...routeProps } = props
  const canView = access[perm]

  const ForbiddenPage = () => <Forbidden route={props.path} perm={perm} />
  const Component = canView ? component : ForbiddenPage

  return <Route {...routeProps} component={Component} />
}
