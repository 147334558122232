import React from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Grid, Paper, Typography } from '@material-ui/core'
import { FormGrid, SectionDivider, Toolbar } from './components'
import ReferralDetails from './ReferralDetails'
import InvoiceDetails from './InvoiceDetails'
import NotesSection from './NotesSection'
import { InvoiceLineTable } from './InvoiceLineTable'
import { WorkflowActions } from './WorkflowActions'
import { isEditableStatus, validateDateOfService } from './utils'
import useForm from '../../hooks/useForm'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import useApi from '../../hooks/useApi'
import usePrevious from '../../hooks/usePrevious'
import { updateReferralInvoice } from '../../services/ReferralInvoiceService'
import { STATUS_DRAFT } from './statuses'

const ReferralInvoiceForm: React.FC = () => {
  const params: any = useParams()
  const hist = useHistory()
  const loc: any = useLocation()
  const { show: showSnackbar } = useSnackbar()

  const [attemptedSave, setAttemptedSave] = React.useState(false)

  const { data: referralInvoice } = useApi({
    route: `/referral_invoice/${params.id}`,
  })

  const prevData = usePrevious(referralInvoice)

  const form = useForm(
    {
      ID: +params.id,
      DateOfService: null,
      BillingEntity2ID: null,
      PatientAccountNumber: '',
      Notes: '',
      OrganizationNPI: '',
      PracticeFacilityNPI: '',
    },
    {
      DateOfService: {
        msg: "Date of Service can't be in the future or left blank. Must be current date or past date.",
        isValid: validateDateOfService,
      },
      // BillingEntity2ID: {
      //   msg: 'test Billing Entity is required.',
      //   isValid: (val: any, x: any) => {
      //     // @ts-ignore
      //     console.log('received', val, data, x)
      //     return false
      //   },
      // },
    }
  )

  React.useEffect(() => {
    // load form once
    if (!prevData && !!referralInvoice) {
      const {
        DateOfService,
        BillingEntity2ID,
        Notes,
        PatientAccountNumber,
        OrganizationNPI,
        PracticeFacilityNPI,
      } = referralInvoice
      form.setData({
        ...form.data,
        DateOfService,
        BillingEntity2ID,
        Notes,
        PatientAccountNumber,
        OrganizationNPI,
        PracticeFacilityNPI,
      })
    }
  }, [referralInvoice])

  const onSave = async (statusId: number, successMessage: string) => {
    setAttemptedSave(statusId !== STATUS_DRAFT)
    try {
      if (statusId === STATUS_DRAFT || form.isValid()) {
        await updateReferralInvoice(params.id, {
          ...form.data,
          ReferralInvoiceStatusID: statusId,
        })

        showSnackbar(successMessage, SnackbarTypeSuccess)

        hist.push(
          `/referral_invoices${loc.state ? loc.state.prevQueryString : ''}`
        )
      } else {
        showSnackbar(`Please correct form errors.`, SnackbarTypeError)
      }
    } catch (e: any) {
      console.warn('Error saving invoice', e)
      if (Array.isArray(e.Error) && e.Error.length > 0) {
        showSnackbar(
          `Error saving invoice; ${e.Error.join(', ')}`,
          SnackbarTypeError
        )
        return
      }
      showSnackbar(`Error saving invoice.`, SnackbarTypeError)
    }
  }

  if (!referralInvoice) return null

  // Disable editing if there are no actions to take on this invoice
  const disableEditing =
    referralInvoice.NextStatuses && referralInvoice.NextStatuses.length === 0
  const editableStatus = isEditableStatus(
    referralInvoice.ReferralInvoiceStatusID
  )

  const referral = {
    ID: referralInvoice.ReferralID,
    ...referralInvoice.Referral,
  }

  return (
    <Paper>
      <Grid container direction="row">
        <Grid item xs={12}>
          <ReferralDetails referral={referral} />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <SectionDivider />
        </Grid>
        <Grid item xs={8}>
          <InvoiceDetails
            referralInvoice={referralInvoice}
            form={form}
            editable={editableStatus}
            showErrors={attemptedSave}
          />
        </Grid>
        <Grid item xs={4}>
          <NotesSection form={form} disabled={disableEditing} />
        </Grid>
        <FormGrid item xs={12} spacing={2}>
          <Typography variant="h5">Invoice Lines</Typography>
        </FormGrid>
        <FormGrid item spacing={2} xs={12}>
          <InvoiceLineTable
            total={referralInvoice.TotalCost}
            practiceFacilityID={referral.PracticeFacilityID}
            referralInvoiceID={referralInvoice.ID}
            lines={referralInvoice.Lines}
            isEditable={editableStatus}
            dateOfService={form.data.DateOfService}
            showErrors={attemptedSave}
          />
        </FormGrid>
      </Grid>
      <Toolbar>
        <WorkflowActions referralInvoice={referralInvoice} onSave={onSave} />
      </Toolbar>
    </Paper>
  )
}

export default ReferralInvoiceForm
