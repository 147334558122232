import React from 'react'
import { Grid, SortDirection } from '@material-ui/core'
import DataTable from '../DataTable'
import { ReferralInvoiceModel } from '../../models/referralInvoice'
import useApi from '../../hooks/useApi'
import { useReferralInvoiceList } from './hooks'
import ReferralInvoiceFilter from './ReferralInvoiceFilters'
import useQueryParams from '../../hooks/useQueryParams'

const ReferralInvoiceList: React.FC = () => {
  const { params, setParams } = useQueryParams()
  const { data, meta, refetch, loading } = useReferralInvoiceList()

  const { data: statuses } = useApi({
    route: '/referral_invoice_statuses',
  })

  const onChangePage = (change: any) => {
    const { range, page, pageSize } = change
    const newParams = { ...params, page, pageSize, range }
    setParams(newParams)
    refetch(newParams)
  }

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    const newParams = { ...params, sort: [col, dir] }
    setParams(newParams)
    refetch(newParams)
  }

  const sortable = params.sort
    ? { col: params.sort[0], dir: params.sort[1] }
    : undefined

  if (!data || !meta || !statuses) return null

  return (
    <>
      <Grid container justify="space-between">
        <Grid item xs={10}>
          <ReferralInvoiceFilter refetchReferralInvoices={refetch} />
        </Grid>
      </Grid>
      <DataTable
        keyProp="ID"
        data={data}
        columns={ReferralInvoiceModel(statuses)}
        count={meta.Total}
        initPage={params.page || 1}
        initPageSize={params.pageSize || 10}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangePage}
        sortHandler={sortColumns}
        sortable={sortable}
        loading={loading}
        allowEditing={false}
      />
    </>
  )
}

export default ReferralInvoiceList
