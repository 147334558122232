import React from 'react'
import moment from 'moment'
import debounce from 'lodash/debounce'
import useApi from '../../hooks/useApi'
import useQueryParams from '../../hooks/useQueryParams'
import { useAuth } from '../../Auth'

const SEARCH_FILTER = 'q'
const DOB_FILTER = 'DOB'
const ORG_FILTER = 'OrganizationID'
const STATUS_FILTER = 'StatusID'
const EMPLOYER_FILTER = 'EmployerIDs'
const REFERRER_FILTER = 'ReferrerIDs'
const DEBOUNCE_MS = 300
const ARCHIVED_FILTER = 'ShowArchived'
const IS_URGENT = 'IsUrgent'

export const useReferralList = () => {
  const { access } = useAuth()
  const defaultParams = {
    filter: { [ARCHIVED_FILTER]: access.Roles.ReferralAdministrator },
  }
  const { params, setParams }: any = useQueryParams(defaultParams)

  return useApi({
    // union list of referrals + referral requests
    route: '/referrals_unified',
    filter: params.filter || {},
    sort: params.sort || ['UpdatedAt', 'DESC'],
    range: params.range || [1, 10],
  })
}

export const useReferralFilters = (refetchReferrals: any) => {
  const { access } = useAuth()
  const defaultParams = {
    filter: { [ARCHIVED_FILTER]: access.Roles.ReferralAdministrator },
  }
  const { params, setParams } = useQueryParams(defaultParams)
  const initialFilters = Object.assign({}, defaultParams.filter, params.filter)

  const [searchText, setSearchTextState] = React.useState<string>(
    initialFilters[SEARCH_FILTER] || ''
  )
  const [searchDob, setSearchDobState] = React.useState<string>(
    initialFilters[DOB_FILTER] || ''
  )
  const [selectedOrg, setSelectedOrgState] = React.useState<number | undefined>(
    initialFilters[ORG_FILTER] || undefined
  )
  const [selectedStatus, setSelectedStatusState] = React.useState<number[]>(
    initialFilters[STATUS_FILTER] || []
  )

  const [selectedEmployer, setSelectedEmployerState] = React.useState<number[]>(
    initialFilters[EMPLOYER_FILTER] || []
  )

  const [selectedReferrer, setSelectedReferrerState] = React.useState<number[]>(
    initialFilters[REFERRER_FILTER] || []
  )

  const [archived, setArchivedState] = React.useState<boolean>(
    initialFilters[ARCHIVED_FILTER]
  )

  const [isUrgent, setIsUrgentState] = React.useState<boolean>(
    initialFilters[IS_URGENT]
  )

  const debouncedRefetch = React.useCallback(
    debounce(refetchReferrals, DEBOUNCE_MS),
    []
  )

  const setSearchText = (val: string) => {
    setSearchTextState(val)
    const isSearchTextValid = val && val.length >= 3
    let filter = {} as any
    if (isSearchTextValid) {
      filter = { ...params.filter, [SEARCH_FILTER]: val }
    } else {
      filter = { ...params.filter }
      delete filter[SEARCH_FILTER]
    }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSearchDob = (val: string) => {
    setSearchDobState(val)
    const isSearchDobValid = val && moment(val).isValid()
    let filter = {} as any
    if (isSearchDobValid) {
      filter = { ...params.filter, [DOB_FILTER]: val }
    } else {
      filter = { ...params.filter }
      delete filter[DOB_FILTER]
    }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedOrg = (orgId: number) => {
    let filter = {} as any
    if (orgId) {
      setSelectedOrgState(orgId)
      filter = { ...params.filter, [ORG_FILTER]: orgId }
    } else {
      setSelectedOrgState(undefined)
      filter = { ...params.filter }
      delete filter[ORG_FILTER]
    }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedStatus = (statuses: number[]) => {
    setSelectedStatusState(statuses)
    const filter = { ...params.filter, [STATUS_FILTER]: statuses }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedEmployer = (employerIDs: number[]) => {
    setSelectedEmployerState(employerIDs)
    const filter = { ...params.filter, [EMPLOYER_FILTER]: employerIDs }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setSelectedReferrer = (referrerIDs: number[]) => {
    setSelectedReferrerState(referrerIDs)
    const filter = { ...params.filter, [REFERRER_FILTER]: referrerIDs }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setArchived = (archived: boolean) => {
    setArchivedState(archived)
    const filter = { ...params.filter, [ARCHIVED_FILTER]: archived }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  const setIsUrgent = (v: boolean) => {
    setIsUrgentState(v)
    const filter = { ...params.filter, [IS_URGENT]: v }
    setParams({ ...params, filter })
    debouncedRefetch({ ...params, filter })
  }

  return {
    searchText,
    setSearchText,
    searchDob,
    setSearchDob,
    selectedOrg,
    setSelectedOrg,
    selectedStatus,
    setSelectedStatus,
    selectedEmployer,
    selectedReferrer,
    setSelectedEmployer,
    setSelectedReferrer,
    archived,
    setArchived,
    isUrgent,
    setIsUrgent,
  }
}
