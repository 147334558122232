import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import useApi from '../../hooks/useApi'
import ActivityList from '../Activity/ActivityList'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles: any = () => {
  return {
    activityCard: {
      margin: 5,
    },
    activityContent: {
      paddingTop: 0,
    },
    noActivityContent: {
      textAlign: 'center',
    },
  }
}

interface ReferralActivityProps {
  referralId: string
}

export const ReferralActivity = ({ referralId }: ReferralActivityProps) => {
  const { data, refetch, loading } = useApi(
    {
      route: `/referral/${referralId}/activity`,
    },
    {
      errorMessage:
        'There was a problem fetching activity for this referral, please refresh and contact your administrator if this continues',
    }
  )

  if (loading) return <CircularProgress />
  return (
    <Card>
      <ActivityList
        title="Referral Activity"
        NoActivityItem={NoActivityItem}
        activities={data}
        refresh={refetch}
        onAck={() => {}}
      />
    </Card>
  )
}

const NoActivityItem = withStyles(styles)(({
  classes,
}: {
  classes: ClassNameMap<string>
}) => {
  return (
    <Card className={classes.activityCard}>
      <CardContent className={classes.noActivityContent}>
        <Typography variant="body2">There is no recent activity.</Typography>
      </CardContent>
    </Card>
  )
})
