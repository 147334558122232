import React, { ChangeEvent, FC } from 'react'
import { Typography, Grid, Tooltip } from '@material-ui/core'
import { FormGrid, SectionSubheader } from './components'
import InvoiceICDCodeSelector from './ReferralInvoiceICDCodeSelector'
import InvoiceFileUpload from './ReferralInvoiceFileUpload'
import Select from '../SelectField'
import TextField from '../TextField'
import { NPIInput } from '../../components/Inputs'
import { ReferralInvoice } from './types'
import BillingEntityPicker from './BillingEntityPicker'

import {
  STATUS_ACCEPTED,
  STATUS_DRAFT,
  STATUS_IGNORED,
  STATUS_REJECTED,
  STATUS_SUBMITTED,
} from './statuses'
import ManagedDateInput from '../Inputs/managedDateInput'

interface InvoiceDetailsProps {
  referralInvoice: ReferralInvoice
  form: any
  editable: boolean
  showErrors: boolean
}

const InvoiceDetails: FC<InvoiceDetailsProps> = ({
  referralInvoice,
  form,
  editable,
  showErrors,
}: InvoiceDetailsProps) => {
  return (
    <FormGrid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Invoice Details</Typography>
        <p style={{ marginTop: '0px', fontSize: '18px' }}>
          Invoice Number: {referralInvoice.ID}
        </p>
        <SectionSubheader>
          {referralInvoice.ReferralInvoiceStatusID === STATUS_DRAFT ? (
            <em>
              This invoice is a draft. Fill out the details below then press
              submit to send it to ZERO for review.
            </em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_SUBMITTED ? (
            <em>This invoice has been submitted and cannot be edited.</em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_IGNORED ? (
            <em>
              This invoice has been ignored. If you need to edit it, click Save
              In Draft Mode below.
            </em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_REJECTED ? (
            <em>
              This invoice has been rejected by ZERO. Please review notes, make
              edits and re-submit.
            </em>
          ) : null}
          {referralInvoice.ReferralInvoiceStatusID === STATUS_ACCEPTED ? (
            <em>
              This invoice has been accepted by ZERO and can no longer be
              edited.
            </em>
          ) : null}
        </SectionSubheader>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          spacing={4}
          // direction="row"
          // justify="space-between"
          alignItems="flex-start">
          <Grid item xs={3}>
            <ManagedDateInput
              label="Date of Service"
              disabled={!editable}
              value={form.data.DateOfService}
              setter={({ name, value }) => form.setters.DateOfService(value)}
              error={showErrors && !!form.errors.DateOfService}
            />
            {!!form?.errors?.DateOfService && (
              <Typography variant="caption" color="error">
                {form.errors.DateOfService}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <TextField
              label="Patient Account Number"
              value={form.data.PatientAccountNumber}
              onChange={form.setters.PatientAccountNumber}
              disabled={!editable}
            />
          </Grid>
          <Grid item xs="auto">
            <Tooltip
              title={
                <span style={{ fontSize: '0.8rem' }}>
                  By selecting a TIN below, this field will auto-populate.
                </span>
              }
              placement="top"
              arrow>
              <NPIInput
                value={form.data.OrganizationNPI}
                disabled={!editable}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  form.setters.OrganizationNPI(e.target.value)
                }
                label="Organization NPI"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Select
              label="Facility NPI"
              value={form.data.PracticeFacilityNPI}
              onChange={(npi: string) => form.setters.PracticeFacilityNPI(npi)}
              disabled={!editable}
              options={referralInvoice.PracticeFacilityNPIs.map(
                (npi: string) => ({
                  name: npi,
                  value: npi,
                })
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center">
        <Grid item xs="auto">
          <BillingEntityPicker
            organizationID={referralInvoice.Referral.OrganizationID}
            selectedBillingEntityID={form.data.BillingEntity2ID}
            disabled={!editable}
            onChange={(be: any) => {
              if (!be) {
                form.setters.OrganizationNPI('')
                form.setters.BillingEntity2ID(null)
                return
              }
              // if NPI we have on file is different than whatever the current
              // value is for NPI, use the one we have on file
              if (form.data.OrganizationNPI != be.NPI) {
                form.setters.OrganizationNPI(be.NPI)
                // woof, timing hack here. Form setters are not syncronous and we can end up wiping out changes
                // if called in quick succession
                form.data.OrganizationNPI = be.NPI
              }
              form.setters.BillingEntity2ID(be.ID)
            }}
          />

          {!referralInvoice.LinkedBillingEntity2?.IsLatest && (
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                background: '#f0ca27',
                display: 'block',
                padding: '0.35rem',
                fontWeight: 600,
                borderRadius: '5px',
                marginTop: '0.5rem',
                maxWidth: 410,
              }}>
              {!editable ? (
                <span>
                  ZERO's records have been updated since the Billing Entity was
                  assigned on this invoice.
                </span>
              ) : (
                <span>
                  ZERO has new information on file for the Billing Entity
                  previously configured on this invoice (TIN, new remittance
                  address, Organization NPI, or W9 on file), which requires
                  manual review. Please re-select the appropriate Billing Entity
                  from the list above.
                </span>
              )}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div style={{ paddingTop: 12 }}>
          <InvoiceFileUpload
            id={referralInvoice.ID}
            files={referralInvoice.Files}
          />
        </div>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start">
          <Grid item xs={8}>
            <Typography
              variant="subtitle2"
              style={{ marginTop: 3, paddingBottom: 8 }}>
              <strong>Add ICD Codes</strong>
            </Typography>
            <InvoiceICDCodeSelector
              id={referralInvoice.ID}
              codes={referralInvoice.ICD10Codes}
              readOnly={!editable}
            />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Grid>
    </FormGrid>
  )
}

export default InvoiceDetails
