import axios, { AxiosInstance, AxiosResponse } from 'axios'
import ConfirmDialog from '../../components/ConfirmDialog'

const BASE_URL = localStorage.getItem('gozero_base_url') || ''

interface CustomAxiosClientInstance extends AxiosInstance {
  customNetworkDownHandler?: any
  customNetworkUpHandler?: any
}

const axiosClient: CustomAxiosClientInstance = axios.create({
  baseURL: BASE_URL,
})

export function setNetworkDownHandler(handler: any) {
  axiosClient.customNetworkDownHandler = handler
}

export function setNetworkUpHandler(handler: any) {
  axiosClient.customNetworkUpHandler = handler
}

/* CRUD helpers */
export function get(url: string, params: any = '') {
  let requestUrl = BASE_URL + url
  if (params && params.filter) params.filter = JSON.stringify(params.filter)
  if (params && params.sort) params.sort = JSON.stringify(params.sort)

  if (params) {
    requestUrl += '?' + queryParams(params)
  }

  const issueRequest = (nextURI = null) => {
    return axiosClient.get(nextURI || requestUrl, getBaseConfig())
  }

  return wrapConfirmationRequired(issueRequest)
}

export function patch(url: string, body: any = '') {
  const requestUrl = BASE_URL + url

  const issueRequest = (nextURI = null) => {
    return axiosClient.patch(nextURI || requestUrl, body, getBaseConfig())
  }

  return wrapConfirmationRequired(issueRequest)
}

export function post(url: string, body: any = '') {
  const requestUrl = BASE_URL + url

  const issueRequest = (nextURI = null) => {
    return axiosClient.post(nextURI || requestUrl, body, getBaseConfig())
  }

  return wrapConfirmationRequired(issueRequest)
}

export function put(url: string, body: any = '') {
  const requestUrl = BASE_URL + url

  const issueRequest = (nextURI = null) => {
    return axiosClient.put(nextURI || requestUrl, body, getBaseConfig())
  }

  return wrapConfirmationRequired(issueRequest)
}

export function del(url: string) {
  const requestUrl = BASE_URL + url

  const issueRequest = (nextURI = null) => {
    const config = getBaseConfig()
    return axiosClient.delete(nextURI || requestUrl, config)
  }

  return wrapConfirmationRequired(issueRequest)
}

export function getToken() {
  return localStorage.getItem('id_token')
}

export function queryParams(params: any) {
  return Object.keys(params)
    .map((k) => {
      let value = params[k]
      if (value && typeof value === 'string') {
        value = value.trim()
      }
      return encodeURIComponent(k) + '=' + encodeURIComponent(value)
    })
    .join('&')
}

function getBaseConfig() {
  return {
    headers: getHeaders(),
  }
}

function getHeaders() {
  return {
    Accept: 'application/json',
    Authorization: `Bearer ${getToken()}`,
  }
}

export interface APIResponse extends Omit<AxiosResponse, 'data'> {
  Data: any
  Meta: APIMetaResult
}

export interface APIMetaResult {
  Range: number[]
  Total: number
}

export interface APIErrorResponse {
  Data?: any
  Meta?: any
  config: any
  response?: { data: any; status: number }
}

/* Base methods */
axiosClient.interceptors.response.use(
  function (response: AxiosResponse<any, any>): APIResponse {
    if (axiosClient.customNetworkUpHandler !== undefined)
      axiosClient.customNetworkUpHandler(true)
    return apiResponse(response)
  },
  function (error: APIErrorResponse): APIErrorResponse | Promise<never> {
    // network error
    if (!error.response) {
      if (axiosClient.customNetworkDownHandler !== undefined)
        return axiosClient.customNetworkDownHandler(error.config)
      return Promise.reject({
        Error: { DetailedMessage: 'Network Down' },
        error: true,
      })
    }

    console.error('Detailed error for debugging: ', error.response.data)

    return Promise.reject({
      ...error.response.data,
      error: true,
    })
  }
)

const apiResponse = (res: AxiosResponse<any>): APIResponse => {
  const { data, ...resNoData } = res
  return {
    Data: data.Data || data.data || data || null,
    Meta: data.Meta || null,
    ...resNoData,
  }
}

export var url = BASE_URL

/*
This is a wrapper for all API interactions that (in effect) supports replaying
a request, if the API replies with a known status code 207 and {Data:{ConfirmationRequired:true}}.
Frequently, we have interactions where a request is made and the backend may have a
condition that definitely isn't an error, but would have effects the user needs to be
aware of (iow, the backend can't make an assumption to do the thing, without the user
being aware).
*/
function wrapConfirmationRequired(issueRequest: any) {
  return issueRequest().then((res: any) => {
    // If a *very* specific set of criteria comes through in reply, then we trigger the
    // confirmation workflow...
    if (
      res.status === 207 &&
      res.Data?.ConfirmationRequired &&
      !!res.Data?.NextURI
    ) {
      return new Promise((resolve, reject) => {
        ConfirmDialog({
          title: 'Confirmation Required',
          content: res.Data?.Message,
          onConfirm() {
            issueRequest(res.Data?.NextURI).then(resolve).catch(reject)
          },
          onCancel() {
            const msg = 'User declined confirmation; action not performed.'
            // Reject with a shape that mimics how our axios interceptor transforms an error
            reject({
              error: true,
              RequestId: res.headers['x-request-id'],
              Error: { Message: msg },
              errList: [msg],
            })
          },
        })
      })
    }

    // ... but by default (99% of the time), act as a pass through
    // and just return the response
    return res
  })
}
