import React from 'react'
import { Link } from 'react-router-dom'
import { COLORS } from '../../utils/colors'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import styled from 'styled-components'

const StyledAvatar = styled(Avatar)`
  color: ${COLORS.BW.GUNMETAL} !important;
  background-color: ${COLORS.BW.CLOUDS} !important;
  font-family: Open Sans;

  :hover {
    opacity: 0.5;
  }
`

const DisplayBox = styled.div`
  padding-left: 10px;
`

const AvatarButton = styled(Button)`
  :hover {
    opacity: none;
    background-color: transparent;
  }

  :focus {
    outline: none;
  }
`

interface Props {
  userDisplayName: string // The name of the user to display as the clickable menu
  currentUser: any // Current user that is logged in
  avatarUrl: string // url for avatar image
}

export const UserMenu = (props: Props) => {
  return (
    <DisplayBox>
      <span>{props.currentUser ? props.currentUser.Email : ''}</span>
      <Link
        to={{
          pathname: '/profile',
        }}
        style={{ textDecoration: 'none' }}>
        <AvatarButton aria-controls="simple-menu" aria-haspopup="true">
          <StyledAvatar src={props.avatarUrl}>
            {props.userDisplayName ? props.userDisplayName[0] : null}
          </StyledAvatar>
        </AvatarButton>
      </Link>
    </DisplayBox>
  )
}
