import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import ActivityList from '../Activity/ActivityList'
import useApi from '../../hooks/useApi'

const NoActivityItem: React.FC = () => {
  return (
    <Card style={{ margin: '5px' }}>
      <CardContent style={{ textAlign: 'center' }}>
        <Typography variant="body2">There is no recent activity.</Typography>
      </CardContent>
    </Card>
  )
}

const ReferralRequestActivity: React.FC<{ referralRequestId: number }> = ({
  referralRequestId,
}) => {
  const { data: activities, refetch } = useApi({
    route: `/referral_request/${referralRequestId}/activity`,
  })

  const onAcknowledgeActivity = () => {
    // no-op
  }

  return (
    <Card>
      <ActivityList
        title="Referral Request Activity"
        NoActivityItem={NoActivityItem}
        activities={activities || []}
        refresh={refetch}
        onAck={onAcknowledgeActivity}
      />
    </Card>
  )
}

export default ReferralRequestActivity
