import React, { useEffect, useState } from 'react'
import SelectField from './SelectField'
import { ReferralForm } from './ReferralForm/types'
import useApi from '../hooks/useApi'
import { useAuth } from '../Auth'
import { Button } from '@material-ui/core'
import { removeReferrerFromReferral } from '../actions/ReferralActions'
import useErrorHandlers from '../hooks/useErrorHandlers'
import useSnackbar from '../hooks/useSnackbar'

interface props {
  form: ReferralForm
  readOnly: boolean
}

/*
Note: wherever this component gets used, you have to ensure that react DOES NOT
render it, then re-renders, in order to make the `useApi` stuff work. IOW - ideally
you'd want to send a different request anytime the employerID changes (and is not
null/undefined), but since it's a hook, there's no way.
*/
export default function ReferrerSelect({
  form,
  readOnly,
}: props): React.ReactElement | null {
  const { access } = useAuth()
  const { showForDuration } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const { data } = useApi({
    route: `/referrers`,
    filter: {
      employerID: form?.data?.EmployerMember?.EmployerID,
    },
  })

  const [showClearButton, setShowClearButton] = useState(false)

  useEffect(() => {
    setShowClearButton(
      !readOnly && access.IsZeroUser && !!form.data?.ReferrerID
    )
  }, [readOnly])

  const refOptions = data
    ? data.map((r: any) => ({
        name: r.Name,
        value: r.ID,
        disabled: !r.IsInEmployerAccessGroup,
      }))
    : []

  if (!showClearButton) {
    refOptions.unshift({ name: 'None', value: '' })
  }

  function removeReferrer() {
    if (!form.data?.ReferrerID || !form.data?.ID) return

    removeReferrerFromReferral(form.data.ID!)
      .then((res: any) => {
        if (res.error) throw res
        form.setData({
          ...form.data,
          ReferrerID: null,
          ReferrerDivisionID: null,
          ReferralRequestID: null,
        })

        showForDuration('Referrer removed', 'success', 3500)
        setShowClearButton(false)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to remove referrer' }))
  }

  function setReferrerId(id: number) {
    form.setData({
      ...form.data,
      ReferrerID: id ? id : null,
      ReferrerDivisionID: null,
    })
  }

  return (
    <>
      <SelectField
        label="Referrer"
        options={refOptions}
        value={form.data?.ReferrerID || ''}
        onChange={(id: number) => setReferrerId(id)}
        disabled={readOnly || showClearButton}
      />
      {showClearButton && (
        <Button color={'secondary'} onClick={() => removeReferrer()}>
          Clear
        </Button>
      )}
    </>
  )
}
