import React, { useEffect, useState } from 'react'
// @ts-ignore
import _ from 'lodash'
import moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { ReferralInvoiceActions } from '../../actions/index'
import ConfirmDetails from './ConfirmDetails'
import { CptCodeSelection } from './CptCodeSelection'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import ManagedDateInput from '../Inputs/managedDateInput'

const { postReferralInvoiceLine } = ReferralInvoiceActions

// Type Defintions
interface Props {
  PracticeFacilityID: number // From the associated referral
  referralInvoiceID: number
  dateOfService?: string
  onSuccess: (line: any) => void // A function to run on success of adding a line
  enabled: boolean
}

interface DialogTitleProps {
  activeStep: number
}

const DialogTitle = (props: DialogTitleProps) => {
  const { activeStep } = props
  return (
    <MuiDialogTitle disableTypography>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step key={0}>
          <StepLabel>Date of Service</StepLabel>
        </Step>
        <Step key={1}>
          <StepLabel>CPT Code</StepLabel>
        </Step>
        <Step key={2}>
          <StepLabel>Confirm Details</StepLabel>
        </Step>
      </Stepper>
    </MuiDialogTitle>
  )
}

interface FormData {
  cptCode?: string
  selectedCostKey?: any
  notes?: string
  posCode?: string
  units?: number | null
  cptModifierCode?: string | null
  drgCode?: string | null
  dateOfService?: string
  referralInvoiceId: number
  description?: string
  diagnosisPointer?: string
  cost?: string
}

interface ActionButtonProps {
  updateActiveStep: (activeStep: number) => void
  activeStep: number
  dataToSubmit: FormData // Data to submit the form if on the final step
  referralInvoiceId: number // ID needed to add a line to the referral invoice only required for final step
  setOpen: (isOpen: boolean) => void
  onSuccess: (line: any) => void
  runValidations: () => boolean // A function that returns true if validations pass
  clearState: () => void // A function to clear the state of all the form fields
}

// A quick helper function to unformat the cost variable before it goes to the API
const unformatCost = (cost: string | undefined) => {
  if (cost) {
    // Remove all formatting ($ and comma) from the cost
    let unformattedCost = cost
    unformattedCost = unformattedCost.replaceAll('$', '')
    unformattedCost = unformattedCost.replaceAll(',', '')
    return unformattedCost
  }

  return cost
}

const ActionButtonsComponent = ({
  updateActiveStep,
  activeStep,
  dataToSubmit,
  referralInvoiceId,
  setOpen,
  onSuccess,
  runValidations,
  clearState,
}: ActionButtonProps) => {
  const { show: showSnackbar } = useSnackbar()
  const submitNewClaimLine = () => {
    const {
      cptCode,
      selectedCostKey,
      notes,
      posCode,
      units,
      cptModifierCode,
      drgCode,
      dateOfService,
      description,
      cost,
      diagnosisPointer,
    } = dataToSubmit

    // Submit the line to the API
    postReferralInvoiceLine(referralInvoiceId, {
      DateOfService: moment(dateOfService).format('YYYY-MM-DD'),
      CptCode: selectedCostKey ? selectedCostKey.CostKey.Code : cptCode,
      Description: selectedCostKey
        ? selectedCostKey.CostKey.Descr
        : description,
      Cost: selectedCostKey ? selectedCostKey.Price : unformatCost(cost),
      Notes: notes,
      PlaceOfServiceCode: posCode,
      ReferralInvoiceID: referralInvoiceId,
      Units: units ? +units : null,
      CPTModifierCode: cptModifierCode || null,
      DRGCode: drgCode || null,
      DiagnosisPointer: diagnosisPointer || null,
      FeeSchedulePriceID: selectedCostKey ? selectedCostKey.ID : null,
    })
      .then((data: any) => {
        onSuccess(data.Data)
        showSnackbar('Invoice line added successfully!', SnackbarTypeSuccess)
        setOpen(false)
      })
      .catch((err: any) => {
        if (err.Error && err.Error[0]) {
          showSnackbar(err.Error[0], SnackbarTypeError)
        } else {
          showSnackbar(
            'There was an issue creating the Invoice line. Please try again or contact us for support.',
            SnackbarTypeError
          )
        }
        console.error('There was an error creating the invoice line', err)
      })
  }

  return (
    <MuiDialogActions>
      {activeStep !== 0 ? (
        <Button
          onClick={() => {
            updateActiveStep(activeStep - 1)
          }}
          color="primary">
          Back
        </Button>
      ) : null}
      {activeStep !== 2 ? (
        <Button
          onClick={() => {
            if (runValidations()) {
              updateActiveStep(activeStep + 1)
            }
          }}
          color="primary">
          Next
        </Button>
      ) : null}
      {activeStep === 2 ? (
        <Button
          onClick={() => {
            if (runValidations()) {
              submitNewClaimLine()
              clearState() // Clear all the form fields in state to start anew
            }
          }}
          color="primary">
          Add Line
        </Button>
      ) : null}
    </MuiDialogActions>
  )
}

const ActionButtons = ActionButtonsComponent

const SelectDateOfService = (props: any) => {
  const { dateOfService, updateDateOfService } = props

  return (
    <>
      <ManagedDateInput
        label="Date of Service"
        value={dateOfService}
        setter={({ name, value }) => updateDateOfService(value)}
      />
    </>
  )
}

// Component Definition
export const InvoiceLineAdd = (props: Props) => {
  const useValidationsState = (initialState: any) => {
    const [state, setState] = useState(initialState)
    const setMergedState = (newState: any) => {
      if (newState !== null) {
        setState((prevState: any) => Object.assign({}, newState))
      } else {
        setState(() => Object.assign({}))
      }
    }
    return [state, setMergedState]
  }

  const [open, setOpen] = useState(false)
  const [activeStep, updateActiveStep] = useState(0)
  const [dateOfService, updateDateOfService] = useState('')
  const [cptCode, updateCptCode] = useState('') // Manually entered CPT Code
  const [description, updateDescription] = useState('')
  const [cost, updateCost] = useState('')
  const [selectedCostKey, updateSelectedCostKey] = useState(null) // Cost key that is selected from our system by the user
  const [posCode, updatePosCode] = useState('')
  const [notes, updateNotes] = useState('')
  const [units, updateUnits] = useState(null)
  const [cptModifierCode, updateCPTModifierCode] = useState(null)
  const [diagnosisPointer, updateDiagnosisPointer] = useState('A')
  const [drgCode, updateDRGCode] = useState(null)
  // An array of validations that need to be shown on the screen
  const [failedValidations, updateFailedValidations] = useState(false)
  const [validationsToDisplay, updateValidationsToDisplay] =
    useValidationsState(null) // String array of validations to show to user

  useEffect(() => {
    // Pre-populate date of service if it exists
    if (props.dateOfService) {
      const formattedDateOfService = moment(props.dateOfService).format(
        'MM/DD/YYYY'
      )
      updateDateOfService(formattedDateOfService)
    }
  }, [props.dateOfService])

  // Clear all the form fields back to their initial state.Fired after line is submitted
  const clearState = () => {
    updateActiveStep(0)
    updateDateOfService(moment(props.dateOfService).format('MM/DD/YYYY'))
    updateCptCode('')
    updateCPTModifierCode(null)
    updateDRGCode(null)
    updateDescription('')
    updateCost('')
    updateSelectedCostKey(null)
    updatePosCode('')
    updateNotes('')
    updateUnits(null)
    updateDiagnosisPointer('A')
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    clearState()
    setOpen(false)
  }

  // Get the content to display based on the active step
  const getStepContentToDisplay = (activeStep: number) => {
    switch (activeStep) {
      case 0:
        return (
          <Grid container direction="row" justify="center">
            <div style={{ textAlign: 'center' }}>
              <Typography variant="h6">What is the date of service?</Typography>
              <SelectDateOfService
                dateOfService={dateOfService}
                updateDateOfService={updateDateOfService}
              />
            </div>
          </Grid>
        )
      case 1:
        return (
          <CptCodeSelection
            PracticeFacilityID={props.PracticeFacilityID}
            dateOfService={dateOfService}
            cptCode={cptCode}
            updateCptCode={updateCptCode}
            cptModifierCode={cptModifierCode}
            updateCPTModifierCode={updateCPTModifierCode}
            drgCode={drgCode}
            updateDRGCode={updateDRGCode}
            selectedCostKey={selectedCostKey}
            updateSelectedCostKey={updateSelectedCostKey}
            cost={cost}
            updateCost={updateCost}
            description={description}
            updateDescription={updateDescription}
            diagnosisPointer={diagnosisPointer}
            updateDiagnosisPointer={updateDiagnosisPointer}
          />
        )
      case 2:
        return (
          <ConfirmDetails
            selectedCostKey={selectedCostKey}
            updateSelectedCostKey={updateSelectedCostKey}
            posCode={posCode}
            updatePosCode={updatePosCode}
            notes={notes}
            updateNotes={updateNotes}
            units={units}
            updateUnits={updateUnits}
            cptCode={cptCode}
            updateCptCode={updateCptCode}
            cptModifierCode={cptModifierCode}
            updateCPTModifierCode={updateCPTModifierCode}
            drgCode={drgCode}
            updateDRGCode={updateDRGCode}
            dateOfService={dateOfService}
            updateDateOfService={updateDateOfService}
            cost={cost}
            updateCost={updateCost}
            description={description}
            updateDescription={updateDescription}
            diagnosisPointer={diagnosisPointer}
            updateDiagnosisPointer={updateDiagnosisPointer}
          />
        )
    }
  }

  // Validations for the date of service section
  // Returns true if validations pass
  const runDateOfServiceValidations = () => {
    let failedValidation = false
    const validations: any = {}

    // Date of service exists
    if (dateOfService === '') {
      validations.dateOfServiceRequired = 'Date of Service is required'
      failedValidation = true
      updateFailedValidations(true)
    }

    // Date of service must be in the past or present
    if (moment(dateOfService).isAfter(moment())) {
      validations.dateOfServiceNotInFuture =
        'Date of Service can not be in the future'
      failedValidation = true
      updateFailedValidations(true)
    }

    updateValidationsToDisplay({ ...validations })

    // If no validations failed then clear all errors and return true
    if (!failedValidation) {
      updateValidationsToDisplay(null)
      updateFailedValidations(false)
      return true
    }

    return false
  }

  // Validations for the choose cpt code section
  const runChooseCptCodeValidations = () => {
    // Cpt code - 5 digits, required
    // Description, required
    // Cost required, better format

    const validations: any = {}
    let failedValidation = false

    // Selected Cost Key is not choosen.
    if (!selectedCostKey) {
      // CPT code exists
      if (cptCode === '') {
        validations.cptCodeRequired = 'CPT code is required'
        failedValidation = true
        updateFailedValidations(true)
      }

      // Cpt Code Exactly 5 digits
      if (cptCode.length !== 5) {
        validations.cptCodeLength = 'CPT is required to be 5 digits'
        failedValidation = true
        updateFailedValidations(true)
      }

      // Description exists
      if (diagnosisPointer === '') {
        validations.diagnosisPointerRequired = 'Diagnosis Pointer is required'
        failedValidation = true
        updateFailedValidations(true)
      }

      // Description exists
      if (description === '') {
        validations.descriptionRequired = 'Description is required'
        failedValidation = true
        updateFailedValidations(true)
      }

      // Cost exists
      if (cost === '') {
        validations.costRequired = 'Cost is required'
        failedValidation = true
        updateFailedValidations(true)
      }
      updateValidationsToDisplay({ ...validations })
    }

    // If no validations failed then clear all errors and return true
    if (!failedValidation) {
      updateValidationsToDisplay(null)
      updateFailedValidations(false)
      return true
    }

    return false
  }

  // Validations for the confirm details section
  const runConfirmDetailsValidations = () => {
    const validations: any = {}
    let failedValidation = false

    // CPT code exists
    if (cptCode === '') {
      validations.cptCodeRequired = 'CPT code is required'
      failedValidation = true
      updateFailedValidations(true)
    }

    // Description exists
    if (description === '') {
      validations.descriptionRequired = 'Description is required'
      failedValidation = true
      updateFailedValidations(true)
    }

    // Diagnosis pointer exists
    if (description === '') {
      validations.diagnosisPointerRequired = 'Diagnosis Pointer is required'
      failedValidation = true
      updateFailedValidations(true)
    }

    // Cost exists
    if (cost === '') {
      validations.costRequired = 'Cost is required'
      failedValidation = true
      updateFailedValidations(true)
    }

    // POS Code is one of the options we have available
    if (
      posCode === '' ||
      (posCode !== '' &&
        !['02', '10', '11', '12', '21', '22', '23', '24', '49', '81'].includes(
          posCode
        ))
    ) {
      validations.validPOS =
        'POS Code must be one of the following: 02, 10, 11, 12, 21, 22, 23, 24, 49, 81'
      failedValidation = true
      updateFailedValidations(true)
    }

    updateValidationsToDisplay({ ...validations })

    // If no validations failed then clear all errors and return true
    if (!failedValidation) {
      updateValidationsToDisplay(null)
      updateFailedValidations(false)
      return true
    }

    return false
  }

  // Run validations for the form based on the active step
  // returns true if validations pass
  const runValidations = (): boolean => {
    let validationsSucceeded = false

    switch (activeStep) {
      case 0:
        validationsSucceeded = runDateOfServiceValidations()
        break
      case 1:
        validationsSucceeded = runChooseCptCodeValidations()
        break
      case 2:
        validationsSucceeded = runConfirmDetailsValidations()
        break
    }

    return validationsSucceeded
  }

  // Render form validations if necessary
  const displayValidations = () => {
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          marginTop: 50,
          color: 'red',
        }}>
        {_.map(validationsToDisplay, (item: any) => {
          return <p>{item}</p>
        })}
      </div>
    )
  }

  return (
    <div>
      <Button
        disabled={!props.enabled}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}>
        Add Invoice Line
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth>
        <DialogTitle activeStep={activeStep} />
        <MuiDialogContent dividers>
          {getStepContentToDisplay(activeStep)}
          {displayValidations()}
        </MuiDialogContent>
        <ActionButtons
          activeStep={activeStep}
          updateActiveStep={updateActiveStep}
          dataToSubmit={{
            dateOfService,
            selectedCostKey,
            notes,
            posCode,
            units,
            cptCode,
            cptModifierCode,
            drgCode,
            description,
            cost,
            diagnosisPointer,
            referralInvoiceId: props.referralInvoiceID, // make sure that matches with the prop passed below in this same component
          }}
          referralInvoiceId={props.referralInvoiceID} // TO DO be sure referral invoice ID passes in correctly
          setOpen={setOpen} // Used to close the dialog after adding line
          onSuccess={props.onSuccess} // To run on successful add
          runValidations={runValidations}
          clearState={clearState}
        />
      </Dialog>
    </div>
  )
}
