import React from 'react'
import { Lock as IconLock } from '@material-ui/icons'
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
  Tooltip,
} from '@material-ui/core'
import { CptCodeInput, CurrencyInput } from '../Inputs'
import CPTModifierCodeAutoComplete from '../Inputs/cptModifierCodeAutoComplete'
import DRGCodeAutoComplete from '../Inputs/drgCodeAutoComplete'
import { DiagnosisPointerInput } from './DiagnosisPointerInput'
import ManagedDateInput from '../Inputs/managedDateInput'

const ConfirmDetails = ({
  updateDiagnosisPointer,
  diagnosisPointer,
  ...props
}: any) => {
  let drgIsModdable = true
  // If the cost key was selected then fill description, CPT code, DRG, and cost
  if (props.selectedCostKey) {
    props.updateDescription(props.selectedCostKey.CostKey.Descr)
    props.updateCost(props.selectedCostKey.Price)
    props.updateCptCode(props.selectedCostKey.CostKey.Code)
    if (
      props.selectedCostKey.CostKey &&
      props.selectedCostKey.CostKey.DRGCode
    ) {
      props.updateDRGCode(props.selectedCostKey.CostKey.DRGCode)
      drgIsModdable = false
    }
  }

  const IsLockedIndicator = () => (
    <Tooltip title="From selected bundle (cannot be modified)">
      <IconLock fontSize="inherit" />
    </Tooltip>
  )

  const inputCPTModifier = () => (
    <CPTModifierCodeAutoComplete
      selectedModifierCode={props.cptModifierCode}
      onChange={(code: string, _: any) => {
        props.updateCPTModifierCode(code)
      }}
      TextFieldProps={{
        InputLabelProps: {
          shrink: true,
        },
      }}
      // AutoCompleteProps={{style: {minWidth: 380}}}
    />
  )

  const inputDRG = () => (
    <DRGCodeAutoComplete
      selectedDRGCode={props.drgCode}
      onChange={(code: string, _: any) => {
        props.updateDRGCode(code)
      }}
      TextFieldProps={{
        label: drgIsModdable ? (
          'DRG Code'
        ) : (
          <>
            DRG Code <IsLockedIndicator />
          </>
        ),
        InputLabelProps: {
          shrink: true,
        },
        helperText: drgIsModdable
          ? ''
          : 'DRG assigned via bundle and cannot be modified',
      }}
      AutoCompleteProps={{
        disabled: !drgIsModdable,
      }}
    />
  )

  const inputUnits = () => (
    <FormControl fullWidth>
      <InputLabel>Units</InputLabel>
      <Input
        name="Units"
        value={props.units}
        onChange={(event: any) => props.updateUnits(event.target.value)}
      />
    </FormControl>
  )

  const inputDateOfService = () => (
    <ManagedDateInput
      label="Date of Service"
      value={props.dateOfService}
      setter={({ name, value }) => props.updateDateOfService(value)}
      fullWidth
    />
  )

  const inputCPTOrBundleCode = () =>
    props.selectedCostKey ? (
      <FormControl fullWidth>
        <InputLabel>
          Selected Bundle <IsLockedIndicator />
        </InputLabel>
        <Input
          name="Bundle Code"
          value={
            props.selectedCostKey.CostKey
              ? props.selectedCostKey.CostKey.Code
              : ''
          }
          disabled
        />
      </FormControl>
    ) : (
      <CptCodeInput
        onChange={(event: any) => props.updateCptCode(event.target.value)}
        value={props.cptCode}
        label="CPT Code"
      />
    )

  const inputDescr = () => (
    <TextField
      onChange={(event: any) => props.updateDescription(event.target.value)}
      value={
        props.selectedCostKey
          ? props.selectedCostKey.CostKey.Descr
          : props.description
      }
      name="Description"
      label={
        <>
          Description {!!props.selectedCostKey ? <IsLockedIndicator /> : null}
        </>
      }
      multiline //={true}
      disabled={!!props.selectedCostKey}
      fullWidth
    />
  )

  const inputCost = () =>
    props.selectedCostKey ? (
      <FormControl fullWidth>
        <InputLabel>
          Cost <IsLockedIndicator />
        </InputLabel>
        <Input
          name="Cost"
          value={props.selectedCostKey.Price}
          onChange={(event: any) => props.updateCost(event.target.value)}
          disabled
        />
      </FormControl>
    ) : (
      <CurrencyInput
        label="Cost"
        name="Cost"
        value={props.cost}
        onChange={(event: any) => props.updateCost(event.target.value)}
        fullWidth
      />
    )

  const inputPOSCode = () => (
    <FormControl fullWidth>
      <InputLabel>POS Code</InputLabel>
      <Input
        name="POS Code"
        value={props.posCode}
        onChange={(event: any) => props.updatePosCode(event.target.value)}
        inputProps={{
          maxLength: 2,
        }}
      />
    </FormControl>
  )

  const inputNotes = () => (
    <TextField
      label="Notes"
      name="Notes"
      value={props.notes}
      onChange={(event: any) => props.updateNotes(event.target.value)}
      multiline
      fullWidth
    />
  )

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      style={{ margin: '0 auto', maxWidth: 600 }}>
      <Grid item xs={4}>
        {inputDateOfService()}
      </Grid>

      <Grid item xs={4}>
        {inputCPTOrBundleCode()}
      </Grid>

      <Grid item xs={4}>
        {inputCost()}
      </Grid>

      <Grid item xs={4}>
        {inputPOSCode()}
      </Grid>

      <Grid item xs={4}>
        {inputUnits()}
      </Grid>

      <Grid item xs={4}>
        {inputNotes()}
      </Grid>

      <Grid container item spacing={2} direction="row" justify="space-between">
        <Grid item xs={4}>
          {inputCPTModifier()}
        </Grid>

        <Grid item xs={4}>
          {inputDRG()}
        </Grid>

        <Grid item xs={4}>
          <DiagnosisPointerInput
            label="Diagnosis Pointer"
            value={diagnosisPointer}
            onChange={(pointer: string, _: any) => {
              updateDiagnosisPointer(pointer)
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {inputDescr()}
      </Grid>
    </Grid>
  )
}

export default ConfirmDetails
