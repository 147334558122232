import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles = () => {
  return {
    progress: {
      width: '100%',
      marginTop: '20px',
    },
  }
}

const loginCallback = ({ classes }: { classes: ClassNameMap<string> }) => {
  return (
    <div>
      <LinearProgress className={classes.progress} />
    </div>
  )
}

export const Callback = withStyles(styles)(loginCallback)
