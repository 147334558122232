import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import * as FileUpload from '../../components/FileUpload'
import { AttachFile } from '@material-ui/icons'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  min-width: 500px;
`

const AddButton = styled(Button)`
  margin-bottom: 5px;
`

interface UploadModalProps {
  disabled?: boolean
  FileUploadProps: FileUpload.props
  closeModal?: () => void
}

const FileUploadModal = ({
  disabled,
  FileUploadProps,
  closeModal = () => null,
}: UploadModalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const handleClose = () => {
    closeModal()
    setModalOpen(false)
  }

  return (
    <>
      <AddButton
        variant="contained"
        color="secondary"
        disabled={disabled}
        onClick={() => setModalOpen(true)}>
        <AttachFile />
        Upload file
      </AddButton>
      <Dialog open={modalOpen}>
        <DialogTitle>Upload File</DialogTitle>
        <StyledDialogContent>
          <FileUpload.Uploader
            multiple
            {...FileUploadProps}
            CustomFileDisplay={(props: any) => {
              return <FileUpload.DefaultSingleFileInput {...props} />
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FileUploadModal
