import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import SaveIcon from '@material-ui/icons/Save'
import PublishIcon from '@material-ui/icons/Publish'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'

import { COLORS } from '../../utils/colors'
import {
  STATUS_DRAFT,
  STATUS_SUBMITTED,
  STATUS_REJECTED,
  STATUS_ACCEPTED,
  STATUS_INVOICED,
  STATUS_IGNORED,
} from './statuses'
import { ReferralInvoice } from '../ReferralInvoiceForm/types'

const getWorkflowDescr = (id: number, previousStatusID?: number) => {
  switch (id) {
    case STATUS_DRAFT:
      switch (previousStatusID) {
        case STATUS_IGNORED:
          return 'Revert to Draft'
        default:
          return 'Save as Draft'
      }
    case STATUS_SUBMITTED:
      return 'Submit for Review'
    case STATUS_REJECTED:
      return 'Reject this invoice'
    case STATUS_ACCEPTED:
      switch (previousStatusID) {
        case STATUS_ACCEPTED:
          return 'Save'
        default:
          return 'Accept this invoice'
      }
    case STATUS_INVOICED:
      return 'Issue Invoice'
    case STATUS_IGNORED:
      return 'Ignore invoice'
  }
}

interface WorkflowActionsProps {
  referralInvoice: ReferralInvoice
  onSave: (statusId: number, message: string) => void
}

export const WorkflowActions: React.FC<WorkflowActionsProps> = ({
  referralInvoice,
  onSave,
}) => {
  const [dialogOpen, updateDialogOpen] = useState(false)

  if (referralInvoice.NextStatuses.length === 0) return null

  // Render a save button based off of the status ID
  const SaveButton: React.FC<{ statusId: number; prevStatusId?: number }> = ({
    statusId,
    prevStatusId,
  }) => {
    let buttonColor = COLORS.PROVIDERS.BLUEBERRY
    let icon = <SaveIcon />
    let successMessage = ''

    // Set the button color based off the status ID
    switch (statusId) {
      case STATUS_DRAFT:
        buttonColor = COLORS.PROVIDERS.FUSCIA
        icon = <UnarchiveIcon />
        successMessage = 'Saved as Draft'
        break
      case STATUS_SUBMITTED:
        buttonColor = COLORS.PROVIDERS.BLUEBERRY
        icon = <PublishIcon />
        successMessage = 'Invoice Submitted'
        break
      case STATUS_REJECTED:
        buttonColor = COLORS.PROVIDERS.FUSCIA
        icon = <ThumbDownIcon />
        successMessage = 'Invoice Rejected'
        break
      case STATUS_ACCEPTED:
        switch (prevStatusId) {
          case STATUS_ACCEPTED:
            buttonColor = COLORS.PROVIDERS.FUSCIA
            icon = <SaveIcon />
            successMessage = 'Save'
            break
          default:
            buttonColor = COLORS.PROVIDERS.EGGPLANT
            icon = <ThumbUpIcon />
            successMessage = 'Invoice Accepted'
        }
        break
      case STATUS_INVOICED:
        buttonColor = COLORS.PROVIDERS.EGGPLANT
        icon = <ThumbUpIcon />
        successMessage = 'Invoice Issued'
        break
      case STATUS_IGNORED:
        buttonColor = COLORS.PROVIDERS.FUSCIA
        icon = <ArchiveIcon />
        successMessage = 'Invoice Ignored'
        break
    }

    return (
      <Button
        startIcon={icon}
        onClick={() => onSave(statusId, successMessage)}
        style={{ backgroundColor: buttonColor, color: 'white' }}>
        {getWorkflowDescr(statusId, referralInvoice.ReferralInvoiceStatusID)}
      </Button>
    )
  }

  const ConfirmSubmitDialog = ({ isOpen, updateIsOpen }: any) => {
    return (
      <Dialog
        open={isOpen}
        onClose={() => updateIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Submit For Review'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are the details correct on this invoice? You will not be able to
            edit the invoice until it is approved or rejected.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => updateIsOpen(false)}>Cancel</Button>
          <SaveButton statusId={STATUS_SUBMITTED} />
        </DialogActions>
      </Dialog>
    )
  }

  // A little hack to display buttons in the correct spot (ie: submit button on right)
  const NextStatusesToDisplay =
    referralInvoice.ReferralInvoiceStatusID === STATUS_DRAFT ||
    referralInvoice.ReferralInvoiceStatusID === STATUS_REJECTED ||
    referralInvoice.ReferralInvoiceStatusID == STATUS_ACCEPTED
      ? referralInvoice.NextStatuses.slice().reverse()
      : referralInvoice.NextStatuses

  return (
    <>
      <ConfirmSubmitDialog
        isOpen={dialogOpen}
        updateIsOpen={updateDialogOpen}
      />
      {NextStatusesToDisplay &&
        NextStatusesToDisplay.map((status: any) => {
          return status.ID === STATUS_SUBMITTED ? (
            <Button
              onClick={() => updateDialogOpen(true)}
              style={{ backgroundColor: COLORS.PROVIDERS.BLUEBERRY }}
              classes={{
                root: 'MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-240 MuiButton-containedPrimary',
              }}>
              <PublishIcon
                classes={{
                  root: 'MuiSvgIcon-root RaSaveButton-leftIcon-90 RaSaveButton-icon-91',
                }}
              />
              {getWorkflowDescr(STATUS_SUBMITTED)}
            </Button>
          ) : (
            <SaveButton
              statusId={status.ID}
              prevStatusId={referralInvoice.ReferralInvoiceStatusID}
            />
          )
        })}
    </>
  )
}
