import React from 'react'
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'

type Option = { name?: string | any; value: any; disabled?: boolean }

interface Props {
  value?: any
  onChange: (val: any) => void
  error?: string | null
  label?: string
  options: Option[]
  multiple?: boolean
  disabled?: boolean
}

const SelectField: React.FC<Props> = ({
  label,
  options,
  value,
  onChange,
  error,
  multiple,
  disabled,
}) => {
  const renderMultiple = (selected: any) => {
    if (Array.isArray(selected)) {
      const optionMap = options.reduce((obj: any, option: Option) => {
        obj[option.value] = option.name
        return obj
      }, {})

      const names = selected.map((s: number) => optionMap[s])

      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {names.map((name: string) => (
            <Chip key={name} label={name} />
          ))}
        </div>
      )
    }

    return null
  }

  return (
    <FormControl style={{ flex: 1, width: '100%' }}>
      {label && <InputLabel shrink={!!value}>{label}</InputLabel>}
      <Select
        value={value}
        onChange={(evt: any) => {
          onChange(evt.target.value)
        }}
        renderValue={multiple ? renderMultiple : undefined}
        multiple={multiple}
        disabled={disabled}>
        {options.map(({ name, value, disabled }: Option, idx: number) => {
          return (
            <MenuItem
              key={`${name}.${value || '_empty_'}.${idx}`}
              value={value}
              disabled={!!disabled}>
              {name}
            </MenuItem>
          )
        })}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
