import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { LibraryAdd as CloneIcon } from '@material-ui/icons'
import ReferralService from '../../services/ReferralService'

interface Props extends React.ComponentProps<typeof Button> {
  record: { ID: number }
}

const CloneReferralButton: React.FC<Props> = (props) => {
  const hist = useHistory()
  const [isCloning, setIsCloning] = useState(false)

  const clone: any = async () => {
    setIsCloning(true)
    const referralId = props.record.ID
    const clonedReferral = await ReferralService.cloneReferral(referralId)
    setIsCloning(false)
    hist.push('/referral/create', { record: clonedReferral })
  }

  return (
    <Button
      size="small"
      disabled={isCloning}
      onClick={clone}
      startIcon={<CloneIcon />}
      color="primary"
      {...props}>
      Clone
    </Button>
  )
}

export default CloneReferralButton
