import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import { FileActions } from '../../actions'
import { LinearProgress, Typography } from '@material-ui/core'
import { REFERRAL_REQUEST_FILE } from '../../actions/FileActions'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const {
  getReferralInvoiceFileDownloadUrl,
  getReferralFileDownloadUrl,
  getReferralRequestFileDownloadUrl,
  REFERRAL_FILE,
  REFERRAL_INVOICE_FILE,
} = FileActions

const styles = () => {
  return {
    progress: {
      width: '100%',
      marginTop: '20px',
    },
  }
}

interface DownloadFileProps {
  location: Location
  classes: ClassNameMap<string>
}

const downloadFile = (props: DownloadFileProps) => {
  const [showLoading, setShowLoading] = useState(true)
  const [type, setType] = useState<string | string[] | null>()
  const [id, setId] = useState<string | string[] | null>()
  const [fileId, setFileId] = useState<string | string[] | null>()

  useEffect(() => {
    const { search } = props.location
    const { type, id, fileId } = queryString.parse(search)

    setType(type)
    setId(id)
    setFileId(fileId)

    let timeoutId = setTimeout(() => {
      setShowLoading(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  useEffect(() => {
    switch (type) {
      case REFERRAL_FILE:
        getReferralFileDownloadUrl({ id, fileId }).then((res: any) => {
          window.open(res.Data.url, '_blank')
        })
        break
      case REFERRAL_INVOICE_FILE:
        getReferralInvoiceFileDownloadUrl({ id, fileId }).then((res: any) => {
          window.open(res.Data.url, '_blank')
        })
        break
      case REFERRAL_REQUEST_FILE:
        getReferralRequestFileDownloadUrl({ id, fileId }).then((res: any) => {
          window.open(res.Data.url, '_blank')
        })
        break
      default:
        break
    }
  }, [type])

  return (
    <div>
      {showLoading ? (
        <LinearProgress className={props.classes.progress} />
      ) : (
        <div />
      )}
      <Typography>
        Your file should have begun downloading. You can now close this window.
      </Typography>
    </div>
  )
}

export const DownloadFile = withStyles(styles)(downloadFile)
