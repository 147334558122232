import React from 'react'
import moment from 'moment'
import FormHeader from './FormHeader'
import EmployerExclusions from '../EmployerExclusions/EmployerExclusions'
import styled from 'styled-components'
import useApi from '../../hooks/useApi'

const StyledPadding = styled.div`
  padding: 1rem 0;
`

interface exclusionProps {
  employerID: number | null
}

const Exclusions: React.FC<exclusionProps> = ({ employerID }) => {
  const { data } = useApi(
    !!employerID
      ? { route: `/employer/${employerID}/plan_exclusions` }
      : undefined
  )

  if (!data || !data.PlanExclusions || data.PlanExclusions.length === 0) {
    return null
  }

  const exclusions = data.PlanExclusions.filter((ex: any) => ex.Status > 0)

  const deductibleStartDate = moment(
    `${data.DeductibleStartMonth}/${data.DeductibleStartDay}`
  ).format('MM/DD')
  const deductibleEndDate = moment(
    `${data.DeductibleStartMonth}/${data.DeductibleStartDay}`
  )
    .subtract(1, 'days')
    .format('MM/DD')

  const hasInvalidDates =
    deductibleEndDate === 'Invalid date' ||
    deductibleStartDate === 'Invalid date'
  return (
    <StyledPadding>
      <FormHeader title="Employer Exclusions" />
      <EmployerExclusions
        exclusions={exclusions}
        hasInvalidDates={hasInvalidDates}
        deductibleStartDate={deductibleStartDate}
        deductibleEndDate={deductibleEndDate}
      />
    </StyledPadding>
  )
}

export default Exclusions
