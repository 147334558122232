import React, { useState } from 'react'
import useSnackbar from '../../hooks/useSnackbar'
import moment from 'moment'
import {
  Button,
  TextField,
  Tooltip,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { CptCodeInput, CurrencyInput } from '../Inputs'
import { ReferralInvoiceActions } from '../../actions/index'
import { FeeSchedulePricesSelect } from '../FeeSchedulePrices'
import CPTModifierCodeAutoComplete from '../Inputs/cptModifierCodeAutoComplete'
import DRGCodeAutoComplete from '../Inputs/drgCodeAutoComplete'
import { DiagnosisPointerInput } from './DiagnosisPointerInput'

const { feeSchedulePriceSearch } = ReferralInvoiceActions

const CptCodeSelectionComponent = (props: any) => {
  const {
    cptCode,
    updateCptCode,
    dateOfService,
    PracticeFacilityID,
    description,
    updateDescription,
    cost,
    updateCost,
    diagnosisPointer,
    updateDiagnosisPointer,
  } = props
  const [searchResults, updateSearchResults] = useState([])
  const [cptCodeToSearch, updateCptCodeToSearch] = useState('')
  const [cptInputMethod, updateCptInputMethod] = useState(0)
  const [searchLoading, updateSearchLoading] = useState(false)
  const [searchHasTakenPlace, updateSearchHasTakenPlace] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()

  // Handle the case where a user is searching for a cost key to link
  const handleCptCodeSearchForCostKeyLinking = () => {
    // Text Mask pre-populates value with five _'s. If there are no _'s then five digits have been entered
    if (cptCodeToSearch) {
      updateSearchHasTakenPlace(true)
      feeSchedulePriceSearch({
        PracticeFacilityID,
        ServiceDate: moment(dateOfService).format('YYYY-MM-DD'),
        q: cptCodeToSearch,
      })
        .then((data: any) => {
          updateSearchLoading(false)
          updateSearchResults(data.Data)
          if (data.Data && data.Data.length === 0) {
            showSnackbar('No match found for that code', 'error', 5000)
          }
        })
        .catch((err: any) => {
          updateSearchLoading(false)
          showSnackbar('No match found for that code', 'error', 5000)
          console.error('Error getting CPT code prices', err)
        })
    }
  }

  // Handle CPT Code change for manual entry (no link to a cost key in our database)
  const handleCptCodeChange = (event: any) => {
    updateCptCode(event.target.value)
    if (props.selectedCostKey) {
      props.updateSelectedCostKey(null)
    }
  }

  // Determine what the title text should be based on the input method being displayed
  const titleText =
    cptInputMethod === 0
      ? 'How would you like to input a CPT Code?'
      : cptInputMethod === 1
        ? ''
        : 'Manually enter CPT Code Information'

  const onBundleSearch = (event: any) => {
    event.preventDefault()
    updateSearchLoading(true)
    // Only search if there are more than two numbers sent
    if (cptCodeToSearch.length > 2) {
      handleCptCodeSearchForCostKeyLinking()
    } else {
      showSnackbar('Search must be at least 3 characters long', 'error', 3000)
      updateSearchLoading(false)
    }
  }

  // Show either the buttons that show the choices, or one of the two options for entering the CPT code
  const getContentToDisplay = () => {
    switch (cptInputMethod) {
      // Display buttons to select option to choose from
      case 0:
        return (
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: '0 auto', maxWidth: 600 }}>
            <Grid item>
              <Button
                onClick={() => updateCptInputMethod(1)}
                variant="contained">
                Select CPT Code or Bundle
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => updateCptInputMethod(2)}
                variant="contained">
                Manually Assign CPT Code
              </Button>
            </Grid>
          </Grid>
        )
      // Display CPT search
      case 1:
        return (
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <form
                onSubmit={onBundleSearch}
                style={{ display: 'inline-block' }}>
                <CptCodeInput
                  onChange={(event: any) => {
                    updateCptCodeToSearch(event.target.value.trim())
                  }}
                  value={cptCodeToSearch}
                  label="CPT or DRG Code"
                />
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginLeft: 20, marginTop: 10 }}>
                  Search
                </Button>
              </form>
            </Grid>

            <Grid item xs={12}>
              {searchLoading ? (
                <CircularProgress style={{ color: '#0059c9', marginTop: 50 }} />
              ) : searchHasTakenPlace ? (
                searchResults.length !== 0 ? (
                  <CptCodeSearchResults
                    updateSelectedCostKey={props.updateSelectedCostKey}
                    searchResults={searchResults}
                  />
                ) : (
                  <p>No results found for that code.</p>
                )
              ) : null}
            </Grid>
          </Grid>
        )
      // Display manual CPT entrance
      case 2:
        return (
          <Grid
            container
            spacing={2}
            direction="row"
            style={{ margin: '0 auto', maxWidth: 600 }}>
            <Grid item xs={4}>
              <CptCodeInput
                updateCptCode={props.updateCptCode}
                onChange={handleCptCodeChange}
                value={cptCode}
                label="CPT Code"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                onChange={(event: any) => updateDescription(event.target.value)}
                value={description}
                name="Description"
                label="Description"
                multiline
              />
            </Grid>
            <Grid item xs={4}>
              <CurrencyInput
                label="Cost"
                name="Cost"
                value={cost}
                onChange={(event: any) => updateCost(event.target.value)}
              />
            </Grid>
            <Grid item xs={8}>
              <CPTModifierCodeAutoComplete
                selectedModifierCode={props.cptModifierCode}
                onChange={(code: string, _: any) => {
                  props.updateCPTModifierCode(code)
                }}
                TextFieldProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                // AutoCompleteProps={{style: {minWidth: 380} }}
              />
            </Grid>
            <Grid item xs={4}>
              <DRGCodeAutoComplete
                selectedDRGCode={props.drgCode}
                onChange={(code: string, _: any) => {
                  props.updateDRGCode(code)
                }}
                TextFieldProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <DiagnosisPointerInput
                label="Diagnosis Pointer"
                value={diagnosisPointer}
                onChange={(pointer: string, _: any) => {
                  updateDiagnosisPointer(pointer)
                }}
              />
            </Grid>
            <Grid item xs={4} />
          </Grid>
        )
    }
  }

  return (
    <Grid container direction="row" justify="center">
      <Grid item xs={12}>
        <Typography variant="h6" align="center">
          {titleText}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {getContentToDisplay()}
      </Grid>
    </Grid>
  )
}

/**
 * Dispaly a CPT code result
 */
const CptCodeSearchResults = (props: any) => {
  return (
    <>
      <FeeSchedulePricesSelect
        updateSelectedCostKey={props.updateSelectedCostKey}
        prices={props.searchResults}
      />
    </>
  )
}

export const CptCodeSelection = CptCodeSelectionComponent
