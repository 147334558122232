import React from 'react'
import { TextField } from '@material-ui/core'

interface DiagnosisPointerProps {
  onChange: any
  label: string
  value: string
}

export const DiagnosisPointerInput = ({
  onChange,
  label,
  value,
}: DiagnosisPointerProps) => {
  const matcher = (val: string) => {
    if (!val) return onChange('A') // always default to "A"
    // uppercase input for convenience
    const uppercasedVal = val.toUpperCase()
    const pattern = /A[A-J]*/
    const match = uppercasedVal.match(pattern)
    if (match) {
      console.debug('Matched: ', match[0], match)
      onChange(match[0])
      return
    }
    console.debug('failed to match')
  }

  return (
    <TextField
      onChange={(event: any) => {
        event.stopPropagation()
        matcher(event.target.value)
      }}
      value={value}
      name="DiagnosisPointer"
      label={label}
    />
  )
}
