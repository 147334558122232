import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import {
  findPricesAvailableByRefID,
  saveReferralPrices,
} from '../../actions/PriceActions'
import { Add as IconAdd, Delete as IconDelete } from '@material-ui/icons'
import _ from 'lodash'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { ReferralPriceBundle } from './types'

const styles = () => {
  return {}
}

/*
Developer notes: there is a specific scenario we _may_ run into that could be considered a bit of
a widow-maker; if the referral was saved with prices from fee schedules that were valid when the
referral was first created, then things change in the fee schedule (lets say a price gets terminated
on a certain date), such that the price would **no longer be valid during the Service Date of the
referral**, then we'd end up in a state where a referral can be assigned a price that has since transitioned
to being... well, wrong. @ginman, @wdepril: is this something we're particularly concerned about?
*/

interface ReferralPricesDialogProps {
  referralId: number
  onSave: () => void
  classes: ClassNameMap<string>
  open?: boolean
  closeDialog?: () => void
  existingSelections?: (ReferralPriceBundle | null)[] | never
  hintMainCPT: string | null
}

const referralPricesDialog = ({
  referralId,
  onSave,
  classes,
  open,
  closeDialog,
  existingSelections = [],
  hintMainCPT,
}: ReferralPricesDialogProps) => {
  const [priceList, setPriceList] = useState<ReferralPriceBundle[]>([])
  const [selected, setSelected] = useState<(ReferralPriceBundle | null)[]>([])
  const [filterText, setFilterText] = useState('')
  const [filteredPrices, setFilteredPrices] = useState<ReferralPriceBundle[]>(
    []
  )

  useEffect(() => {
    let getSelected: (ReferralPriceBundle | null)[] =
      reduceSelections(existingSelections)
    setSelected(getSelected)

    findPricesAvailableByRefID({ referralId })
      .then((res: any) => {
        setPriceList(res.Data)
        setFilteredPrices(res.Data)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  const reduceSelections = (
    bundles: (ReferralPriceBundle | null)[]
  ): (ReferralPriceBundle | null)[] => {
    return bundles.reduce(
      (coll: (ReferralPriceBundle | null)[], v: ReferralPriceBundle | null) => {
        if (v && coll) {
          coll[v.ID] = v
        }
        return coll
      },
      selected
    )
  }

  const doSelectPrice = (p: ReferralPriceBundle) => {
    setSelected({ [+p.ID]: p, ...selected })
  }

  const doRemovePrice = (p: ReferralPriceBundle) => {
    setSelected((current: (ReferralPriceBundle | null)[]) => {
      const copy = { ...current }
      delete copy[p.ID]
      return copy
    })
  }

  // debounced functions should be bound on the instance; we use this whenever
  // the text "search" changes, so we don't pound the UI on every key change
  const _applyFilter = _.debounce((val = '') => {
    if (!!val.length === false) {
      setFilteredPrices(priceList)
      return
    }
    let getFilteredPrices = priceList.filter((v) => {
      if ((v.CostKey.Code || '').indexOf(val) !== -1) {
        return true
      }
      return false
    })
    setFilteredPrices(getFilteredPrices)
  }, 250)

  const onFilterChange = (ev: any) => {
    const val = ev.target.value || ''
    setFilterText(val)
    _applyFilter(val)
  }

  const savePrices = () => {
    saveReferralPrices({
      referralId: referralId,
      priceIDs: Object.keys(selected).map((v) => {
        return +v /* cast numeric type */
      }),
    })
      .then((res: any) => {
        onSave()
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  const renderTable = (
    data: (ReferralPriceBundle | null)[] = [],
    opts: any = {}
  ) => {
    if (!data.length) {
      return
    }

    const hasPrices = data[0] && data[0].Price != null

    opts = {
      onRowClick: () => {
        /* no-op */
      },
      icon: null,
      ...opts,
    }
    return (
      <Table padding="default" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Cost Key</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Bundle Contents</TableCell>
            {hasPrices ? <TableCell>Price</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data)
            ? data.map((row, index) => {
                return (
                  row && (
                    <TableRow key={index}>
                      <TableCell style={{ width: '1%' }}>
                        <IconButton
                          className={classes.button}
                          onClick={opts.onRowClick.bind(this, row)}>
                          {opts.icon}
                        </IconButton>
                      </TableCell>
                      <TableCell>{row.CostKey.Code}</TableCell>
                      <TableCell>{row.CostKey.Descr}</TableCell>
                      <TableCell>{row.CostKey.BundleContents}</TableCell>
                      <TableCell>
                        {hasPrices ? '$' + row.Price : null}
                      </TableCell>
                    </TableRow>
                  )
                )
              })
            : null}
        </TableBody>
      </Table>
    )
  }

  return (
    <div>
      {!open ? null : (
        <div>
          <Dialog open={open} fullWidth maxWidth="lg">
            <DialogTitle>Manage Prices</DialogTitle>
            <DialogContent>
              <h4>Selected Cost Keys (and associated price):</h4>
              {Object.keys(selected).length ? (
                renderTable(Object.values(selected), {
                  onRowClick: doRemovePrice,
                  icon: <IconDelete />,
                })
              ) : (
                <p>No prices selected</p>
              )}
              <Divider />
              <h4>Available Cost Keys</h4>
              <TextField
                className={classes.textField}
                value={filterText}
                onChange={onFilterChange}
                placeholder="Type to filter..."
              />
              {hintMainCPT ? (
                <small>Hint: referral Main CPT Code: {hintMainCPT}</small>
              ) : null}
              {!!filteredPrices.length ? (
                renderTable(filteredPrices, {
                  onRowClick: doSelectPrice,
                  icon: <IconAdd />,
                })
              ) : (
                <p>No prices available</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={savePrices} color="primary">
                Save
              </Button>
              <Button onClick={closeDialog} color="default">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(referralPricesDialog)
