import React from 'react'
import { Button } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import AddUserDialog from './AddUserDialog'

const AddUserButton = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  return (
    <>
      <Button
        color="primary"
        startIcon={<PersonAdd />}
        onClick={() => setDialogOpen(true)}>
        Add User
      </Button>
      <AddUserDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </>
  )
}

export default AddUserButton
