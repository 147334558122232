export enum statuses {
  IN_FLIGHT = 'inFlight',
  DONE = 'done',
  START = 'start',
  SIGNED = 'signed',
  UPLOADED = 'uploaded',
  ERRORED = 'errored',
}

export interface customDisplayProps {
  file: decoratedFile

  setter(data: fileFields & any): void
}

export interface fileFields {
  FileTypeID: number
  ReferralID?: number
  ReferralinvoiceID?: number
}

export interface postFileArgs extends fileFields {
  S3Key: string | null
  Size: number | null
}

export interface postFileResponse extends postFileArgs {
  FileID: number
}

export interface FileRecord {
  Data: postFileResponse
}

export interface decoratedFile {
  key: string
  domFileObj: File
  presignResponse: presignResponse | null
  progress: number
  err: (Error & any) | null
  fileRec: FileRecord | null
  postFileData?: fileFields
  objectURL: string
}

export interface presignResponse {
  S3Key: string
  UUID?: string | null
  Url: string
}

export interface managedFileCollection {
  [index: string]: decoratedFile
}

export interface coordinator {
  status: statuses | null
  // Note: managedFiles is an OBJECT, *not* an array (although it looks similar). Instead,
  // the keys of the object are the same as their **index in the array of selectedFiles**
  // that we keep track of w/ useState. Doing this with an makes state management easier,
  // when updating deeply-targeted fields.
  managedFiles: managedFileCollection | null
}

export interface FileTypeData {
  ID: number
  CreatedAt: string
  CreatedByUserID: number
  Description: string
  Hash: string
  IsEmployerMemberType: boolean
  IsEmployerType: boolean
  IsLoaType: boolean
  IsOrganizationType: boolean
  IsPHIType: boolean
  IsPartnerType: boolean
  IsReferrerType: boolean
  ManagedInternally: boolean
  ModifiedByUserID: number
  UpdatedAt: string
}

export interface ReferralFile {
  ReferralID: number
  FileID: number
  CreatedAt: string
  CreatedBy: string
  FileTypeID: number
  S3Key: string
  Size: number
}

export interface ReferralInvoiceFile extends Omit<ReferralFile, 'ReferralID'> {
  ReferralInvoiceID: number
}

export const REFERRAL_FILE_TYPE = 33
export const REFERRAL_INVOICE_FILE_TYPE = 56
export const REFERRAL_REQUEST_FILE_TYPE = 74
export const REFERRAL_INVOICE_FILE = 'referral_invoice'
