import React from 'react'
import styled from 'styled-components'

const ReportViewIframe = styled.iframe`
  margin-top: 1rem;
  width: 100%;
  min-height: 75vh;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
`

interface props {
  reportURL?: string
}

export default function ReportDisplay({
  reportURL,
}: props): React.ReactElement | null {
  if (!reportURL) return null
  return (
    <ReportViewIframe
      sandbox="allow-downloads allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
      title="EmbeddedReport"
      src={reportURL}
    />
  )
}
