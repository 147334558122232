import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'

interface Props {
  to: string
  children: string // link text
}

const COLOR = '#0059c9'

const BacklinkText = styled(Typography)`
  display: inline-block;
  padding-bottom: 10px;
  cursor: pointer;
  color: ${COLOR};
`

const BacklinkIcon = styled(OpenInNewIcon)`
  color: ${COLOR};
  font-size: 1rem;
  vertical-align: text-top;

  :hover {
    cursor: pointer;
  }
`

const Backlink: React.FC<Props> = ({ to, children }) => {
  const openNewWindow = () => {
    window.open(to, '_blank')
  }

  return (
    <BacklinkText
      onClick={openNewWindow}
      variant="body1"
      color="textSecondary"
      style={{ paddingBottom: '10px' }}>
      {children} <BacklinkIcon />
    </BacklinkText>
  )
}

export default Backlink
