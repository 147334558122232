import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import {
  findPricesAvailableByRefRequestID,
  saveReferralRequestPrices,
} from '../../actions/PriceActions'
import { Add as IconAdd, Delete as IconDelete } from '@material-ui/icons'
import _ from 'lodash'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { ReferralPriceBundle } from '../ReferralPrices/types'

const styles: any = () => {
  return {}
}

interface ReferralRequestPricesDialogProps {
  classes?: ClassNameMap<string>
  referralRequestID: number
  open?: boolean
  closeDialog?: () => void
  onSave: () => void
  existingSelections?: ReferralPriceBundle[]
  hintMainCPT?: string
}

const referralRequestPricesDialog = ({
  classes,
  referralRequestID,
  open,
  closeDialog,
  onSave,
  existingSelections,
  hintMainCPT,
}: ReferralRequestPricesDialogProps) => {
  const [priceList, setPriceList] = useState([])
  const [selected, setSelected] = useState<ReferralPriceBundle[]>([])
  const [filterText, setFilterText] = useState('')
  const [filteredPrices, setFilteredPrices] = useState<ReferralPriceBundle[]>(
    []
  )

  useEffect(() => {
    let _selected =
      existingSelections &&
      selected &&
      existingSelections.reduce(
        (coll: ReferralPriceBundle[], v: ReferralPriceBundle) => {
          if (v && coll) {
            coll[v.ID] = v
          }
          return coll
        },
        selected
      )
    if (_selected) {
      setSelected(_selected)
    }

    findPricesAvailableByRefRequestID({ requestID: referralRequestID })
      .then((res: any) => {
        setPriceList(res.Data)
        setFilteredPrices(res.Data)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }, [])

  const doSelectPrice = (p: ReferralPriceBundle) => {
    setSelected({ [p.ID]: p, ...selected })
  }

  const doRemovePrice = (p: ReferralPriceBundle) => {
    setSelected(_.omit(selected, p.ID))
  }

  // debounced functions should be bound on the instance; we use this whenever
  // the text "search" changes, so we don't pound the UI on every key change
  const _applyFilter = _.debounce((val = '') => {
    if (!!val.length === false) {
      setFilteredPrices(priceList)
      return
    }
    let filtered = priceList.filter((v: ReferralPriceBundle) => {
      if ((v.CostKey.Code || '').indexOf(val) !== -1) {
        return true
      }
      return false
    })
    setFilteredPrices(filtered)
  }, 250)

  const onFilterChange = (ev: any) => {
    const val = ev.target.value || ''
    setFilterText(val)
    _applyFilter(val)
  }

  const savePrices = () => {
    saveReferralRequestPrices({
      requestID: referralRequestID,
      priceIDs: Object.keys(selected).map((v) => {
        return +v /* cast numeric type */
      }),
    })
      .then((res: any) => {
        onSave()
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  const renderTable = (data: ReferralPriceBundle[] = [], opts: any = {}) => {
    if (!data.length) {
      return
    }

    const hasPrices = data[0].Price != null
    opts = {
      onRowClick: () => {
        /* no-op */
      },
      icon: null,
      ...opts,
    }
    return (
      <Table
        size="small"
        padding="default"
        className={classes && classes.table}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Cost Key</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Bundle Contents</TableCell>
            {hasPrices ? <TableCell>Price</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data)
            ? data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ width: '1%' }}>
                      <IconButton
                        className={classes && classes.button}
                        onClick={opts.onRowClick.bind(this, row)}>
                        {opts.icon}
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.CostKey.Code}</TableCell>
                    <TableCell>{row.CostKey.Descr}</TableCell>
                    <TableCell>{row.CostKey.BundleContents}</TableCell>
                    <TableCell>{hasPrices ? '$' + row.Price : null}</TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    )
  }

  return (
    <div>
      {!open ? null : (
        <div>
          <Dialog open={open} fullWidth maxWidth="lg">
            <DialogTitle>Manage Prices</DialogTitle>
            <DialogContent>
              <h4>Selected Cost Keys (and associated price):</h4>
              {Object.keys(selected).length ? (
                renderTable(Object.values(selected), {
                  onRowClick: doRemovePrice,
                  icon: <IconDelete />,
                })
              ) : (
                <p>No prices selected</p>
              )}
              <Divider />
              <h4>Available Cost Keys</h4>
              <TextField
                className={classes && classes.textField}
                value={filterText}
                onChange={onFilterChange}
                placeholder="Type to filter..."
              />
              {hintMainCPT ? (
                <small>Hint: referral Main CPT Code: {hintMainCPT}</small>
              ) : null}
              {!!filteredPrices.length ? (
                renderTable(filteredPrices, {
                  onRowClick: doSelectPrice,
                  icon: <IconAdd />,
                })
              ) : (
                <p>No prices available</p>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={savePrices} color="primary">
                Save
              </Button>
              <Button onClick={closeDialog} color="default">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(referralRequestPricesDialog)
