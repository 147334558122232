import React, { useEffect, useState } from 'react'
import {
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Done as IconDone } from '@material-ui/icons'
import { red } from '@material-ui/core/colors'
import _ from 'lodash'
import useApi from '../../hooks/useApi'

import { UserActions } from '../../actions'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const { saveNotificationSetting } = UserActions

const styles: any = (theme: any) => {
  return {
    root: {
      paddingRight: theme.spacing(1),
    },
    profileField: {
      padding: 10,
    },
    entityContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    profileContainer: {
      textAlign: 'center',
      padding: 25,
    },
    typeHeader: {
      textDecoration: 'underline',
    },
    notificationContainer: {
      paddingTop: '1rem',
      textAlign: 'left',
    },
    radioInputs: {
      padding: 0,
    },
    iconConfirmNotificationSaved: {
      position: 'relative',
      top: '3px',
    },
    errSaveNotification: {
      background: red[500],
      color: '#ffffff',
      padding: '0.5rem',
      marginBottom: '0.5rem',
      borderRadius: '5px',
      fontSize: '90%',
    },
  }
}

interface ProfileProps {
  classes: ClassNameMap<string>
}

const profile = ({ classes }: ProfileProps) => {
  const [GoZeroNotifications, setGoZeroNotifications] = useState<string | null>(
    null
  )
  const [showConfirmNotificationSaved, setShowConfirmNotificationSaved] =
    useState<boolean>(false)
  const [errSaveNotificationsMsg, setErrSaveNotificationsMsg] = useState<
    string | null
  >(null)
  const [_timeoutConfirmSaveNotification, setTimeoutConfirmSaveNotification] =
    useState<number>(0)
  const [_timeoutShowSaveNotifErr, setTimeoutShowSaveNotifErr] =
    useState<number>(0)

  const {
    data: userInfo,
    error: userInfoError,
    refetch,
  } = useApi({
    route: `/user`,
  })

  useEffect(() => {
    return () => {
      clearTimeout(_timeoutConfirmSaveNotification)
      clearTimeout(_timeoutShowSaveNotifErr)
    }
  }, [])

  useEffect(() => {
    if (userInfo) {
      setGoZeroNotifications(userInfo.GoZeroNotifications)
    }
  }, [userInfo])

  const onNotificationSettingChange = (_: any, v: string) => {
    setGoZeroNotifications(v)
  }

  const renderProfile = () => {
    if (!_.isEmpty(userInfo)) {
      const { FirstName, LastName, Email } = userInfo
      return (
        <div className={classes.profileContainer}>
          <Typography variant="h5" className={classes.profileField}>
            {FirstName} {LastName} - {Email}
          </Typography>
          <div className={classes.notificationContainer}>
            <h4>Notification Settings</h4>
            {errSaveNotificationsMsg ? (
              <span className={classes.errSaveNotification}>
                <strong>Failed saving notification settings:</strong>{' '}
                {errSaveNotificationsMsg}
              </span>
            ) : null}
            <RadioGroup
              aria-label="notifications"
              name="notifications"
              value={
                `${GoZeroNotifications}` /* must be a string for the radio component */
              }
              onChange={onNotificationSettingChange}>
              <FormControlLabel
                value="0"
                control={<Radio />}
                label="Off (never receive notifications)"
              />
              <FormControlLabel value="1" control={<Radio />} label="Instant" />
              <FormControlLabel value="2" control={<Radio />} label="Nightly" />
            </RadioGroup>
            <Button color="primary" onClick={doSaveNotificationSetting}>
              Save
            </Button>
            {showConfirmNotificationSaved ? (
              <IconDone
                fontSize="small"
                className={classes.iconConfirmNotificationSaved}
              />
            ) : null}
          </div>
        </div>
      )
    }
  }

  const doSaveNotificationSetting = () => {
    saveNotificationSetting(+GoZeroNotifications!) // convert to an integer before sending
      .then(() => {
        setShowConfirmNotificationSaved(true)
        setTimeoutConfirmSaveNotification(
          setTimeout(() => {
            setShowConfirmNotificationSaved(false)
          }, 3000)
        )

        refetch()
      })
      .catch((err: any) => {
        setErrSaveNotificationsMsg(err.Error.Message)
        setTimeoutShowSaveNotifErr(
          setTimeout(() => {
            setErrSaveNotificationsMsg(null)
          }, 3000)
        )
      })
  }

  return (
    <div>
      <Paper className={classes.root}>
        {userInfoError && (
          <Typography variant="caption">{userInfoError.message}</Typography>
        )}
        {!userInfoError && renderProfile()}
      </Paper>
    </div>
  )
}

export const Profile = withStyles(styles)(profile)
