import React from 'react'
import { Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { Divider, Grid, Paper } from '@material-ui/core'

import ReferralRequestActivity from './ReferralRequestActivity'
import MemberDetailsForm from '../ReferralForm/MemberDetailsForm'
import PhysicianDetailsForm from '../ReferralForm/PhysicianDetailsForm'
import { LoadingSidebar } from '../ReferralForm/LoadingSkeletons'
import ConversationsTabs from '../Conversations'
import ReferralRequestDetailsForm from './ReferralDetailsForm'
import PermissionDenied from '../PermissionDenied'
import ActionsFooter from './ActionsFooter'
import AttachedBundlesForm from './AttachedBundlesForm'
import { ReferralRequestFormData } from './types'
import usePrevious from '../../hooks/usePrevious'
import useForm from '../../hooks/useForm'
import useApi from '../../hooks/useApi'
import { useAuth } from '../../Auth'
import validators from './validators'
import Exclusions from '../ReferralForm/Exclusions'

interface Props {
  initialData: ReferralRequestFormData
  isEditing: boolean
  refetch: () => void
}

const FormDivider = styled(Divider)`
  margin: 20px 0px;
`

const Content = styled.div`
  padding: 20px 20px 0px;
`

export const useReferralRequest = (id?: number | null) => {
  const { data, refetch } = useApi(
    id
      ? {
          route: `/referral_request/${id}`,
        }
      : undefined
  )

  return { refetch, data: data ? { ...data, ...data.ReferralRequest } : null }
}

const ReferralRequestForm: React.FC<Props> = ({
  initialData,
  isEditing,
  refetch,
}) => {
  const defaultData = {
    ID: null,
    BundlerID: null,
    CancelledAt: null,
    CancelledBy: null,
    ContactEmail: null,
    ContactPhone: null,
    ContactPhoneExt: null,
    ConversationID: null,
    CreatedByUserID: null,
    Descr: null,
    EmployerMember: null,
    EmployerMemberID: null,
    EmployerName: '',
    EntityName: 'referralRequest' as const,
    MainCptCode: null,
    ModifiedByUserID: null,
    Notes: null,
    OrderingPhysEmail: null,
    OrderingPhysFax: null,
    OrderingPhysName: null,
    OrderingPhysPhone: null,
    OrderingPhysPhoneExt: null,
    OrganizationID: null,
    PCPAddressID: null,
    PracticeFacilityID: null,
    ReferralSource: null,
    ReferralID: null,
    ReferrerID: null,
    ReferrerDivisionID: null,
    RequestedServiceDate: null,
    NextReferralRequestStatuses: ['open'],
    CreatedAt: null,
    CreatedByUser: null,
    UpdatedAt: null,
    StatusID: null,
    View: true,
    Edit: true,
    ContentEditable: true,
  }

  const prevInitialData = usePrevious(initialData) // will be null if loading
  const [attemptedSave, setAttemptedSave] = React.useState(false)
  const [customErrors, setCustomErrors] = React.useState<string[]>([])
  const form = useForm<ReferralRequestFormData>(defaultData, validators)

  React.useEffect(() => {
    // set the initialData when it's finished loading
    if (!prevInitialData && !!initialData) {
      form.setData({ ...form.data, ...initialData })
    }
  }, [initialData])

  return (
    <Paper>
      <Content>
        <MemberDetailsForm form={form} showErrors={attemptedSave} />
        <FormDivider />
        <Exclusions employerID={form.data?.EmployerMember?.EmployerID} />
        <FormDivider />
        <ReferralRequestDetailsForm
          form={form}
          showErrors={attemptedSave}
          isEditing={isEditing}
          customErrors={customErrors}
        />
        <FormDivider />
        <AttachedBundlesForm
          form={form.data}
          practiceFacilityID={
            initialData ? initialData.PracticeFacilityID : null
          }
        />
        <PhysicianDetailsForm form={form} showErrors={attemptedSave} />
      </Content>
      <ActionsFooter
        form={form}
        refetch={refetch}
        isEditing={isEditing}
        setAttemptedSave={setAttemptedSave}
        customErrors={customErrors}
        setCustomErrors={setCustomErrors}
      />
    </Paper>
  )
}

export const CreateReferralRequest: React.FC<any> = (props) => {
  const member = props.location.state && props.location.state.record

  const { data: memberContact } = useApi(
    member && member.MemberID
      ? {
          route: `/member/${member.MemberID}/contact_info`,
        }
      : undefined
  )

  if (!member) return <Redirect to="/find_member" />
  if (!memberContact) return null

  return (
    <ReferralRequestForm
      initialData={{
        ...member,
        ContactPhone: memberContact.Phone,
        ContactPhoneExt: memberContact.PhoneExt,
        ContactEmail: memberContact.Email,
      }}
      isEditing={false}
      refetch={() => {}}
    />
  )
}

export const EditReferralRequest: React.FC<any> = (props) => {
  const { access } = useAuth()
  const referralRequestId = props.match.params.referralRequestId
  const { data: referralRequest, refetch } =
    useReferralRequest(+referralRequestId)

  if (!access.IsZeroUser && !access.Roles.ReferralRequester) {
    return <PermissionDenied />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <ReferralRequestForm
          initialData={referralRequest}
          isEditing
          refetch={refetch}
        />
      </Grid>
      <Grid item xs={4}>
        {referralRequest ? (
          <div>
            <Paper>
              <ConversationsTabs referralRequestId={referralRequestId} />
            </Paper>
            <Paper style={{ marginTop: '20px' }}>
              <ReferralRequestActivity referralRequestId={referralRequestId} />
            </Paper>
          </div>
        ) : (
          <LoadingSidebar />
        )}
      </Grid>
    </Grid>
  )
}
