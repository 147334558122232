import * as api from '../services/ApiService'

export const findPricesAvailableByRefID = (params: any) => {
  return api.get(`/referral/${params.referralId}/available_prices`)
}

export const getReferralPrices = (params: any) => {
  return api.get(`/referral/${params.referralId}/prices`)
}

export const saveReferralPrices = (params: any) => {
  const { referralId, priceIDs } = params
  return api.put(`/referral/${referralId}/prices`, { priceIDs })
}

export const findPricesAvailableByRefRequestID = (params: any) => {
  return api.get(`/referral_request/${params.requestID}/available_prices`)
}

export const getReferralRequestPrices = (params: any) => {
  return api.get(`/referral_request/${params.requestID}/prices`)
}

export const saveReferralRequestPrices = (params: any) => {
  const { requestID, priceIDs } = params
  return api.put(`/referral_request/${requestID}/prices`, { priceIDs })
}
