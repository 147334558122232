import React, { useEffect } from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { useAuth } from '../../Auth'

export default () => {
  const { logout } = useAuth()
  useEffect(() => {
    logout()
  }, [])

  return (
    <Card>
      <CardHeader>No user associated with this account</CardHeader>
      <CardContent>
        Please contact the Zero administrator at support@zero.health
      </CardContent>
    </Card>
  )
}
