import * as api from '../services/ApiService'

export const getUser = () => {
  return api
    .get('/user')
    .then((res: any) => {
      if (res.Data) {
        return res.Data
      }
    })
    .catch((err: any) => {
      return err
    })
}

export const saveNotificationSetting = (Setting: any) => {
  return api.put('/my/go_zero_notifications', {
    Setting,
  })
}
