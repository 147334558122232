export const ZEROCARD_BUNDLER_ID = 1

export const getUserInfo = () => {
  const rawInfo = window.localStorage.getItem('userInfo')
  let userInfo = {}

  if (rawInfo) {
    try {
      userInfo = JSON.parse(rawInfo)
    } catch {
      console.log('No user data available')
      window.location.assign('/no_user')
    }
  }

  return userInfo
}
