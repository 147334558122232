import React from 'react'
import debounce from 'lodash/debounce'
import { useParams, useHistory, Link } from 'react-router-dom'
import { Paper, SortDirection } from '@material-ui/core'
import { Breadcrumbs, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { HeaderTitle } from './components'
import DataTable from '../DataTable'
import { UserPermissionsModel } from '../../models/userManagement'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import useApi from '../../hooks/useApi'
import SearchField from '../SearchField'

const LoadingFacilityDetail = () => {
  return (
    <div>
      <Skeleton width={200} height={60} style={{ marginTop: '20px' }} />
      <div style={{ height: '10px' }} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
    </div>
  )
}

const BreadcrumbTrail: React.FC<any> = ({ organizationId, name }) => {
  const { data } = useApi({ route: `/user-management/user-organizations` })
  let rootName

  if (data) {
    const org = data.find((org: any) => +org.ID === +organizationId)
    rootName = org ? org.Name : 'Organization'
  }

  const orgLink = rootName
    ? `/user_management/organization/${organizationId}`
    : '/user_management'

  return (
    <Breadcrumbs style={{ marginTop: '21px' }}>
      <Link to={orgLink}>{rootName}</Link>
      <Typography>{name}</Typography>
    </Breadcrumbs>
  )
}

const FacilityUserPermissions: React.FC<any> = ({
  facilityName,
  permissions,
  refetch,
}) => {
  const params: any = useParams()
  const hist = useHistory()
  const { show: showSnackbar } = useSnackbar()
  const [range, setRange] = React.useState<any>()
  const [sort, setSort] = React.useState<any>()

  const onChangePage = (change: any) => {
    const { range } = change
    setRange(range)
    refetch({ range })
  }

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    setSort([col, dir])
    refetch({ sort: [col, dir] })
  }

  const onRowClick = (_class: any, row: any) => {
    if (row.AllFacilities) {
      const { UserID } = row
      hist.push({
        pathname: `/user_management/organization/${params.organizationId}/users/${UserID}/facility_permissions`,
        // for breadcrumbs
        state: {
          prevPathname: hist.location.pathname,
          prevPageName: facilityName,
        },
      })
    }
  }

  const refetchWithParams = () => {
    refetch({ range, sort })
  }

  const notifyError = (msg: string) => {
    showSnackbar(msg, SnackbarTypeError)
  }

  return (
    <DataTable
      keyProp="UserID"
      data={permissions || []}
      columns={UserPermissionsModel(refetchWithParams, notifyError)}
      count={permissions.length}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePage}
      sortHandler={sortColumns}
      onRowClick={onRowClick}
      sortable={undefined}
      allowEditing={false}
      initPage={1}
      initPageSize={10}
    />
  )
}

const PracticeFacilityDetail = () => {
  const params: any = useParams()
  const { organizationId, facilityId } = params

  const [searchText, setSearchText] = React.useState('')

  const { data: facility } = useApi({
    route: `/user-management/organization/${organizationId}/practice_facility/${facilityId}`,
  })

  const { data: permissions, refetch: refetchPermissions } = useApi(
    organizationId && facilityId
      ? {
          route: `/user-management/organization/${organizationId}/facility/${facilityId}/user-permissions`,
        }
      : undefined
  )

  const debouncedRefetch = React.useCallback(
    debounce(refetchPermissions, 500),
    []
  )

  const onSearch = (val: string) => {
    setSearchText(val)

    if (val && val.length > 3) {
      debouncedRefetch({ filter: { q: val } })
    } else {
      debouncedRefetch()
    }
  }

  if (!facility || !permissions) return <LoadingFacilityDetail />

  return (
    <div>
      <BreadcrumbTrail organizationId={organizationId} name={facility.Name} />
      <HeaderTitle>{facility.Name}</HeaderTitle>
      <Paper elevation={3} style={{ padding: '10px', paddingTop: '5px' }}>
        <div
          style={{
            display: 'flex',
            margin: '10px',
          }}>
          <SearchField
            label="Search users"
            value={searchText}
            onChange={onSearch}
          />
          <div style={{ width: '70%' }} />
        </div>
        <FacilityUserPermissions
          facilityName={facility.Name}
          permissions={permissions}
          refetch={refetchPermissions}
        />
      </Paper>
    </div>
  )
}

export default PracticeFacilityDetail
