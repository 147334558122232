import React from 'react'
import { customDisplayProps } from './types'
import { Grid, LinearProgress } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FileIcon from './FileIcon'
import { bytesToFriendly } from '../../utils'
import styled from 'styled-components'

const DefaultSingleFileInput = ({
  file,
}: customDisplayProps): React.ReactElement | null => (
  <DefaultSingleFileInputWrapper>
    {file.fileRec?.Data?.FileID && (
      <Grid className="file-confirmed" container spacing={2}>
        <Grid item>
          <FileIcon ext={file.domFileObj.name.split('.').pop()} />
        </Grid>
        <Grid item>
          <div>
            <strong>File Uploaded OK!</strong>
          </div>
          <div>FileName: {file.fileRec.Data.S3Key?.split('/').pop()}</div>
          <div>Size: {bytesToFriendly(file.fileRec.Data.Size)}</div>
        </Grid>
      </Grid>
    )}

    {file.err && !file.fileRec && (
      <Alert className="alert-error" severity="error">{`${file.err}`}</Alert>
    )}
    <div className="selected-file">
      <span className="selected-file-name">
        Selected File: <strong>{file.domFileObj.name}</strong>
      </span>
      <LinearProgress variant="determinate" value={file.progress} />
    </div>
  </DefaultSingleFileInputWrapper>
)

export default DefaultSingleFileInput

/*
Styled components below
*/
const DefaultSingleFileInputWrapper = styled.div`
  min-width: 480px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #bbb;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .alert-error {
    margin-bottom: 0.75rem;
  }

  .selected-file {
    margin-bottom: 1rem;

    .selected-file-name {
      display: block;
      padding-bottom: 0.5rem;
    }
  }

  .file-confirmed {
    margin: 0 auto;
    max-width: fit-content;
  }
`
