import React from 'react'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useHistory } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import SearchField from '../SearchField'
import AutocompleteField from '../AutocompleteField'
import AddUserButton from './AddUserButton'
import DataTable from '../DataTable'
import { PracticeFacilityManagementModel } from '../../models/userManagement'
import useApi from '../../hooks/useApi'
import useQueryParams from '../../hooks/useQueryParams'
import states from '../../utils/stateCodes'
import { FacilityListProps } from './types'
import { SortDirection } from '@material-ui/core'

const ActionsGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto 160px;
  margin: 10px 0px;
`

const LoadingFacilities = () => {
  return (
    <div>
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
    </div>
  )
}

const FacilitiesList: React.FC<FacilityListProps> = ({
  facilities,
  organizationId,
  refetch,
  count,
}) => {
  const hist = useHistory()
  const { params, setParams } = useQueryParams()

  const onRowClick = (_class: any, row: any) => {
    const { ID } = row
    hist.push(
      `/user_management/organization/${organizationId}/facilities/${ID}/user_permissions`
    )
  }

  const onChangePage = (change: any) => {
    const { range } = change
    const newParams = { ...params, range }
    refetch(newParams)
    setParams(newParams)
  }

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    const newParams = { ...params, sort: [col, dir] }
    refetch(newParams)
    setParams(newParams)
  }

  return (
    <DataTable
      keyProp="ID"
      data={facilities}
      columns={PracticeFacilityManagementModel()}
      count={count}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePage}
      onRowClick={onRowClick}
      sortHandler={sortColumns}
      sortable={undefined}
      allowEditing={false}
      initPage={1}
      initPageSize={10}
    />
  )
}

const PracticeFacilities: React.FC<{ organizationId: number }> = ({
  organizationId,
}) => {
  const [searchText, setSearchText] = React.useState('')
  const [selectedState, setSelectedState] = React.useState('')

  const facilitiesRequest = {
    route: `/user-management/organization/${organizationId}/facilities`,
  }

  const { data, meta, refetch } = useApi(
    organizationId ? facilitiesRequest : undefined
  )

  const debouncedRefetch = React.useCallback(debounce(refetch, 500), [])

  React.useEffect(() => {
    refetch(facilitiesRequest)
  }, [organizationId])

  const onSearch = (val: string) => {
    setSearchText(val)
    if (val && val.length > 3) {
      debouncedRefetch({ filter: { q: val, state: selectedState } })
    } else {
      debouncedRefetch({ filter: { state: selectedState } })
    }
  }

  const onStateSelect = (val: string) => {
    setSelectedState(val)
    if (val) {
      refetch({ filter: { q: searchText, state: val } })
    } else {
      refetch({ filter: { q: searchText } })
    }
  }

  return (
    <div>
      <ActionsGrid>
        <SearchField
          label="Search facilities"
          value={searchText}
          onChange={onSearch}
        />
        <AutocompleteField
          label="Select state"
          value={selectedState}
          onChange={onStateSelect}
          options={states.map((st) => ({ name: st, value: st }))}
        />
        <AddUserButton />
      </ActionsGrid>
      {data ? (
        <FacilitiesList
          facilities={data}
          organizationId={organizationId}
          count={(meta && meta.Total) || 0}
          refetch={refetch}
        />
      ) : (
        <LoadingFacilities />
      )}
    </div>
  )
}

export default PracticeFacilities
