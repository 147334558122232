import React from 'react'
import styled from 'styled-components'
import { TextField, InputAdornment } from '@material-ui/core'
import { Search } from '@material-ui/icons'

interface Props {
  value?: string | null
  onChange: (val: string) => void
  label: string
  error?: string | null
  style?: any
  // tooltip?
}

const StyledTextField: typeof TextField = styled(TextField)`
  flex: 1;
`

const SearchField: React.FC<Props> = ({
  value,
  onChange,
  label,
  error,
  style,
}) => {
  return (
    <StyledTextField
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
      label={label}
      placeholder="Search..."
      error={!!error}
      helperText={error}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
      style={style}
    />
  )
}

export default SearchField
