import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { Add as IconAdd, Delete as IconDelete } from '@material-ui/icons'
import _ from 'lodash'
import { ReferralPriceBundle } from '../ReferralPrices/types'

interface FeeSchedulePricesSelectProps {
  updateSelectedCostKey: (bundle: ReferralPriceBundle | null) => void
  prices: ReferralPriceBundle[]
}

const FeeSchedulePricesSelect = ({
  prices,
  updateSelectedCostKey,
}: FeeSchedulePricesSelectProps) => {
  const [priceList, setPriceList] = useState<ReferralPriceBundle[]>([])
  const [selected, setSelected] = useState<ReferralPriceBundle | null>(null)

  useEffect(() => {
    setPriceList(prices)
  }, [])

  useEffect(() => {
    if (_.differenceWith(priceList, prices, _.isEqual).length !== 0) {
      setPriceList(prices)
    }
  }, [prices])

  const doSelectPrice = (p: ReferralPriceBundle) => {
    const priceListClone = [...priceList]
    _.remove(priceListClone, {
      ...p,
    })

    setSelected(p)
    setPriceList(priceListClone)
    updateSelectedCostKey(p)
  }

  const doRemovePrice = (p: ReferralPriceBundle) => {
    let updatePriceList = [...priceList, p]

    setSelected(null)
    setPriceList(updatePriceList)
    updateSelectedCostKey(null)
  }

  const renderTable = (data: ReferralPriceBundle[] = [], opts: any = {}) => {
    if (!data.length) {
      return
    }
    opts = {
      onRowClick: () => {
        /* no-op */
      },
      icon: null,
      ...opts,
    }
    return (
      <Table padding="default">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Bundle</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Bundle Contents</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(data)
            ? data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={{ width: '1%' }}>
                      <IconButton onClick={opts.onRowClick.bind(this, row)}>
                        {opts.icon}
                      </IconButton>
                    </TableCell>
                    <TableCell>{row.CostKey.Code}</TableCell>
                    <TableCell>{row.CostKey.Descr}</TableCell>
                    <TableCell>{row.CostKey.BundleContents}</TableCell>
                    <TableCell>${row.Price}</TableCell>
                  </TableRow>
                )
              })
            : null}
        </TableBody>
      </Table>
    )
  }

  return (
    <div>
      {
        <div>
          <h4>Selected Bundle (and associated price):</h4>
          {selected ? (
            renderTable([selected], {
              onRowClick: doRemovePrice,
              icon: <IconDelete />,
            })
          ) : (
            <p>No prices selected</p>
          )}
          <Divider />
          <h4>Available Bundles</h4>
          {!!priceList.length ? (
            renderTable(priceList, {
              onRowClick: doSelectPrice,
              icon: <IconAdd />,
            })
          ) : (
            <p>No prices available</p>
          )}
        </div>
      }
    </div>
  )
}

export { FeeSchedulePricesSelect }
