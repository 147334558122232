import React from 'react'
import { FormControl, InputLabel, Input } from '@material-ui/core'

interface Props {
  label: string
  value: React.ReactNode | string
}

const ReferenceTextField: React.FC<Props> = ({ label, value }) => {
  return (
    <>
      <label
        style={{
          fontSize: '12px',
          fontWeight: 700,
          color: '#0000008a',
          position: 'relative',
          top: '-4px', //interesting hack to match the transformed mui labels
          marginBottom: '7px',
        }}>
        {label}
      </label>
      <div style={{ color: 'black', fontWeight: 500, fontSize: '1rem' }}>
        {value}
      </div>
    </>
  )
}

export default ReferenceTextField
