import React, { useState, useEffect } from 'react'
import useApi from '../hooks/useApi'
import SelectField from './SelectField'

interface DivisionSelectProps {
  value: number | null
  onChange: (id: number) => void
  referrerID: number | null
  disabled?: boolean
}

interface Division {
  value: number
  name: string
}

const ReferrerDivisionSelect: React.FC<any> = ({
  value,
  onChange,
  referrerID,
  disabled,
}: DivisionSelectProps) => {
  const [referrerDivisions, setReferrerDivisions] = useState<Array<Division>>(
    []
  )

  const { data: divisions, refetch } = useApi({
    route: `/referrer/${referrerID}/divisions`,
  })

  useEffect(() => {
    if (!divisions || !divisions.length) return setReferrerDivisions([])
    setReferrerDivisions(
      divisions.map((division: any) => ({
        name: division.Name,
        value: division.ID,
      }))
    )
  }, [divisions])

  useEffect(() => {
    if (!referrerID) return
    refetch({ route: `/referrer/${referrerID}/divisions` })
  }, [referrerID])

  if (!referrerDivisions || !referrerDivisions.length) return <></>
  return (
    <SelectField
      label="Division"
      options={referrerDivisions}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default ReferrerDivisionSelect
