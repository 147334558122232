import React from 'react'
import { Tooltip as MUITooltip } from '@material-ui/core'
import styled from 'styled-components'

const StyledTooltipContent = styled.div`
  font-size: 0.8rem;
  font-weight: 400;
  padding: 0.25rem;
  line-height: 1.5;
`

export const Tooltip = ({ children, title, ...spread }: any) => (
  <MUITooltip
    {...spread}
    arrow
    title={<StyledTooltipContent>{title}</StyledTooltipContent>}>
    {children}
  </MUITooltip>
)

export default Tooltip
