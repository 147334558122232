import * as api from '../services/ApiService'

export const getReportableOrganizations = () => {
  return api.get('/reportable/organization')
}

export const getReportsByOrgID = (orgID: number) => {
  return api.get(`/reportable/organization/${orgID}`)
}

export const getOrganizationReport = (orgID: number, reportHandle: string) => {
  return api.get(`/reportable/organization/${orgID}/${reportHandle}`)
}

export const getReportableReferrers = () => {
  return api.get('/reportable/referrer')
}

export const getReportsByReferrerID = (rfrID: number) => {
  return api.get(`/reportable/referrer/${rfrID}`)
}

export const getReferrerReport = (rfrID: number, reportHandle: string) => {
  return api.get(`/reportable/referrer/${rfrID}/${reportHandle}`)
}
