import React from 'react'
import {
  AccountBalance as IconAccountBalance,
  Assessment as IconAssessment,
  Assignment as IconAssignment,
  AssignmentInd as IconAssignmentInd,
  Dashboard as IconDashboard,
  Description as IconDescription,
} from '@material-ui/icons'

import Menu from '../Menu'
import * as T from './types'
import { useAuth } from '../../Auth'
import { useConfig } from '../../Config'

export const MenuContainer = () => {
  const { access, zeroUser } = useAuth() as any
  const { env } = useConfig() as any

  const UserCanManageUsers = access.CanManageUsers
  const userFullName = zeroUser
    ? `${zeroUser.FirstName} ${zeroUser.LastName}`
    : ''

  const showDashboard =
    access.CanAccessInvoices ||
    access.CanAccessReferrals ||
    access.CanManageUsers

  const viewReports =
    access.Roles.OrganizationReportsViewer ||
    access.Roles.ReferralInvoiceReviewer ||
    access.Roles.ReferrerReportsViewer

  const menuItems = [
    showDashboard
      ? {
          icon: <IconDashboard />,
          label: 'Dashboard',
          url: `/` /*consider changing this */,
        }
      : null,
    access.CanAccessReferrals
      ? {
          label: 'Referrals',
          icon: <IconAssignment />,
          url: `/referrals`,
        }
      : null,
    access.CanAccessInvoices
      ? {
          label: 'Billing Dashboard',
          icon: <IconAccountBalance />,
          url: `/billing_dashboard`,
        }
      : null,
    access.CanAccessInvoices
      ? {
          label: 'Invoices',
          icon: <IconDescription />,
          url: `/referral_invoices`,
        }
      : null,
    viewReports
      ? {
          label: 'Reports',
          icon: <IconAssessment />,
          url: '/reports',
        }
      : null,
    UserCanManageUsers
      ? {
          label: 'User Management',
          icon: <IconAssignmentInd />,
          url: `/user_management`,
        }
      : null,
  ].filter(Boolean) as unknown as T.MenuItem[]

  return (
    <Menu
      isProduction={env === 'prd'}
      isManager={UserCanManageUsers}
      fullName={userFullName}
      user={zeroUser}
      menuItems={menuItems}
    />
  )
}
