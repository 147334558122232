import { useCallback } from 'react'

export const SnackbarTypeError = 'error'
export const SnackbarTypeSuccess = 'success'
export const SnackbarTypeWarning = 'warning'

export default function useSnackbar() {
  const show = useCallback((msg: string, type: string) => {
    const ev = new CustomEvent('zeroShowSnackbar', {
      detail: {
        msg,
        type,
      },
    })
    console.debug('dispatching event...', ev)
    dispatchEvent(ev)
  }, [])

  const close = useCallback(() => {
    const ev = new CustomEvent('zeroCloseSnackbar')
    console.debug('dispatching event...', ev)
    dispatchEvent(ev)
  }, [])

  const showForDuration = useCallback(
    (msg: string, type: string, duration: number /* ms */) => {
      show(msg, type)
      setTimeout(close, duration)
    },
    [show, close]
  )

  return {
    show,
    close,
    showForDuration,
  }
}
