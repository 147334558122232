import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Auth'
import { Button } from '@material-ui/core'
import OrganizationReports from './organizationReports'
import ReferrerReports from './referrerReports'
import styled from 'styled-components'

const ReportingWrapper = styled.div`
  padding: 2rem;
`

enum view {
  Organizations,
  Referrers,
}

export default function Reports() {
  const { access } = useAuth()
  const [whichView, setWhichView] = useState<any>(null)
  const canViewOrgReports =
    access.Roles?.OrganizationReportsViewer === true ||
    access.IsZeroUser === true
  const canViewReferrerReports =
    access.Roles?.ReferrerReportsViewer === true || access.IsZeroUser === true

  useEffect(() => {
    const canViewBoth = canViewOrgReports && canViewReferrerReports
    const canViewNone =
      canViewOrgReports === false && canViewReferrerReports === false
    if (canViewBoth || canViewNone) {
      setWhichView(null)
      return
    }
    if (canViewOrgReports) {
      setWhichView(view.Organizations)
      return
    }
    if (canViewReferrerReports) {
      setWhichView(view.Referrers)
      return
    }
  }, [])

  // for users that may have access to both org and referrer reports, they have to
  // choose which they want to see (in practice, this is applicable to bundlers only today)
  if (whichView === null) {
    return (
      <div style={{ textAlign: 'center', padding: '1rem' }}>
        <Button
          variant="outlined"
          onClick={() => {
            setWhichView(view.Organizations)
          }}>
          Organization Reports
        </Button>
        &nbsp;&nbsp;
        <Button
          variant="outlined"
          onClick={() => {
            setWhichView(view.Referrers)
          }}>
          Referrer Reports
        </Button>
      </div>
    )
  }

  return (
    <ReportingWrapper>
      {whichView === view.Organizations && <OrganizationReports />}
      {whichView === view.Referrers && <ReferrerReports />}
    </ReportingWrapper>
  )
}
