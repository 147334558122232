import * as api from '../services/ApiService'

export const findICDs = (params: any) => {
  return api.get('/icd10_code', params)
}

interface SaveReferralInvoiceICDCodeParams {
  referralInvoiceID: number
  ICDCodes: string[]
}

export const saveReferralInvoiceICDCodes = (
  params: SaveReferralInvoiceICDCodeParams
) => {
  const requestBody = {
    ICDCodes: params.ICDCodes,
  }
  return api.put(
    `/referral_invoice/${params.referralInvoiceID}/icd_codes`,
    requestBody
  )
}
