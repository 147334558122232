import React, { useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField, Typography } from '@material-ui/core'
import { ReferralInvoiceActions } from '../../actions'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'

const StyledOption = styled.div`
  font-size: 0.875rem;

  //.entity-name,
  .tin-npi,
  .disp-address,
  .no-address {
    display: block;
  }

  .disp-address,
  .no-address {
    font-size: 90%;
  }

  .no-address {
    color: red;
  }
`

interface item {
  ID: number
  Name: string
  TIN: string
  IsCurrentlyActive: boolean
  [key: string]: any
}

export default function BillingEntityPicker({
  organizationID,
  selectedBillingEntityID,
  disabled,
  onChange,
}: {
  organizationID: number
  selectedBillingEntityID: number | null
  disabled?: boolean
  onChange(val: item | null): void
}): React.ReactElement {
  const [billingEntityList, setBillingEntityList] = useState<item[]>([])
  const [active, setActive] = useState<item | null>(null)
  const [initialListLoaded, setInitialListLoaded] = useState(false)

  useEffect(() => {
    if (disabled) {
      setInitialListLoaded(true)
      return
    }
    ReferralInvoiceActions.getBillingEntities({
      organizationIDs: [organizationID],
    }).then((res: any) => {
      setBillingEntityList(res?.Data || [])
      const useActive = res?.Data?.find(
        (i: item) => i.ID === selectedBillingEntityID
      )
      if (useActive) {
        setActive(useActive)
      }
      setInitialListLoaded(true)
    })
  }, [organizationID, disabled])

  useEffect(() => {
    if (!initialListLoaded) return
    if (!selectedBillingEntityID) return
    if (active && active.ID === selectedBillingEntityID) return
    ReferralInvoiceActions.getBillingEntities({
      organizationIDs: [organizationID],
      IDs: [selectedBillingEntityID],
    }).then((res: any) => {
      if (res?.err) return
      if (!res.Data) return
      setActive(res.Data?.[0] || null)
    })
  }, [selectedBillingEntityID, active, initialListLoaded])

  function onSelectionChange(_evt: any, val: item | null) {
    setActive(val)
    onChange(val)
  }

  return (
    <>
      <Autocomplete
        disabled={disabled}
        options={billingEntityList}
        // @ts-ignore
        getOptionLabel={(option: any) => option.Name || ''}
        getOptionSelected={(opt: item) => {
          return active ? opt.ID === active.ID : false
        }}
        value={active}
        onChange={onSelectionChange}
        disableClearable={false}
        // onInputChange={onInputChange}
        // renderInput={(params) => <TextField {...params} label="Billing Entity" />}
        renderOption={(option: item) => (
          <StyledOption>
            <span className="entity-name">{option.Name}</span>
            <span className="tin-npi">
              <strong>TIN:</strong>&nbsp;{option.TIN}; &nbsp;
              <strong>NPI:</strong>
              &nbsp;{option.NPI || '(unknown)'}
            </span>
            {option?.DisplayAddress ? (
              <span className="disp-address">
                <strong>Remittance Address:</strong>
                &nbsp;<span>{option.DisplayAddress}</span>
              </span>
            ) : (
              <span className="no-address">
                ZERO does not have a remittance address on file for this TIN.
                Invoices using this TIN/NPI combination will likely be subject
                to delays.
              </span>
            )}
          </StyledOption>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label="Billing Entity"
            placeholder="Search by TIN or Address"
            style={{ minWidth: 300 }}
            // {...TextFieldProps}
          />
        )}
      />
      {active && (
        <div style={{ paddingTop: '0.5rem', maxWidth: 410 }}>
          <StyledOption>
            {/* <span className="entity-name">{active.Name}</span> */}
            <span className="tin-npi">
              <strong>TIN:</strong>&nbsp;{active.TIN}; &nbsp;
              <strong>NPI:</strong>
              &nbsp;{active.NPI || '(unknown)'}
            </span>
            {active?.DisplayAddress ? (
              <span className="disp-address">
                <strong>Remittance Address:</strong>
                &nbsp;<span>{active.DisplayAddress}</span>
              </span>
            ) : (
              <span className="no-address">
                ZERO does not have a remittance address on file for this TIN.
                Invoices using this TIN/NPI combination will likely be subject
                to delays.
              </span>
            )}
          </StyledOption>

          {active?.IsCurrentlyActive === false && (
            <Typography
              variant="caption"
              style={{
                background: '#f0ca27',
                display: 'block',
                padding: '0.35rem',
                borderRadius: '5px',
                marginTop: '0.5rem',
              }}>
              ZERO's records indicate this TIN (or the remittance address) may
              be outdated as of &nbsp;
              {dateTime
                .parse(active.DateEnd)
                .format(dateTime.formats.AmericanDate)}
              .{' '}
              <strong>
                Please double-check the TIN, remittance address, and
                Organization NPI (above) are correct for the Date Of Service
                before submitting.
              </strong>
            </Typography>
          )}
        </div>
      )}
    </>
  )
}
