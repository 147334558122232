import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import { defaultDateFormatter, lastUpdatedDateFormatter } from '../utils'

const EditLink: React.FC<{ row: any }> = ({ row }) => {
  const loc: any = useLocation()
  return (
    <Link
      to={{
        pathname: `/referral_invoice/${row.ID}`,
        state: { prevQueryString: loc.search || '' },
      }}
      style={{ textDecoration: 'none' }}>
      <Button size="small" color="primary">
        <EditIcon width={20} />
        <span style={{ marginLeft: '7px' }}>Edit</span>
      </Button>
    </Link>
  )
}

export const ReferralInvoiceModel = (statuses: any) => ({
  ReferralDescr: {
    name: 'Description',
    details: { options: { width: '100px' } },
  },
  ReferralDateOfService: {
    name: 'Referral Date of Service',
    details: {
      sortName: 'ReferralDateOfService',
    },
  },
  DateOfService: {
    name: 'Invoice Date of Service',
    details: {
      sortName: 'DateOfService',
      dataFormat: (col: any, row: any) => {
        return col ? defaultDateFormatter(col, row) : 'Not Scheduled'
      },
    },
  },
  MemberName: {
    name: 'Name',
    details: {
      sortName: 'MemberName',
    },
  },
  EmployerName: {
    name: 'Employer',
    details: {
      sortName: 'EmployerName',
    },
  },
  OrganizationName: {
    name: 'Organization',
    details: {
      sortName: 'OrganizationName',
    },
  },
  PracticeFacilityName: {
    name: 'Facility',
    details: {
      sortName: 'PracticeFacilityName',
    },
  },
  UpdatedAt: {
    name: 'Last Updated',
    details: { dataFormat: lastUpdatedDateFormatter },
  },
  ReferralInvoiceStatusID: {
    name: 'Status',
    details: {
      dataFormat: (_col: any, row: any) => {
        const status = statuses.find(
          (s: any) => s.ID === row.ReferralInvoiceStatusID
        )
        return status ? status.Description : 'unknown'
      },
    },
  },
  Notes: {
    name: 'Notes',
  },
  PatientAccountNumber: {
    name: 'Patient Account Number',
  },
  ID: {
    name: 'Edit',
    details: {
      dataFormat: (_col: any, row: any) => {
        return <EditLink row={row} />
      },
    },
  },
})
