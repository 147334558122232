import React from 'react'
import debounce from 'lodash/debounce'
import { Link, useParams, useLocation } from 'react-router-dom'
import {
  FormControlLabel,
  Switch,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Breadcrumbs,
  Typography,
  SortDirection,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { HeaderTitle } from './components'
import SearchField from '../SearchField'
import DataTable from '../DataTable'
import {
  AllPracticeFacilitiesPermissionsModel,
  PracticeFacilityPermissionsModel,
} from '../../models/userManagement'
import useApi, { ApiRes } from '../../hooks/useApi'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { nameFormatter } from '../../utils'
import { deletePermissions } from '../../services/UserManagementService'
import { UserPermissions } from './types'

interface ToggleProps {
  value: boolean
  onChange: (val: boolean) => void
}

const LoadingUserDetail = () => {
  return (
    <div>
      <Skeleton width={200} height={60} style={{ marginTop: '20px' }} />
      <Skeleton width={150} height={20} style={{ marginBottom: '20px' }} />
      <div style={{ height: '10px' }} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
      <Skeleton width={1000} height={100} />
    </div>
  )
}

const BreadcrumbTrail: React.FC<any> = ({ organizationId, name }) => {
  const loc: any = useLocation()
  const { data } = useApi({ route: `/user-management/user-organizations` })
  const hasPrevPath = loc && loc.state && loc.state.prevPathname
  let rootName

  if (data) {
    const org = data.find((org: any) => +org.ID === +organizationId)
    rootName = org ? org.Name : 'Organization'
  }

  const orgLink = rootName
    ? `/user_management/organization/${organizationId}`
    : '/user_management'

  return (
    <Breadcrumbs style={{ marginTop: '21px' }}>
      <Link to={orgLink}>{rootName}</Link>
      {hasPrevPath && (
        <Link to={loc.state.prevPathname}>{loc.state.prevPageName}</Link>
      )}
      <Typography>{name}</Typography>
    </Breadcrumbs>
  )
}

const AlertDialog: React.FC<any> = ({ open, onCancel, onContinue }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you want to manage permissions across all facilities for this user
          we will clear out their current permissions at specific facilities.
          <br />
          <br />
          All current permission settings for this user will be removed.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onContinue} autoFocus color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ManageFacilityPermsToggle: React.FC<ToggleProps> = ({
  value,
  onChange,
}) => {
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [onAlertContinue, setOnAlertContinue] = React.useState(() => () => {})

  const onAlertCancel = () => {
    setAlertOpen(false)
  }

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={value}
            onChange={(evt) => {
              const checked = evt.target.checked
              if (checked) {
                onChange(true)
              } else {
                setAlertOpen(true)

                setOnAlertContinue(() => () => {
                  setAlertOpen(false)
                  onChange(false)
                })
              }
            }}
          />
        }
        label="Manage permissions per practice facility"
        labelPlacement="start"
      />
      <AlertDialog
        open={alertOpen}
        onCancel={onAlertCancel}
        onContinue={onAlertContinue}
      />
    </>
  )
}

const AllFacilitiesPermissions: React.FC<{
  permission: any
  refetch: Function
}> = ({ permission, refetch }) => {
  const { show: showSnackbar } = useSnackbar()
  const params: any = useParams()

  const data = permission
    ? [permission]
    : [
        {
          UserID: +params.userId,
          OrganizationID: +params.organizationId,
          GrantedOrganizationID: +params.organizationId,
          GrantedPracticeFacilityID: null,
          PracticeFacilityID: null,
          Name: 'All Practice Facilities',
          ReferralViewer: false,
          ReferralAdministrator: false,
          ReferralCoordinator: false,
          ViewQuarterlyReport: false,
          InvoiceSubmitter: false,
        },
      ]

  const notifyError = (msg: string) => {
    showSnackbar(msg, SnackbarTypeError)
  }

  return (
    <DataTable
      keyProp="Name"
      data={data}
      columns={AllPracticeFacilitiesPermissionsModel(refetch, notifyError)}
      pagination={false}
      sortable={undefined}
      allowEditing={false}
      onChangePage={() => {}}
      onChangeRowsPerPage={() => {}}
      initPage={1}
      initPageSize={10}
    />
  )
}

const PracticeFacilitiesPermissions: React.FC<any> = ({
  permissions,
  refetch,
  count,
}) => {
  const { show: showSnackbar } = useSnackbar()
  const [range, setRange] = React.useState<any>()
  const [sort, setSort] = React.useState<any>()

  const onChangePage = (change: any) => {
    const { range } = change
    setRange(range)
    refetch({ range })
  }

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    setSort([col, dir])
    refetch({ sort: [col, dir] })
  }

  const notifyError = (msg: string) => {
    showSnackbar(msg, SnackbarTypeError)
  }

  const refetchWithParams = () => {
    refetch({ range, sort })
  }

  return (
    <DataTable
      keyProp="PracticeFacilityID"
      data={permissions}
      columns={PracticeFacilityPermissionsModel(refetchWithParams, notifyError)}
      count={count}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePage}
      sortHandler={sortColumns}
      sortable={undefined}
      allowEditing={false}
      initPage={1}
      initPageSize={10}
    />
  )
}

interface PermissionResponse extends ApiRes {
  data: UserPermissions
}

const UserDetail = () => {
  const params: any = useParams()
  const { organizationId, userId } = params
  const [searchText, setSearchText] = React.useState('')
  const [managePermsByFacility, setManagePermsByFacility] =
    React.useState(false)

  const userRequest = { route: `/user/${userId}` }

  const { data: user, refetch: refetchUser } = useApi(
    userId ? userRequest : undefined
  )

  const {
    data: permissions,
    refetch: refetchPermissions,
    meta,
  }: PermissionResponse = useApi(
    organizationId && userId
      ? {
          route: `/user-management/organization/${organizationId}/user/${userId}/facility-permissions`,
        }
      : undefined
  )

  React.useEffect(() => {
    if (userId) {
      refetchUser(userRequest)
    }
  }, [userId])

  const debouncedRefetch = React.useCallback(
    debounce(refetchPermissions, 500),
    []
  )

  const onSearch = (val: string) => {
    setSearchText(val)

    if (val && val.length > 3) {
      debouncedRefetch({ filter: { q: val } })
    } else {
      debouncedRefetch()
    }
  }

  const clearPermissions = async () => {
    await deletePermissions(organizationId, userId)
    refetchPermissions()
  }

  const onToggle = (val: boolean) => {
    setManagePermsByFacility(val)
    clearPermissions()
  }

  // Unfortunately this derived state for the toggle is necessary,
  // but the side effects to be handled are minimal.
  // (The flag is permissions.ManageByFacility)
  //
  // WITHOUT SIDE EFFECTS:
  // toggle can be true, flag can be false
  // user sets pf perm -> flag is true, toggle should stay true - OK
  //
  // toggle can be false, flag can be false
  // no permission records - OK
  //
  // toggle can be false, flag can be false
  // permission record exists - OK
  //
  // SIDE EFFECT:
  // toggle can be false, flag can be true
  // with pf records -> set toggle to true
  React.useEffect(() => {
    if (permissions) {
      if (!managePermsByFacility && permissions.ManageByFacility) {
        setManagePermsByFacility(true)
      }
    }
  }, [permissions])

  if (!user || !permissions) return <LoadingUserDetail />

  return (
    <div>
      <BreadcrumbTrail
        organizationId={organizationId}
        name={nameFormatter(user.FirstName, user.LastName)}
      />
      <HeaderTitle
        style={{
          marginBottom: 5,
        }}>{`${nameFormatter(user.FirstName, user.LastName)} - ${user.Email}`}</HeaderTitle>
      <Paper elevation={3} style={{ padding: '10px', paddingTop: '5px' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '10px',
          }}>
          {managePermsByFacility ? (
            <SearchField
              label="Search facilities"
              value={searchText}
              onChange={onSearch}
            />
          ) : (
            <div />
          )}
          <div style={{ width: '40%' }} />
          <ManageFacilityPermsToggle
            value={managePermsByFacility}
            onChange={onToggle}
          />
        </div>
        {managePermsByFacility ? (
          <PracticeFacilitiesPermissions
            permissions={permissions.FacilityPermissions}
            refetch={refetchPermissions}
            count={meta && meta.Total}
          />
        ) : (
          <AllFacilitiesPermissions
            permission={permissions.AllFacilitiesPermission}
            refetch={refetchPermissions}
          />
        )}
      </Paper>
    </div>
  )
}

export default UserDetail
