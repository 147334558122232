import React from 'react'
import {
  REFERRAL_CREATED,
  REFERRAL_CREATED_FROM_REQUEST,
  REFERRAL_FILE_ADDED,
  REFERRAL_NEW_MSG,
  REFERRAL_REQUEST_CANCELLED,
  REFERRAL_REQUEST_CREATED,
  REFERRAL_REQUEST_FILE_ADDED,
  REFERRAL_REQUEST_UPDATED,
  REFERRAL_UPDATED,
} from '../../actions/ActivityActions'
import ReferralActivityItem from './ReferralActivityItem'
import ReferralRequestActivityItem from './ReferralRequestActivityItem'

export interface Activity {
  ID: number
  CreatedAt: string
  ReferralID: number
  ReferralRequestID: number | null
  ActivityTypeID: number
  Content: string
  Raw: string
  ActivityPerformerUserID: number
  ActivityPerformerUsername: string
  ConversationMessageID: number
  AckdByUserID: number | null
  AckdByUsername: string | null
  AckdOn: string | null
  PracticeFacilityName: string
  ActivityTitle: string
  RequiresAck: boolean | null
}

interface Props {
  activity: Activity
  growOpts: any
  allowAck: boolean
  onAck: Function
}

const ActivityItem: React.FC<Props> = (props) => {
  const { activity, growOpts, allowAck, onAck } = props
  const { ActivityTypeID, AckdOn, ActivityPerformerUsername, ActivityTitle } =
    activity

  const getTitle = (): string => {
    const titleMap: { [key: number]: string } = {
      [REFERRAL_FILE_ADDED]: `${ActivityPerformerUsername} uploaded a new file.`,
      [REFERRAL_CREATED]: `${ActivityPerformerUsername} created a new referral.`,
      [REFERRAL_UPDATED]: `${ActivityPerformerUsername} updated an existing referral.`,
      [REFERRAL_NEW_MSG]: `${ActivityPerformerUsername} posted a new message.`,
      [REFERRAL_REQUEST_CREATED]: `${ActivityPerformerUsername} created a new referral request.`,
      [REFERRAL_REQUEST_UPDATED]: `${ActivityPerformerUsername} updated your referral request.`,
      [REFERRAL_REQUEST_CANCELLED]: `${ActivityPerformerUsername} cancelled your referral request.`,
      [REFERRAL_CREATED_FROM_REQUEST]: `${ActivityPerformerUsername} approved your referral request.`,
      [REFERRAL_REQUEST_FILE_ADDED]: `${ActivityPerformerUsername} uploaded a new file.`,
    }

    return titleMap[ActivityTypeID] || ''
  }

  const showViewLink =
    ActivityTypeID === REFERRAL_NEW_MSG || ActivityTypeID === REFERRAL_CREATED

  const ackAndView =
    ActivityTypeID === REFERRAL_CREATED ||
    ActivityTypeID === REFERRAL_REQUEST_CREATED ||
    ActivityTypeID === REFERRAL_CREATED_FROM_REQUEST

  if (activity.ReferralID) {
    return (
      <ReferralActivityItem
        linkDescription={ActivityTitle}
        refId={activity.ReferralID}
        ack={allowAck && AckdOn === null}
        ackAndView={ackAndView}
        activity={activity}
        growOpts={growOpts}
        onAck={onAck}
        showViewLink={showViewLink}
        title={getTitle()}
      />
    )
  }

  if (activity.ReferralRequestID) {
    return (
      <ReferralRequestActivityItem
        linkDescription={ActivityTitle}
        referralRequestId={activity.ReferralRequestID}
        ack={allowAck && AckdOn === null}
        ackAndView={ackAndView}
        activity={activity}
        growOpts={growOpts}
        onAck={onAck}
        showViewLink={showViewLink}
        title={getTitle()}
      />
    )
  }

  return null
}

export default ActivityItem
