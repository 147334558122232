import React from 'react'
import styled from 'styled-components'
import MaskedInput from 'react-text-mask'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Input,
} from '@material-ui/core'
import ReferenceTextField from './ReferenceTextField'

interface Props {
  label: string
  value: string
  onChange: (val: string) => void
  error?: string | null
  readOnly?: boolean
}

interface MaskProps {
  value: string
  onChange: (e: any) => void
  inputRef: (ref: any) => void
}

const PhoneFieldContainer = styled.div`
  .MuiInputBase-input.Mui-disabled {
    color: initial;
  }

  .MuiInput-underline.Mui-disabled:before {
    border: none;
  }
`

const PhoneNumberMask: React.FC<MaskProps> = (props: MaskProps) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder="(___) ___-____"
    />
  )
}

const PhoneField: React.FC<Props> = (props: Props) => {
  const { label, value, onChange, error, readOnly } = props

  if (readOnly) {
    return <ReferenceTextField label={label} value={value} />
  }

  return (
    <PhoneFieldContainer>
      <FormControl fullWidth error={!!error}>
        <InputLabel>{label}</InputLabel>
        <Input
          value={value}
          onChange={(e: any) => onChange(e.target.value)}
          inputComponent={PhoneNumberMask as any}
          disabled={readOnly}
        />
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </PhoneFieldContainer>
  )
}

export default PhoneField
