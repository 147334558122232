import React from 'react'
import moment from 'moment'
import {
  Button,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'
import {
  CheckCircleOutline,
  ErrorOutline,
  Visibility,
} from '@material-ui/icons'
import FormHeader from './FormHeader'
import FormRow from './FormRow'
import TextField from '../TextField'
import PhoneField from '../PhoneField'
import PhoneExtField from '../PhoneExtField'
import ReferenceTextField from '../ReferenceTextField'
import { ReferralForm } from './types'
import { ReferralRequestForm } from '../ReferralRequestForm/types'
import { LoadingFormSection } from './LoadingSkeletons'
import { defaultDateFormatter } from '../../utils'

interface Props {
  form: ReferralForm | ReferralRequestForm
  showErrors: boolean
}

const formatDate = (date: string) => {
  const m = moment.utc(date)

  if (!date || m.year() < 1900) {
    return 'Not Set'
  }

  return m.format('MM/DD/YYYY')
}

const ReferenceSSNField: React.FC<{ value: string }> = ({ value }) => {
  const [visible, setVisible] = React.useState(false)
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div style={{ width: '90px' }}>
        <ReferenceTextField
          label="Last 4 SSN"
          value={visible ? value : '* * * *'}
        />
      </div>
      <Button onClick={() => setVisible(!visible)}>
        <Visibility style={{ color: 'gray' }} />
      </Button>
    </div>
  )
}

const CurrentlyActiveField: React.FC<{ active: boolean }> = ({ active }) => (
  <FormControl fullWidth>
    <InputLabel>Currently Active</InputLabel>
    <Input
      value={`Member is ${active ? 'ACTIVE' : 'INACTIVE'}`}
      style={{ color: active ? 'green' : 'firebrick' }}
      startAdornment={
        <InputAdornment position="start">
          {active ? <CheckCircleOutline /> : <ErrorOutline />}
        </InputAdornment>
      }
      fullWidth
      disabled
      disableUnderline
    />
  </FormControl>
)

const MemberDetails: React.FC<Props> = ({ form, showErrors }) => {
  const member = form.data.EmployerMember

  return (
    <div>
      <FormHeader title="Member Details" />

      {!member ? (
        <LoadingFormSection />
      ) : (
        <>
          <FormRow>
            <Grid item xs={4}>
              <ReferenceTextField
                label="Name"
                value={`${member.FirstName} ${member.LastName}`}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceSSNField value={member.SSNLast4} />
            </Grid>
            <Grid item xs={3}>
              <ReferenceTextField
                label="DOB"
                value={formatDate(member.Dob || member.DOB)}
              />
            </Grid>
            <Grid item xs={2}>
              <ReferenceTextField label="Gender" value={member.Gender} />
            </Grid>
          </FormRow>

          <FormRow>
            <Grid item xs={4}>
              <ReferenceTextField
                label="Integration ID"
                value={member.IntegrationID}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceTextField
                label="Employer"
                value={form.data.EmployerName || member.EmployerName}
              />
              {form?.data?.EntityName === 'referral' &&
                !!form?.data?.EmployerTermDate && (
                  <span
                    style={{
                      fontSize: '80%',
                      color: 'red',
                    }}>
                    <strong>Termination Date:</strong>{' '}
                    {defaultDateFormatter(form?.data?.EmployerTermDate)}
                  </span>
                )}
            </Grid>
            <Grid item xs={5}>
              <ReferenceTextField label="Plan Name" value={member.PlanName} />
            </Grid>
          </FormRow>

          <FormRow>
            <Grid item xs={4}>
              <TextField
                label="Contact Email"
                value={form.data.ContactEmail || ''}
                onChange={form.setters.ContactEmail}
                error={showErrors ? form.errors.ContactEmail : null}
                readOnly={
                  form.data.EntityName === 'referral'
                    ? !form.data.Edit
                    : !form.data.ContentEditable
                }
              />
            </Grid>
            <Grid item xs={2}>
              <PhoneField
                label="Contact Phone"
                value={form.data.ContactPhone || ''}
                onChange={form.setters.ContactPhone}
                error={showErrors ? form.errors.ContactPhone : null}
                readOnly={
                  form.data.EntityName === 'referral'
                    ? !form.data.Edit
                    : !form.data.ContentEditable
                }
              />
            </Grid>
            <Grid item xs={1}>
              <PhoneExtField
                label="Ext."
                value={form.data.ContactPhoneExt || ''}
                onChange={form.setters.ContactPhoneExt}
                error={showErrors ? form.errors.ContactPhoneExt : null}
                readOnly={
                  form.data.EntityName === 'referral'
                    ? !form.data.Edit
                    : !form.data.ContentEditable
                }
              />
            </Grid>
            <Grid item xs={5}>
              <ReferenceTextField
                label="Address"
                value={member.Address.DisplayAddress}
              />
            </Grid>
          </FormRow>

          <FormRow>
            <Grid item xs={4}>
              <CurrentlyActiveField
                active={member.Active || member.IsCurrentlyActive}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceTextField
                label="Activation Date"
                value={formatDate(member.ActivationDate)}
              />
            </Grid>
            <Grid item xs={3}>
              <ReferenceTextField
                label="Deactivation Date"
                value={formatDate(member.DeactivationDate)}
              />
            </Grid>
          </FormRow>
        </>
      )}
    </div>
  )
}

export default MemberDetails
