import * as api from '../services/ApiService'
import { getErrorMsg } from './utils'

export const GET_REFERRAL_SUCCESS = 'GET_REFERRAL_SUCCESS'
export const GET_REFERRAL_ERROR = 'GET_REFERRAL_ERROR'
export const GET_REFERRAL = 'GET_REFERRAL'
export const GET_REFERRALS_SUCCESS = 'GET_REFERRALS_SUCCESS'
export const GET_REFERRALS_ERROR = 'GET_REFERRALS_ERROR'
export const GET_REFERRALS = 'GET_REFERRALS'
export const GET_REFERRAL_STATUSES_SUCCESS = 'GET_REFERRAL_STATUSES_SUCCESS'
export const GET_REFERRAL_STATUSES_ERROR = 'GET_REFERRAL_STATUSES_ERROR'
export const GET_REFERRAL_STATUSES = 'GET_REFERRAL_STATUSES'
export const GET_REFERRAL_USERS_SUCCESS = 'GET_REFERRAL_USERS_SUCCESS'
export const GET_REFERRAL_USERS_ERROR = 'GET_REFERRAL_USERS_ERROR'
export const GET_REFERRAL_USERS = 'GET_REFERRAL_USERS'

export const archiveReferral = (
  ids: Array<number>,
  doArchive: boolean = true
) => {
  const params = { ReferralIDs: ids, DoArchive: doArchive }
  return api.put('/referral_archived', params).then((res: any) => {
    if (res.error) throw 'Error Archiving Referrals, please contact engineering'
    return res
  })
}

export const getOrg = ({ id }: { id: number }) => {
  return api.get(`/organization/${id}`)
}

// Get all organizations
export const getAllOrgs = () => {
  return api.get(`/organization`, { filter: { Active: true, NoLimit: true } })
}

export const getReferral = (params: any) => {
  const { id } = params
  return api.get(`/referral/${id}`)
}

export const getReferralSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralError = (err: any) => {
  return {
    type: GET_REFERRAL_ERROR,
    payload: getErrorMsg(err),
    meta: {
      notification: {
        body: 'There was a problem loading the referral, please try again or contact your administrator',
        level: 'error',
      },
    },
  }
}

export const getReferrals = (params: any) => {
  return api.get('/referral', params)
}

export const getReferralsSuccess = (data: any) => {
  return {
    type: GET_REFERRALS_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralsError = (err: any) => {
  return {
    type: GET_REFERRALS_ERROR,
    payload: getErrorMsg(err),
  }
}

export const getReferralStatuses = () => {
  return api.get('/referral_statuses')
}

export const getReferralStatusesSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_STATUSES_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralStatusesError = (err: any) => {
  return {
    type: GET_REFERRAL_STATUSES_ERROR,
    payload: getErrorMsg(err),
  }
}

export const getReferralUsers = ({ id }: { id: number }) => {
  return api.get(`/referral/${id}/users`)
}

export const getReferralUsersSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_USERS_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralUsersError = (err: any) => {
  return {
    type: GET_REFERRAL_USERS_ERROR,
    payload: getErrorMsg(err),
  }
}

export const removeReferrerFromReferral = (referralID: number) => {
  return api.post(`/referral/${referralID}/remove_referrer`)
}
