import styled from 'styled-components'
import { COLORS } from '../../utils/colors'
import { AppBar, Button, ListItemText } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const getAppBarComponent = (env: string) => {
  // production is default, sweet gradient
  let AppBarStyled = styled(AppBar)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-items: center;
    background: linear-gradient(
      -45deg,
      ${COLORS.PROVIDERS.BLUEBERRY} 0%,
      ${COLORS.PROVIDERS.EGGPLANT} 29%,
      ${COLORS.PROVIDERS.FUSCIA} 69%,
      ${COLORS.PROVIDERS.VIOLET} 96%
    );
  `

  // If this is not production change the color of the app bar
  if (env === 'dev' || env === 'local') {
    AppBarStyled = styled(AppBarStyled)`
      background: ${COLORS.DEV_APP_BAR};
    `
  }

  if (env === 'stg') {
    AppBarStyled = styled(AppBarStyled)`
      background: ${COLORS.STG_APP_BAR};
    `
  }

  return AppBarStyled
}

const HalfContainer = styled.div`
  width: 50%;
`

export const HalfContainerFlexStart = styled(HalfContainer)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const HalfContainerFlexEnd = styled(HalfContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const LoginBtn = styled(Button)`
  color: ${COLORS.BW.INK_BLACK};
  padding-left: 20px;
`

export const AppBarStyled = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
`

export const MenuFooter = styled(ListItemText)`
  position: fixed;
  bottom: 0px;
  width: 220px;
`

export const MenuLink = styled(Link)`
  span {
    color: ${COLORS.BW.INK_BLACK};
  }

  text-decoration: none;
`
