import React, { useEffect, useState } from 'react'
import { ICDActions } from '../../actions'
import usePrevious from '../../hooks/usePrevious'
import useSnackbar from '../../hooks/useSnackbar'
import { ICD10CodeAutoComplete } from '../Inputs/icd10CodeAutoComplete'
import { ICDCode, ReferralInvoice } from '../ReferralInvoiceForm/types'

const { findICDs, saveReferralInvoiceICDCodes } = ICDActions

interface InvoiceICDCodeSelectorProps {
  codes: ReferralInvoice['ICD10Codes']
  id: number
  readOnly: boolean
}

const invoiceICDCodeSelector = ({
  codes,
  id,
  readOnly,
}: InvoiceICDCodeSelectorProps) => {
  const [icds, setICDs] = useState(codes)
  const prevIcds = usePrevious(icds)
  const { show: showSnackbar } = useSnackbar()

  const handleDelete = (deleteICD: ICDCode) => {
    const newICDs = icds
      .filter((icd) => icd.Code !== deleteICD.Code)
      .map((icd) => icd)

    setICDs(newICDs)

    const newCodes = newICDs.map((icd) => icd.Code)

    saveReferralInvoiceICDCodes({
      referralInvoiceID: id,
      ICDCodes: newCodes,
    }).catch((err: any) => {
      showSnackbar(`Error saving ICD Codes: ${err.Error}`, 'error')
    })
  }

  const fetchICDs = (val: string) => {
    return findICDs({ filter: { q: val } })
  }

  const handleSelected = (addICD: ICDCode) => {
    const newICDs = [...icds, addICD]

    setICDs(newICDs)

    const newCodes = newICDs.map((icd) => icd.Code)

    saveReferralInvoiceICDCodes({
      referralInvoiceID: id,
      ICDCodes: newCodes,
    }).catch((err: any) => {
      showSnackbar(`Error saving ICD Codes: ${err.Error}`, 'error')
    })
  }

  useEffect(() => {
    if (!prevIcds && !!icds) {
      setICDs(icds)
    }
  }, [icds])

  return (
    <ICD10CodeAutoComplete
      readOnly={readOnly}
      fetch={fetchICDs}
      threshold={3}
      onSelect={handleSelected}
      onRemove={handleDelete}
      placeholder="Type the first 3 characters to search"
      label="ICD Codes"
      labelGetter={(option: any) => option.Formatted}
      initialValues={icds}
    />
  )
}

export default invoiceICDCodeSelector
