import * as api from '../services/ApiService'
import { getErrorMsg } from './utils'

export const FIND_MEMBERS_SUCCESS = 'FIND_MEMBERS_SUCCESS'
export const FIND_MEMBERS_ERROR = 'FIND_MEMBERS_ERROR'
export const FIND_MEMBER_START = 'FIND_MEMBER_START'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'

export const findMembers = (params: any) => {
  return api.get('/eligibility_search', params)
}

export const findMemberStart = (params: any) => {
  return {
    type: FIND_MEMBER_START,
  }
}

export const findMembersSuccess = (data: any) => {
  return {
    type: FIND_MEMBERS_SUCCESS,
    payload: data.Data,
  }
}

export const findMembersError = (err: any) => {
  return {
    type: FIND_MEMBERS_ERROR,
    payload: getErrorMsg(err),
  }
}

export const clearSearchResults = () => {
  return {
    type: CLEAR_SEARCH_RESULTS,
  }
}
