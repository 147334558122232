import styled from 'styled-components'
import { Button } from '@material-ui/core'

export const PageTitle = styled.h1`
  text-align: left;
  padding-bottom: 15px;
  font-family: 'Raleway';
`
export const StandardButton = styled(Button)`
  margin-top: 6px;
  max-width: 225px;
  max-height: 36px;
  margin-right: 5px;
  font-size: 12px;
  padding: 10px;
`

export const SectionHeader = styled.h5`
  margin: 2.5rem 0 0.75rem;
  font-size: 110%;
`
