import React from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Logo from '../../assets/tzc-logo.png'

export default ({ devMsg, message }: { devMsg: string; message: string }) => {
  const renderDevMsg = () => {
    return (
      <div style={{ width: '400px', margin: '25px' }}>
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="body1">Technical Details</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>{devMsg}</Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }

  return (
    <div>
      <div style={{ textAlign: 'center' }}>
        <img
          alt="logo"
          style={{ marginTop: '15px' }}
          height="50px"
          width="50px"
          src={Logo}
        />
        <Typography variant="h3" component="h2" gutterBottom>
          Our apologies
        </Typography>
        <Typography gutterBottom>{message}</Typography>
        {devMsg ? renderDevMsg() : null}
        <Typography variant="body2">
          If this persists, please{' '}
          <a href="https://zero.health/contact-us/">Contact Us</a>
        </Typography>
      </div>
    </div>
  )
}
