import React from 'react'
import moment from 'moment'
import { Typography, Grid, Chip } from '@material-ui/core'
import { FormGrid, StyledGridRow, ReadOnlyField } from './components'
import { ReferralFile } from '../Files'
import AttachedBundles from '../Referrals/AttachedBundles'
import { FileActions } from '../../actions'
import { ReferralInvoice } from './types'
import useApi from '../../hooks/useApi'
import { AttachFile } from '@material-ui/icons'
import Backlink from '../Backlink'
import { parseS3Key } from '../../utils'
import styled from 'styled-components'

const { REFERRAL_FILE } = FileActions

const ChipGrid = styled(Grid)`
  margin-top: 5px;
`

interface ReferralDetailProps {
  referral: ReferralInvoice['Referral'] & {
    ID: number
    Gender: string
  }
}

const ReferralDetailSectionComponent: React.FC<ReferralDetailProps> = ({
  referral,
}) => {
  const { data: refFiles } = useApi({
    route: `/referral/${referral.ID}/file`,
  })

  const handleFileClick = ({ ReferralID, FileID }: any) => {
    window.open(
      `/download?id=${ReferralID}&type=${REFERRAL_FILE}&fileId=${FileID}`,
      '_blank'
    )
  }

  const hasEditPermission = referral.Edit

  const renderFiles = () => {
    if (refFiles && refFiles.length > 0) {
      return refFiles.map((file: ReferralFile) => {
        return (
          <ChipGrid container key={file.S3Key}>
            <Chip
              icon={<AttachFile />}
              clickable
              label={parseS3Key(file.S3Key)}
              onClick={() => handleFileClick(file)}
              variant="outlined"
              color="primary"
            />
          </ChipGrid>
        )
      })
    }
  }

  const formatMemberName = () => {
    if (!referral.Gender) return referral.MemberName
    return `${referral.MemberName} (${referral.Gender})`
  }

  return (
    <FormGrid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Referral Details</Typography>
        {hasEditPermission && (
          <Backlink to={`/referral/${referral.ID}`}>View original</Backlink>
        )}
      </Grid>
      <Grid item xs={12}>
        <StyledGridRow
          container
          justify="space-between"
          alignItems="flex-start"
          direction="row"
          xs={12}>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField label="Description" value={referral.Descr} />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Date of Service"
              value={moment(referral.DateOfService).format('MM/DD/YYYY')}
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Organization"
              value={referral.OrganizationName}
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
        </StyledGridRow>
        <StyledGridRow
          container
          justify="space-between"
          alignItems="flex-start"
          direction="row"
          xs={12}>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Facility"
              value={referral.PracticeFacilityName}
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Member Name (Gender)"
              value={formatMemberName()}
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField label="Employer" value={referral.EmployerName} />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
        </StyledGridRow>
        <StyledGridRow
          container
          justify="space-between"
          alignItems="flex-start"
          direction="row"
          xs={12}>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Member DOB"
              value={moment(referral.DOB).format('MM/DD/YYYY')}
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Member Activation"
              value={
                referral.MemberActivationDate
                  ? moment(referral.MemberActivationDate).format('MM/DD/YYYY')
                  : 'N/A'
              }
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Member Deactivation"
              value={
                referral.MemberDeactivationDate
                  ? moment(referral.MemberDeactivationDate).format('MM/DD/YYYY')
                  : 'N/A'
              }
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
        </StyledGridRow>
        <StyledGridRow
          container
          justify="space-between"
          alignItems="flex-start"
          direction="row"
          xs={12}>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <ReadOnlyField
              label="Referral Notes"
              value={referral.ReferralNotes}
            />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <div>
              <Typography variant="subtitle2" style={{ paddingBottom: 10 }}>
                <strong>Referral Files</strong>
              </Typography>
              {renderFiles()}
            </div>
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={3}>
            <Typography variant="subtitle2" style={{ paddingBottom: 10 }}>
              <strong>Attached Bundles</strong>
            </Typography>
            <AttachedBundles ID={referral.ID} viewOnly={true} />
          </StyledGridRow>
          <StyledGridRow
            item
            justify="center"
            alignItems="center"
            direction="row"
            xs={1}
          />
        </StyledGridRow>
        <StyledGridRow
          container
          justify="space-between"
          alignItems="flex-start"
          direction="row"
          xs={12}></StyledGridRow>
      </Grid>
    </FormGrid>
  )
}

export default ReferralDetailSectionComponent
