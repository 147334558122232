import React from 'react'
import moment from 'moment-timezone'
import { PhoneInput } from '../components/Inputs'

export const NULL_DATE = moment('0001-01-01T00:00:00Z').utc()

/**
 * Return true if we are on production
 * This is used for themeing purposes in most cases
 */
export const checkIfProduction = () => {
  const reactAppEnv = process.env.REACT_APP_ENV
  return reactAppEnv === 'prd'
}

export const checkIfActive = (start, end) => {
  const now = moment().utc()

  if (start > now) {
    if (end > start && end < now) return false
    if (start.diff(NULL_DATE) === 0) return false

    return true
  }

  return false
}

export const parseS3Key = (s3Key) => {
  if (!s3Key) return ''
  const parts = s3Key.split('/')
  if (parts && parts.length > 0) return parts[parts.length - 1]
}

export const defaultDateFormatter = (cell, _row) => {
  const fmt = `MM/DD/YYYY`
  // chaining utc will mutate
  if (moment.utc(cell).diff(NULL_DATE) === 0) return <span>`N/A`</span>
  return <span>{moment(cell).format(fmt)}</span>
}

export const lastUpdatedDateFormatter = (cell, _row) => {
  const fmt = `MM/DD/YYYY h:mm:ss A`
  // chaining utc will mutate
  if (moment.utc(cell).diff(NULL_DATE) === 0) return <span>`N/A`</span>
  return <p style={{ width: '160px' }}>{moment(cell).format(fmt)}</p>
}

export const momentCalendarFormatter = (date) => {
  return getCal(date, {})
}

export const getCal = (date, { hideTime, useUtc }) => {
  const formatted = moment(date)
  let cal = date

  if (formatted.isValid() === true) {
    const withTime = '[at] h:mm A'
    if (useUtc) formatted.utc()

    cal = formatted.calendar(null, {
      sameDay: function (_now) {
        const format = '[Today]'
        return !hideTime ? `${format} ${withTime}` : format
      },
      lastWeek: function (_now) {
        const format = 'dddd'
        return !hideTime ? `${format} ${withTime}` : format
      },
    })
  }

  return cal
}

export const isValidCpt = (cpt) => {
  return cpt && typeof cpt === 'string' && cpt.length === 5
}

export const DecoratedPhoneInput = (props) => {
  const showError = props.meta.error !== undefined && props.meta.submitFailed
  return (
    <PhoneInput
      error={showError}
      helperText={props.meta.error}
      onChange={props.input.onChange}
      value={props.input.value}
      label={props.label}
    />
  )
}

export const registerPoller = (fn, interval) => {
  return setInterval(() => window['dev_pause_poll'] !== true && fn(), interval)
}

export const deregisterPoller = (pollId) => {
  clearInterval(pollId)
}

export const errors = {
  IsForbidden: (err) => {
    return err && err.Error && err.Error.StatusCode === 403
  },
}

export const getIds = (collection) => {
  if (!collection || collection.length === 0) return []
  return collection.map((item) => {
    return item.ID
  })
}

export const nameFormatter = (firstName, lastName) => {
  return `${firstName} ${lastName}`
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function bytesToFriendly(bytes, decimals = 2) {
  if (!bytes) return '0 Bytes (or unknown)'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function makeRandomString(len = 8) {
  // Declare all characters
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  // Pick characers randomly
  let str = ''
  for (let i = 0; i < len; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return str
}

// Used by src/components/DataTable. This is dumb to have it live here, but
// its less hacky than it was before. @todo: ensure only tables that need to use
// this are doing so, and make the default argument to the DataTable a no-op.
export function defaultTableRowColorer(row) {
  if (row.IsUrgent) return '#ff9e7b'
  if (row.ArchivedAt) return '#ededed'
  return null
}
