import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField as MatTextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { Add as ContentAdd, Cancel, HelpOutline } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import DataTable from '../DataTable'
import { defaultDateFormatter } from '../../utils'
import { FindMemberActions } from '../../actions'
import queryString from 'query-string'
import moment from 'moment'
import _ from 'lodash'
import useQueryParams from '../../hooks/useQueryParams'
import { DateInput } from '../Inputs'
import { ClassNameMap } from '@material-ui/styles/withStyles'

const { findMembers } = FindMemberActions

const styles = (theme: any) => {
  return {
    root: {
      paddingRight: theme.spacing(1),
    },
    tooltip: {
      color: theme.palette.secondary.main,
      width: 15,
      height: 20,
      cursor: 'pointer',
    },
    searchBarContainer: {
      padding: '20px',
    },
    searchBarField: {
      marginRight: '5px',
    },
    searchBtn: {
      marginLeft: '5px',
      marginTop: '12px',
    },
  }
}

const searchFields = {
  firstName: '',
  lastName: '',
  integrationID: '',
  employerMemberID: null,
  dob: '',
}

interface FindMemberProps {
  location: Location
  classes: ClassNameMap<string>
}

interface HandleSearchProps {
  data?: any
  error?: any
}

const findMember = ({ classes, location }: FindMemberProps) => {
  const params = queryString.parse(location.search)
  const [fields] = useState(_.assign({}, searchFields, params))
  const [searching, setSearching] = useState<boolean>(false)
  const [members, setMembers] = useState([])
  const [membersError, setMembersError] = useState<any>()

  const handleSearchAttempt = () => {
    setSearching(true)
    setMembers([])
    setMembersError(undefined)
  }

  const handleSearch = ({ data, error }: HandleSearchProps) => {
    setSearching(false)
    setMembers(data ? data.Data : [])
    setMembersError(error)
  }

  return (
    <div>
      <Paper className={classes.root}>
        <DataTable
          data={members}
          getError={membersError}
          columns={columns}
          keyProp={'MemberID'}
          customToolbar={
            <FindMemberSearchBar
              params={fields}
              searchCb={handleSearch}
              onSearch={handleSearchAttempt}
              formType={params.formType}
            />
          }
          pagination={false}
          loading={searching}
          count={members ? members.length : 0}
          sortable={undefined}
          allowEditing={false}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
          initPage={1}
          initPageSize={10}
        />
      </Paper>
    </div>
  )
}
export const FindMember = withStyles(styles)(findMember)

interface FindMemberSearchBarProps {
  params: any
  classes: ClassNameMap<string>
  searchCb: any
  onSearch: any
  formType: any
  history: any
}

const findMemberSearchBar = ({
  params,
  classes,
  searchCb,
  onSearch,
  formType,
  history,
}: FindMemberSearchBarProps) => {
  const [fields, setFields] = useState(_.assign({}, searchFields, params))
  const [dobIsValid, setDOBIsValid] = useState(
    _.isEmpty(fields.dob) || moment(fields.dob).isValid()
  )

  useEffect(() => {
    // auto-exec a search if we have all the fields needed (from querystring)
    if (isValidSearch()) {
      handleSearch()
    }
  }, [])

  useEffect(() => {
    setDOBIsValid(_.isEmpty(fields.dob) || moment(fields.dob).isValid())
  }, [fields.dob])

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSearch()
    }
  }

  const handleSearch = () => {
    if (!isValidSearch()) return null
    const { dob, firstName, lastName, integrationID, employerMemberID } = fields
    onSearch()

    let dobStr = ''
    if (!_.isEmpty(dob)) {
      const parsedDOB = moment(dob)
      if (parsedDOB.isValid()) {
        dobStr = parsedDOB.format('YYYY-MM-DD')
      }
    }

    const params = _.omitBy(
      {
        firstName,
        lastName,
        dob: dobStr,
        integrationID: integrationID,
        employerMemberID,
        filterInactive: true,
        formType: formType,
      },
      _.isNil
    )

    history.push(`?${queryString.stringify(params)}`)
    findMembers(params)
      .then((data: any) => {
        searchCb({ data })
      })
      .catch((err: any) => {
        searchCb({ error: err })
      })
  }

  const isValidSearch = () => {
    const { dob, firstName, lastName, integrationID, employerMemberID } = fields
    if (dob && !moment(dob).isValid()) return false
    return (
      dob ||
      (firstName && firstName.length > 1 && lastName && lastName.length > 1) ||
      integrationID !== '' ||
      employerMemberID
    )
  }

  const handleChange = (e: any) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    })
  }

  const handleClearEmpMemId = () => {
    setFields({
      ...fields,
      employerMemberID: null,
    })
  }

  const { dob, firstName, lastName, integrationID, employerMemberID } = fields
  const ttTitle = `Search by: First and Last Name (at least the first 3 characters of each) OR Integration ID (Zerocard ID) OR the member's Date of Birth`

  return (
    <div>
      <div className={classes.searchBarContainer}>
        <div>
          <Typography variant="h5">
            Search for an eligible member{' '}
            <Tooltip title={ttTitle}>
              <HelpOutline className={classes.tooltip} />
            </Tooltip>
          </Typography>
        </div>
        <MatTextField
          variant="standard"
          value={firstName}
          onKeyPress={onKeyPress}
          onChange={handleChange}
          name="firstName"
          className={classes.searchBarField}
          label="First Name"
        />
        <MatTextField
          variant="standard"
          value={lastName}
          onKeyPress={onKeyPress}
          onChange={handleChange}
          name="lastName"
          className={classes.searchBarField}
          label="Last Name"
        />
        <MatTextField
          variant="standard"
          value={integrationID}
          onKeyPress={onKeyPress}
          onChange={handleChange}
          name="integrationID"
          className={classes.searchBarField}
          label="Integration ID"
        />
        <DateInput
          value={dob}
          onChange={handleChange}
          name="dob"
          className={classes.searchBarField}
          label="Date Of Birth"
          error={!dobIsValid}
        />
        {employerMemberID && (
          <MatTextField
            value={employerMemberID}
            disabled
            name="employerMemberID"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClearEmpMemId}>
                    <Cancel />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            className={classes.searchBarField}
            label="ID"
          />
        )}
        <Button
          className={classes.searchBtn}
          onKeyPress={onKeyPress}
          onClick={handleSearch}
          variant="contained">
          Search
        </Button>
      </div>
    </div>
  )
}
export const FindMemberSearchBar = compose<any, any>(
  withRouter,
  withStyles(styles)
)(findMemberSearchBar)

interface AddNewReferralFormatter {
  MemberID: number
  EmployerMemberID: number
  EmployerName: string
  EmployerID: number
  FirstName: string
  LastName: string
  DOB: string
  Gender: string
  IntegrationID: number
  SSNLastFour: string
  PlanName: string
  IsCurrentlyActive: boolean
  DisplayAddress: string
  ActivationDate: string
  DeactivationDate: string
}

const AddNewReferralFormatter = ({
  MemberID,
  EmployerMemberID,
  EmployerName,
  ...EmployerMember
}: AddNewReferralFormatter) => {
  const { params } = useQueryParams()

  const urlPath =
    params.formType === 'referralRequest'
      ? '/referral_request/create'
      : '/referral/create'

  return (
    <span>
      <IconButton
        aria-label="Add new referral"
        component={Link}
        to={{
          pathname: urlPath,
          state: {
            record: {
              StatusID: 1,
              MemberID,
              EmployerMemberID: EmployerMemberID,
              EmployerName: EmployerName,
              EmployerMember: {
                EmployerID: EmployerMember.EmployerID,
                FirstName: EmployerMember.FirstName,
                LastName: EmployerMember.LastName,
                DOB: EmployerMember.DOB,
                Gender: EmployerMember.Gender,
                IntegrationID: EmployerMember.IntegrationID,
                SSNLast4: EmployerMember.SSNLastFour,
                PlanName: EmployerMember.PlanName,
                Active: EmployerMember.IsCurrentlyActive,
                Address: { DisplayAddress: EmployerMember.DisplayAddress },
                ActivationDate: EmployerMember.ActivationDate,
                DeactivationDate: EmployerMember.DeactivationDate,
              },
            },
          },
        }}>
        <ContentAdd fontSize="small" color="secondary" />
      </IconButton>
    </span>
  )
}

const columns = {
  AddNew: {
    name: 'Add Referral',
    details: {
      dataFormat: (_cell: any, row: any) => (
        <AddNewReferralFormatter {...row} />
      ),
      options: {
        width: '100px',
      },
    },
  },
  FirstName: {
    name: 'First Name',
  },
  LastName: {
    name: 'Last Name',
  },
  DOB: {
    name: 'DOB',
    details: { dataFormat: defaultDateFormatter },
  },
  Gender: {
    name: 'Gender',
  },
  EmployerName: {
    name: 'Employer',
  },
}
