import React from 'react'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import { Refresh } from '@material-ui/icons'

const refresh = () => {
  window.location.reload()
}

interface StalePageNotifierProps {
  open: boolean
}

export const StalePageNotifier = ({ open }: StalePageNotifierProps) => {
  const duration = 1000000000000000

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={duration}>
      <SnackbarContentWrapper />
    </Snackbar>
  )
}

const SnackbarContentWrapper = () => {
  return (
    <SnackbarContent
      message={`This page has stopped automatically updating. Please refresh.`}
      action={[
        <IconButton key="refresh" color="inherit" onClick={refresh}>
          <Refresh />
        </IconButton>,
      ]}
    />
  )
}
