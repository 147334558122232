import React from 'react'
import styled from 'styled-components'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { Save, Cancel, AddBox } from '@material-ui/icons'
import { ReferralRequestForm } from './types'
import { put, post } from '../../services/ApiService'
import * as formatters from '../../utils/formatters'
import * as T from './types'
import { COLORS } from '../../utils/colors'
import useSnackbar from '../../hooks/useSnackbar'

interface Props {
  form: ReferralRequestForm
  isEditing: boolean
  setAttemptedSave: (val: boolean) => void
  customErrors: string[]
  setCustomErrors: any
  refetch: () => void
}

const Footer = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
`

// convert data for the api request
const convertData = (data: T.ReferralRequestFormData) => {
  return {
    ...data,
    RequestedServiceDate: data.RequestedServiceDate
      ? formatters.date.deformat(data.RequestedServiceDate)
      : data.RequestedServiceDate,
  }
}

const ActionsFooter: React.FC<Props> = ({
  form,
  refetch,
  isEditing,
  setAttemptedSave,
  customErrors,
  setCustomErrors,
}) => {
  const hist = useHistory()
  const loc: any = useLocation()
  const data = convertData(form.data)
  const { show: showSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = React.useState(false)
  const [isGenerating, setIsGenerating] = React.useState(false)

  const save = async () => {
    setIsSaving(true)
    const valid = form.isValid()

    if (!valid) {
      showSnackbar('Please correct form errors.', 'error')
      setAttemptedSave(true)
      setIsSaving(false)
      return
    }

    if (isEditing) {
      await put(`/referral_request/${data.ID}`, data)
      setIsSaving(false)
      showSnackbar('Success', 'success')
      hist.push(
        `/referral_request/${data.ID}${
          loc.state ? loc.state.prevQueryString : ''
        }`
      )
      refetch()
    } else {
      const res: any = await post(`/referral_request`, data)
      showSnackbar('Success', 'success')
      setIsSaving(false)
      hist.push(`/referral_request/${res.Data.ID}`)
    }
  }

  const cancel = async () => {
    await put(`/referral_request/${data.ID}/cancel`)
    showSnackbar('The referral request has been cancelled.', 'success')
    hist.push(`/referrals`)
  }

  const generateReferral = async () => {
    setIsGenerating(true)
    const valid = form.isValid()

    const preventInvalid = () => {
      setAttemptedSave(true)
      showSnackbar('Please correct form errors.', 'error')
      setIsGenerating(false)
    }

    if (!form.data.OrganizationID) {
      setCustomErrors([...customErrors, 'OrganizationID'])
      return preventInvalid()
    }

    if (!form.data.PracticeFacilityID) {
      setCustomErrors([...customErrors, 'PracticeFacilityID'])
      return preventInvalid()
    }

    if (!valid) {
      return preventInvalid()
    }

    await put(`/referral_request/${data.ID}`, data)
    const res: any = await post(`/referral_request/${data.ID}/to_referral`)
    hist.push(`/referral/${res.Data.ReferralID}`)
  }

  return (
    <Footer>
      {form.data.ContentEditable && (
        <Button
          color="primary"
          variant="contained"
          disabled={isSaving}
          startIcon={<Save />}
          onClick={save}
          style={{
            marginRight: '10px',
          }}>
          Save
        </Button>
      )}
      {form.data.NextReferralRequestStatuses.includes('referral') && (
        <Button
          variant="contained"
          startIcon={<AddBox />}
          onClick={generateReferral}
          disabled={isGenerating}
          style={{
            marginRight: '10px',
            backgroundColor: COLORS.PROVIDERS.VIOLET,
            color: 'white',
          }}>
          Save and Generate Referral
        </Button>
      )}
      {form.data.NextReferralRequestStatuses.includes('cancel') && (
        <Button
          variant="contained"
          startIcon={<Cancel />}
          onClick={cancel}
          style={{
            backgroundColor: '#ff9a00', // orangey-red from logo
            color: 'white',
          }}>
          Cancel Request
        </Button>
      )}
    </Footer>
  )
}

export default ActionsFooter
