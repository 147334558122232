import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { FormGrid, SectionSubheader } from './components'
import TextField from '../TextField'

interface NotesSectionProps {
  form: any
  disabled: boolean
}

const NotesSection: React.FC<NotesSectionProps> = ({ form, disabled }) => (
  <FormGrid container>
    <Grid item xs={12}>
      <Typography variant="h5">Invoice Notes</Typography>
      <SectionSubheader>
        For details and communication about the invoice
      </SectionSubheader>
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Notes"
        value={form.data.Notes}
        onChange={form.setters.Notes}
        disabled={disabled}
        multiline
        rows={4}
      />
    </Grid>
  </FormGrid>
)

export default NotesSection
