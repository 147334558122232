import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Chip } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { getReferralRequestPrices } from '../../actions/PriceActions'
import ReferralRequestPricesDialog from './dialog'
import _ from 'lodash'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import { ReferralPriceBundle } from '../ReferralPrices/types'

const styles = (theme: any) => {
  return {
    chipper: {
      margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px 0`,
    },
  }
}

interface ReferralRequestPricesProps {
  classes: ClassNameMap<string>
  referralRequestID: number
  hintMainCPT?: string
  viewOnly?: boolean
}

const referralRequestPrices = ({
  classes,
  referralRequestID,
  hintMainCPT,
  viewOnly,
}: ReferralRequestPricesProps) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [existingSelections, setExistingSelections] = useState<
    ReferralPriceBundle[]
  >([])

  useEffect(() => {
    loadExisting()
  }, [])

  const loadExisting = () => {
    getReferralRequestPrices({ requestID: referralRequestID })
      .then((res: any) => {
        setExistingSelections(_.get(res, 'Data', []))
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  const closeDialog = () => {
    setOpenDialog(false)
  }

  const onSave = () => {
    closeDialog()
    loadExisting()
  }

  const addButton = viewOnly ? null : (
    <Button
      variant="contained"
      color="primary"
      startIcon={<Edit />}
      onClick={() => {
        setOpenDialog(true)
      }}>
      Manage Attached Bundles
    </Button>
  )

  return (
    <div>
      <div>
        {existingSelections.map((pr: ReferralPriceBundle) => {
          return (
            <Chip
              className={classes.chipper}
              key={pr.ID}
              label={pr.CostKey.Code}
              color="primary"
              variant="outlined"
            />
          )
        })}
      </div>
      {addButton}
      {openDialog ? (
        <ReferralRequestPricesDialog
          referralRequestID={referralRequestID}
          existingSelections={existingSelections}
          onSave={onSave}
          hintMainCPT={hintMainCPT}
          open={true}
          closeDialog={closeDialog}
        />
      ) : null}
    </div>
  )
}

export const ReferralRequestPrices = withStyles(styles)(referralRequestPrices)
