import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import { FileActions } from '../../actions'
import { postFileResponse, REFERRAL_FILE_TYPE } from '../FileUpload/types'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import styled from 'styled-components'
import FileUploadModal from '../Files/FileUploadModal'
import SwitchField from '../SwitchField'
import { ReferralForm } from './types'
import { put } from '../../services/ApiService'

const { saveNewReferralFile } = FileActions

const IconWarning = styled(WarningIcon)`
  margin-right: 10px;
`

const WarningText = styled(Typography)`
  flex: 1;
`

const ContainerGrid = styled(Grid)`
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid black;
`

interface Props {
  form: ReferralForm
  goBack: () => void
}

const ReferrerActionsBox = ({ form, goBack }: Props) => {
  const { showForDuration: showSnackbar } = useSnackbar()

  const id = form.data.ID

  const saveFile = (file: any) => {
    return new Promise<postFileResponse>((resolve, reject) => {
      if (id) {
        const body = { ...file, ReferralID: Number(id), FileID: 0 }
        saveNewReferralFile({ refId: id, body })
          .then((res: any) => {
            resolve(res.Data as postFileResponse)
          })
          .catch((err: any) => {
            reject(err)
          })
      } else {
        reject('No referral ID')
      }
    })
  }

  const saveIsUrgent = (val: boolean) => {
    put(`/referral/${id}/urgent`, '{"IsUrgent": ' + val + '}')
      .then(() => {
        showSnackbar('Success', SnackbarTypeSuccess, 3000)
        form.setters.IsUrgent(val)
      })
      .catch(() => {
        showSnackbar(
          'Something went wrong. Please Contact support.',
          SnackbarTypeError,
          8000
        )
      })
  }

  const handleFileSave = () => {
    showSnackbar('New File Added Successfully', SnackbarTypeSuccess, 3000)
    goBack()
  }

  if (!id) return null
  return (
    <ContainerGrid container direction="row" alignItems="center">
      <IconWarning color="primary" fontSize="large" />
      <WarningText>
        This referral can no longer be updated. Actions that are now permitted
        include:
      </WarningText>
      <div style={{ marginLeft: '1rem' }}>
        <SwitchField
          label="Urgent"
          value={!!form.data.IsUrgent}
          onChange={saveIsUrgent}
        />
      </div>
      <FileUploadModal
        FileUploadProps={{
          apiPostFile: saveFile,
          autoUniqueName: true,
          defaultFileData: { FileTypeID: REFERRAL_FILE_TYPE },
        }}
        disabled={false}
      />
    </ContainerGrid>
  )
}

export default ReferrerActionsBox
