import React, { useEffect, useRef, useState } from 'react'
import {
  DataFunc,
  Mention,
  MentionProps,
  MentionsInput,
  SuggestionDataItem,
} from 'react-mentions'
import { Button, Typography } from '@material-ui/core'
import { Send } from '@material-ui/icons'
import { Variant } from '@material-ui/core/styles/createTypography'
import { ConversationUser } from '../Conversations/types'
import styled from 'styled-components'

const MENTION_IDENTIFIER = '@[&__display__&](&__id__&)'
const MENTION_ANCHOR = '@[&'

// Custom mention-er component styles
const StyledMentionerInput = styled.div`
  --shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); // matches MUI paper's box shadow
  --radius: 5px;
  --highlight-color: #c9eaff;

  .custom-mention {
    min-height: 4lh;
    display: flex;
    padding: 0.5rem;
    background: #f5f5f5;
    box-shadow: var(--shadow);
    border-radius: var(--radius);
    line-height: 1.4rem;

    * {
      line-height: inherit !important;
    }

    .custom-mention__control {
      flex: 1;
      position: relative;

      .custom-mention__mention {
        color: transparent;
        background: var(--highlight-color);
        border-radius: var(--radius);
        top: 2px;
        position: relative;
        padding-right: 4px;
      }

      textarea {
        border: none;
        outline: none;
      }
    }

    .custom-mention__suggestions {
      top: 100% !important;
      margin-top: 0.15rem !important;
      width: 100%;
      font-size: 0.875rem;
      background: rgba(233, 233, 233, 0.85) !important;
      backdrop-filter: blur(3px);
      border-radius: var(--radius);
      box-shadow: var(--shadow);
      max-height: 50vh;
      overflow: hidden;
      overflow-y: auto;

      * {
        font-size: inherit !important;
      }

      .custom-mention__suggestions__item {
        padding: 8px;
        display: block;

        &:hover,
        &[class*='--focused'] {
          background: var(--highlight-color);
        }
      }
    }
  }

  .btn-send-wrap {
    text-align: right;
    padding: 0.5rem 0;
  }
`

const StyledMessageDisplay = styled.div`
  .mentioned-user {
    color: #3f51b5; //#c9eaff;
    text-decoration: underline;
  }
`

interface MentionDisplayerProps {
  content: string
  variant?: Variant
}

export const MentionDisplayer = ({
  content = '',
  variant = 'body1',
}: MentionDisplayerProps) => {
  const parsed = parseMentions(content)
  return (
    <StyledMessageDisplay>
      <Typography variant={variant}>{parsed.Display}</Typography>
    </StyledMessageDisplay>
  )
}

/*
 * Display: What is used for display in an html format, with highlighted mentions.
 * Content: The parsed, text based content of a message, sans markup.
 * Raw: The original content of a message that includes anchors for mentions
 * UserIDs: The ids of users mentioned in a message
 */
export const parseMentions = (text: string) => {
  const userIds: number[] = []
  const content: string[] = []
  if (text && text.indexOf(MENTION_ANCHOR) !== -1) {
    const parts = text.split(MENTION_ANCHOR)

    const replaced = parts.map((part: string) => {
      /* check to see if the part has the actual mention in it */
      if (part.indexOf('&](&') !== -1) {
        const mentionName: string = part.substring(0, part.indexOf('&](&'))
        const userId: number = Number(
          part.substring(part.indexOf('(&') + 2, part.indexOf('&)'))
        ).valueOf()
        const trailingWords: string = part.substring(
          part.indexOf('&)') + 2,
          part.length
        )

        userIds.push(userId)
        content.push(mentionName)
        content.push(trailingWords)
        return (
          <span key={part}>
            <span className="mentioned-user">{mentionName}</span>
            <span>{trailingWords}</span>
          </span>
        )
      }

      content.push(part)
      return <span key={part}>{part}</span>
    })

    return {
      Content: content.join(''),
      display: replaced,
      Display: replaced,
      Raw: text,
      UserIDs: userIds,
    }
  }

  return {
    Content: text,
    display: text,
    Display: text,
    Raw: text,
    UserIDs: userIds,
  }
}

interface MentionTextAreaProps {
  active: boolean
  handleSend: () => void
  initialValue?: string
  data: ConversationUser[]
  placeholder?: string
  onChange?: (x: string) => void
}

export default function MentionTextArea({
  active = true,
  handleSend = () =>
    console.debug(
      'You need to specify a method to handleSend in MentionTextArea'
    ),
  initialValue = '',
  data,
  placeholder,
  onChange,
}: MentionTextAreaProps) {
  const _input = useRef()
  const [inputValue, setInputValue] = useState<string>(initialValue)
  const [formattedUsers, setFormattedUsers] = useState<
    SuggestionDataItem[] | DataFunc
  >([])

  useEffect(() => {
    let users: SuggestionDataItem[] | DataFunc = formatUsers(data)
    setFormattedUsers(users)
  }, [data])

  const formatUsers = (users: ConversationUser[]) => {
    if (!users || users.length === 0) return []
    return users.map((user: ConversationUser) => {
      return {
        id: user.ID,
        display: `${user.FirstName} ${user.LastName} (${user.Email})`,
      }
    })
  }

  const handleChange = ({ target }: { target: { value: string } }) => {
    setInputValue(target.value)
    if (onChange) {
      onChange(target.value)
    }
  }

  const renderSuggestion: MentionProps['renderSuggestion'] = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    return <Typography className="suggestion">{highlightedDisplay}</Typography>
  }

  const send = () => {
    setInputValue('')
    handleSend()
  }

  function isSendDisabled(): boolean {
    return (inputValue || '').length === 0
  }

  const trigger = active ? '@' : '++**' // a random esoteric trigger, an easter egg?

  return (
    <StyledMentionerInput>
      <MentionsInput
        className="custom-mention"
        value={inputValue}
        inputRef={_input.current}
        rows={3}
        placeholder={placeholder}
        onChange={handleChange}>
        <Mention
          trigger={trigger}
          className="custom-mention__mention"
          data={formattedUsers}
          renderSuggestion={renderSuggestion}
          appendSpaceOnAdd
          markup={MENTION_IDENTIFIER}
        />
      </MentionsInput>

      <div className="btn-send-wrap">
        <Button
          disabled={isSendDisabled()}
          type="button"
          variant="outlined"
          color="primary"
          aria-label="Send message"
          onClick={send}>
          Send&nbsp;
          <Send fontSize="small" />
        </Button>
      </div>
    </StyledMentionerInput>
  )
}
