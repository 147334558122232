import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

export const LoadingFormSection: React.FC = () => (
  <Grid container>
    <Grid item xs={6}>
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
    </Grid>
    <Grid item xs={6}>
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
    </Grid>
  </Grid>
)

export const LoadingSidebar = () => {
  return (
    <div>
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <div style={{ height: '40px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
      <Skeleton height="40px" width="95%" style={{ marginBottom: '10px' }} />
    </div>
  )
}
