import React from 'react'
import useApi from '../../hooks/useApi'
import { FileActions } from '../../actions'
import { AttachFile } from '@material-ui/icons'
import { Chip, Grid } from '@material-ui/core'
import { parseS3Key } from '../../utils'
import {
  postFileResponse,
  ReferralFile,
  ReferralInvoiceFile,
} from '../FileUpload/types'
import FileUploadModal from '../Files/FileUploadModal'
import styled from 'styled-components'

const { saveNewReferralFile, delRefFileErr, delRefFile, REFERRAL_FILE } =
  FileActions

const ChipGrid = styled(Grid)`
  margin-top: 5px;
`

interface Props {
  referralId?: number
  disabled?: boolean
}

const ReferralFileUpload: React.FC<Props> = ({
  referralId,
  disabled,
}: Props) => {
  const {
    data: refFiles,
    refetch,
    loading,
  } = useApi({
    route: `/referral/${referralId}/file`,
  })

  const { data: fileTypes } = useApi({
    route: `/file_types`,
  })

  if (!refFiles || !fileTypes || loading) return null
  const defaultFileData = Object.freeze({ FileTypeID: fileTypes.ref_file })

  const saveFile = (file: any) => {
    return new Promise<postFileResponse>((resolve, reject) => {
      if (referralId) {
        const body = {
          ReferralID: referralId,
          FileTypeID: fileTypes.ref_file,
          S3Key: parseS3Key(file.S3Key),
          Size: file.Size,
        }
        saveNewReferralFile({ refId: referralId, body })
          .then((res: any) => {
            resolve(res.Data as postFileResponse)
          })
          .catch((err: any) => {
            reject(err)
          })
      } else {
        reject('No referral ID')
      }
    })
  }

  const refreshFiles = () => {
    refetch({})
  }

  const handleFileClick = ({ ReferralID, FileID }: any) => {
    return (_e: any) => {
      window.open(
        `/download?id=${ReferralID}&type=${REFERRAL_FILE}&fileId=${FileID}`,
        '_blank'
      )
    }
  }

  const handleFileDelete = ({ ReferralID, FileID }: any) => {
    return (_e: any) => {
      delRefFile({ refId: ReferralID, id: FileID })
        .then(() => {
          refetch({})
        })
        .catch(delRefFileErr)
    }
  }

  const renderFiles = () => {
    if (refFiles && refFiles.length > 0) {
      return refFiles.map((file: ReferralFile | ReferralInvoiceFile) => {
        return (
          <ChipGrid container>
            <Chip
              key={file.S3Key}
              icon={<AttachFile />}
              clickable
              label={parseS3Key(file.S3Key)}
              onClick={handleFileClick(file)}
              onDelete={disabled ? undefined : handleFileDelete(file)}
              variant="outlined"
              color="primary"
            />
          </ChipGrid>
        )
      })
    }
  }

  return (
    <>
      <FileUploadModal
        FileUploadProps={{
          defaultFileData: defaultFileData,
          apiPostFile: saveFile,
          autoUniqueName: true,
        }}
        disabled={disabled}
        closeModal={refreshFiles}
      />
      {renderFiles()}
    </>
  )
}

export default ReferralFileUpload
