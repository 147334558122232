import React from 'react'
import useApi from '../../hooks/useApi'
import * as api from '../../services/ApiService'
import { AttachFile } from '@material-ui/icons'
import { Chip, Grid } from '@material-ui/core'
import { parseS3Key } from '../../utils'
import useSnackbar from '../../hooks/useSnackbar'
import FileUploadModal from '../Files/FileUploadModal'
import styled from 'styled-components'
import { postFileResponse } from '../FileUpload'

const ChipGrid = styled(Grid)`
  margin-top: 5px;
`

const REFERRAL_REQUEST_FILE = 'referral_request'

interface Props {
  referralRequestId?: number
  disabled?: boolean
}

const ReferralRequestFileUpload: React.FC<Props> = ({
  referralRequestId,
  disabled,
}) => {
  const { show: showSnackbar } = useSnackbar()
  const {
    data: refFiles,
    refetch,
    loading,
  } = useApi({
    route: `/referral_request/${referralRequestId}/file`,
  })
  const { data: fileTypes } = useApi({
    route: `/file_types`,
  })

  if (!refFiles || !fileTypes) return null

  const saveFile = (file: any): Promise<postFileResponse> => {
    return new Promise<postFileResponse>((resolve, reject) => {
      if (referralRequestId) {
        const body = {
          ReferralRequestID: referralRequestId,
          FileTypeID: fileTypes.referral_request_file,
          S3Key: parseS3Key(file.S3Key),
          Size: file.Size,
        }
        api
          .post(`/referral_request/${referralRequestId}/file`, body)
          .then((res: any) => {
            resolve(res.Data as postFileResponse)
          })
          .catch((err: any) => {
            reject(err)
          })
      } else {
        reject('No referral request ID')
      }
    })
  }

  const refreshFiles = () => {
    refetch({})
  }

  const handleFileClick = (props: any) => {
    const { ReferralRequestID, FileID } = props
    return (_e: any) => {
      window.open(
        `/download?id=${ReferralRequestID}&type=${REFERRAL_REQUEST_FILE}&fileId=${FileID}`,
        '_blank'
      )
    }
  }

  const handleFileDelete = (props: any) => {
    const { ReferralRequestID, FileID } = props
    return (_e: any) => {
      api
        .del(`/referral_request/${ReferralRequestID}/file/${FileID}`)
        .then(() => {
          refetch({})
        })
        .catch((err: any) => {
          if (
            !(
              err.Error &&
              err.Error.Message &&
              err.Error.Message.startsWith('User declined confirmation')
            )
          ) {
            showSnackbar(
              'Error deleting file. Please contact your ZERO administrator.',
              'error'
            )
            console.warn('Error deleting referral request file', err)
          }
        })
    }
  }

  if (!refFiles || loading) return null

  const renderFiles = () => {
    if (refFiles && refFiles.length > 0) {
      return refFiles.map((file: any) => {
        return (
          <ChipGrid container>
            <Chip
              key={file.S3Key}
              icon={<AttachFile />}
              clickable
              label={parseS3Key(file.S3Key)}
              onClick={handleFileClick(file)}
              onDelete={disabled ? undefined : handleFileDelete(file)}
              variant="outlined"
              color="primary"
            />
          </ChipGrid>
        )
      })
    }
  }

  return (
    <>
      <FileUploadModal
        FileUploadProps={{
          onSuccess: refreshFiles,
          defaultFileData: { FileTypeID: fileTypes.referral_request_file },
          apiPostFile: saveFile,
          autoUniqueName: true,
        }}
        disabled={disabled}
      />
      {renderFiles()}
    </>
  )
}

export default ReferralRequestFileUpload
