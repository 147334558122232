import * as api from '../services/ApiService'
import { getErrorMsg } from './utils'
import { errors } from '../utils'

export const GET_RECENT_ACTIVITY_SUCCESS = 'GET_RECENT_ACTIVITY_SUCCESS'
export const GET_RECENT_ACTIVITY_ERROR = 'GET_RECENT_ACTIVITY_ERROR'
export const GET_RECENT_ACTIVITY = 'GET_RECENT_ACTIVITY'
export const GET_REFERRAL_ACTIVITY_SUCCESS = 'GET_REFERRAL_ACTIVITY_SUCCESS'
export const GET_REFERRAL_ACTIVITY_ERROR = 'GET_REFERRAL_ACTIVITY_ERROR'
export const GET_ACTIVITIES_NEEDS_REVIEW_ERROR =
  'GET_ACTIVITIES_NEEDS_REVIEW_ERROR'
export const GET_ACTIVITIES_NEEDS_REVIEW = 'GET_ACTIVITIES_NEEDS_REVIEW'
export const GET_ACTIVITIES_NEEDS_REVIEW_SUCCESS =
  'GET_ACTIVITIES_NEEDS_REVIEW_SUCCESS'
export const ACK_ACTIVITY_SUCCESS = 'ACK_ACTIVITY_SUCCESS'
export const ACK_ACTIVITY_ERROR = 'ACK_ACTIVITY_ERROR'
export const REFERRAL_CREATED = 1
export const REFERRAL_UPDATED = 2
export const REFERRAL_NEW_MSG = 3
export const REFERRAL_FILE_ADDED = 4
export const REFERRAL_REQUEST_CREATED = 5
export const REFERRAL_REQUEST_UPDATED = 6
export const REFERRAL_REQUEST_CANCELLED = 7
export const REFERRAL_CREATED_FROM_REQUEST = 8
export const REFERRAL_REQUEST_FILE_ADDED = 9

export const getRecentActivity = () => {
  return api.get('/activity/recent')
}

export const getRecentActivitySuccess = (data: any) => {
  return {
    type: GET_RECENT_ACTIVITY_SUCCESS,
    payload: data.Data,
  }
}

export const getRecentActivityError = (err: any) => {
  const RequestId = err.Meta && err.Meta.RequestId ? err.Meta.RequestId : ''
  let errMsg = `There was a problem fetching recent activity, please try again or contact your administrator with this code: ${RequestId}`

  if (errors.IsForbidden(err)) {
    errMsg =
      'You do not have access to view Recent Activity, please contact your administrator or support@zero.health.'
  }

  return {
    type: GET_RECENT_ACTIVITY_ERROR,
    payload: getErrorMsg(err),
    meta: {
      notification: {
        body: errMsg,
        level: 'error',
      },
    },
  }
}

export const getReferralActivity = (params: any) => {
  const { id } = params
  return api.get(`/referral/${id}/activity`)
}

export const getReferralActivitySuccess = (data: any) => {
  return {
    type: GET_REFERRAL_ACTIVITY_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralActivityError = (err: any) => {
  const RequestId = err.Meta && err.Meta.RequestId ? err.Meta.RequestId : ''
  let errMsg = `There was a problem fetching the activity for this referral, please try again or contact your administrator with this code: ${RequestId}`

  if (errors.IsForbidden(err)) {
    errMsg =
      'You do not have access to view activity for this referral, please contact your administrator or support@zero.health.'
  }

  return {
    type: GET_REFERRAL_ACTIVITY_ERROR,
    payload: getErrorMsg(err),
    meta: {
      notification: {
        body: errMsg,
        level: 'error',
      },
    },
  }
}

export const getActivitiesNeedsReview = () => {
  return api.get('/activity/needs_review')
}

export const getActivitiesNeedsReviewSuccess = (data: any) => {
  return {
    type: GET_ACTIVITIES_NEEDS_REVIEW_SUCCESS,
    payload: data.Data,
  }
}

export const getActivitiesNeedsReviewError = (err: any) => {
  const RequestId = err.Meta && err.Meta.RequestId ? err.Meta.RequestId : ''
  let errMsg = `There was a problem fetching data for the Needs Review list, please try again or contact your administrator with this code: ${RequestId}`

  if (errors.IsForbidden(err)) {
    errMsg =
      'You do not have access to view activities that need review, please contact your administrator or support@zero.health.'
  }

  return {
    type: GET_ACTIVITIES_NEEDS_REVIEW_ERROR,
    payload: getErrorMsg(err),
    meta: {
      notification: {
        body: errMsg,
        level: 'error',
      },
    },
  }
}

export const ackActivity = (params: any) => {
  const { id } = params

  return api.put(`/activity/${id}/acknowledge`)
}

export const ackActivitySuccess = (data: any) => {
  return {
    type: ACK_ACTIVITY_SUCCESS,
    payload: data.Data,
  }
}

export const ackActivityError = (err: any) => {
  return {
    type: ACK_ACTIVITY_ERROR,
    payload: getErrorMsg(err),
    meta: {
      notification: {
        body: 'Someone has already acknowledged this activity, please try refreshing the page or wait for it to disappear.',
        level: 'error',
      },
    },
  }
}
