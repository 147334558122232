import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { Error, Warning } from '@material-ui/icons'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

const styles: any = () => {
  return {
    warningIcon: {
      color: '#F9B418',
    },
    errorIcon: {
      color: 'red',
    },
    banner: {
      display: 'inline-block',
      marginLeft: '5px',
      position: 'relative',
      top: '-7px',
    },
    container: {
      marginTop: -20,
      padding: 10,
    },
  }
}

interface AgingActivitiesWarningBannerProps {
  weekOld: number
  monthOld: number
  classes: ClassNameMap<string>
}

const agingActivitiesWarningBanner = ({
  weekOld = 0,
  monthOld = 0,
  classes,
}: AgingActivitiesWarningBannerProps) => {
  const [moreInfoOpen, setMoreInfoOpen] = useState(false)

  const onModalClose = () => {
    setMoreInfoOpen(false)
  }

  const showMoreInfo = () => {
    setMoreInfoOpen(true)
  }

  const renderMoreInfo = () => {
    return (
      <div>
        <Dialog open={moreInfoOpen} onClose={() => onModalClose()}>
          <DialogTitle>Needs Review</DialogTitle>
          <DialogContent>
            <Typography>
              This is the most important part of the dashboard. You can treat{' '}
              <strong>Needs Review</strong> like a checklist. Any time a
              referral is created or a message sent, it will show up here. When
              you read a message that is intended for you or see a referral come
              in, you should acknowledge the activity here or in the referral
              details. Ideally, you should strive to{' '}
              <strong>keep this list clear of old activities</strong> so that it
              is easy to see new messages and referrals coming in.{' '}
              <p>
                <strong>Please note:</strong> Depending on how your company
                works, you may see some activities here that are meant for
                others. If you acknowledge an activity that is meant for someone
                else, it will disappear from their list, as well as yours and
                they may never get notified.
              </p>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => onModalClose()} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      {(weekOld !== 0 || monthOld !== 0) && (
        <div>
          {weekOld > 0 && (
            <div>
              <Warning className={classes.warningIcon} />
              <Typography className={classes.banner}>
                You have <strong>{weekOld} activities</strong> that are more
                than a week old
              </Typography>
            </div>
          )}
          {monthOld > 0 && (
            <div>
              <Error className={classes.errorIcon} />
              <Typography className={classes.banner}>
                <strong>
                  You have {monthOld} activities that are more than a month old
                </strong>
              </Typography>
            </div>
          )}
          <Typography>
            Please use the <strong>Acknowledge button</strong> to clear
            activities out of this list. {/* eslint-disable-next-line */}
            <a href="#" className="App-link" onClick={() => showMoreInfo()}>
              How this works.
            </a>
          </Typography>
        </div>
      )}
      {moreInfoOpen ? renderMoreInfo() : null}
    </div>
  )
}

export const AgingActivitiesWarningBanner = withStyles(styles)(
  agingActivitiesWarningBanner
)
