import React, { useState } from 'react'
import DataTable from '../DataTable'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import moment from 'moment'
import { DateInput } from '../Inputs'
import { defaultDateFormatter, getIds } from '../../utils'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import { InvoiceLineAdd } from '../InvoiceLineAdd'
// @ts-ignore
import _ from 'lodash'
import { ReferralInvoiceActions } from '../../actions'
import { DiagnosisPointerInput } from '../InvoiceLineAdd/DiagnosisPointerInput'

const { deleteReferralInvoiceLine, putReferralInvoiceLine } =
  ReferralInvoiceActions
export const InvoiceLineTable = ({
  total,
  practiceFacilityID,
  referralInvoiceID,
  lines,
  isEditable,
  dateOfService,
}: any) => {
  const [currentLines, updateCurrentLines] = useState(lines)
  const [checkedItems, setCheckedItems] = useState([])
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const { show: showSnackbar } = useSnackbar()

  // @todo-refactor
  // we need to get rid of the derived state pattern here with lines and currentLines,
  // would be better to use lines as the state and refetch after updating.
  // for now this will work
  React.useEffect(() => {
    updateCurrentLines(lines)
  }, [lines])

  // Columns for the invoice line table
  const columns: any = {
    CptCode: {
      name: 'Cpt Code',
      details: { options: { width: '100px' } },
    },
    Cost: {
      name: 'Cost',
      details: { dataFormat: (cell: any, _row: any) => `$${cell}` },
    },
    DateOfService: {
      name: 'Date Of Service',
      details: { dataFormat: defaultDateFormatter },
    },
    Description: {
      name: 'Description',
    },
    DiagnosisPointer: {
      name: 'Diagnosis Pointer',
    },
    PlaceOfServiceCode: {
      name: 'POS Code',
    },
    Notes: {
      name: 'Notes',
    },
    Units: {
      name: 'Units',
      details: {
        dataFormat: (_: any, row: any) => row.Units || '1',
      },
    },
    CPTModifierCode: {
      name: 'CPT Modifier',
    },
    DRGCode: {
      name: 'DRG',
    },
    LinkedBundle: {
      name: 'Linked Bundle',
      details: {
        dataFormat: (_col: any, row: any) => {
          // Return check mark if this row has a linked bundle
          if (row.FeeSchedulePriceID) {
            return <CheckIcon />
          }
        },
        align: 'center',
      },
    },
  }

  const nonInvoicedColumns: any = _.assign({}, columns, {
    CptCode: {
      ...columns.CptCode,
      details: {
        ...columns.CptCode.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: {
          style: { width: 100 },
          inputProps: { maxlength: 5 },
        },
        validators: {
          Length: {
            isValid: (val: string) => {
              return val.length === 5
            },
            msg: 'CPT Code must be exactly 5 digits',
          },
        },
      },
    },
    Cost: {
      ...columns.Cost,
      details: {
        ...columns.Cost.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: { style: { width: 100 } },
      },
    },
    DateOfService: {
      ...columns.DateOfService,
      details: {
        ...columns.DateOfService.details,
        editable: true,
        EditComponent: DateInput,
        editComponentOpts: { style: { width: 100 } },
        validators: {
          NotInFuture: {
            isValid: (val: string) => {
              return !moment(val).isAfter(moment())
            },
            msg: 'Date of Service cannot be in the future',
          },
        },
      },
    },
    Description: {
      ...columns.Description,
      details: {
        ...columns.Description.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: { style: { width: 100 } },
      },
    },
    DiagnosisPointer: {
      ...columns.DiagnosisPointer,
      details: {
        ...columns.DiagnosisPointer.details,
        editable: true,
        EditComponent: DiagnosisPointerInput,
        editComponentOpts: {
          style: { width: 100 },
          inputProps: { maxlength: 2 },
        },
      },
    },
    PlaceOfServiceCode: {
      ...columns.PlaceOfServiceCode,
      details: {
        ...columns.PlaceOfServiceCode.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: {
          style: { width: 100 },
          inputProps: { maxlength: 2 },
        },
        validators: {
          ValidCode: {
            isValid: (val: string) => {
              return (
                val === '' || ['11', '21', '22', '23', '24', '81'].includes(val)
              )
            },
            msg: 'POS Code can only be 11, 21, 22, 23, 24, 81 or left blank',
          },
        },
      },
    },
    Notes: {
      ...columns.Notes,
      details: {
        ...columns.Notes.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: { style: { width: 150 } },
      },
    },
    Units: {
      ...columns.Units,
      details: {
        ...columns.Units.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: { style: { width: 80 } },
      },
    },
    CPTModifierCode: {
      ...columns.CPTModifierCode,
      details: {
        ...columns.CPTModifierCode.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: { style: { width: 80 } },
      },
    },
    DRGCode: {
      ...columns.DRGCode,
      details: {
        ...columns.DRGCode.details,
        editable: true,
        EditComponent: TextField,
        editComponentOpts: { style: { width: 80 } },
      },
    },
  })

  const columnsToUse = isEditable ? nonInvoicedColumns : columns

  const handleDeleteLines = () => {
    deleteLines({ lineIds: getIds(checkedItems), referralInvoiceID })
  }

  interface DeleteLinesParams {
    lineIds: Array<number>
    referralInvoiceID: number
  }

  const deleteLines = ({ lineIds, referralInvoiceID }: DeleteLinesParams) => {
    // this is golden...
    const successes: Array<number> = []
    const delErrors: any = []
    if (lineIds && lineIds.length > 0) {
      lineIds.forEach((lineId: any) => {
        deleteReferralInvoiceLine(referralInvoiceID, lineId)
          .then((_res: any) => {
            // @ts-ignore
            successes.push(lineId)
            // @ts-ignore
            if (successes.length + delErrors.length === lineIds.length) {
              setConfirmDeleteOpen(false)
              if (delErrors.length > 0) {
                showSnackbar(
                  'There was a problem deleting 1 or more invoice lines. Please refresh and try again or contact support.',
                  SnackbarTypeError
                )
                removeLines(successes)
              } else {
                showSnackbar('Lines deleted successfully', SnackbarTypeSuccess)
                setCheckedItems([])
                removeLines(successes)
              }
            }
          })
          .catch((err: any) => {
            delErrors.push(err)
            if (successes.length + delErrors.length === lineIds.length) {
              removeLines(successes)
              setConfirmDeleteOpen(false)
              showSnackbar(
                'There was a problem deleting 1 or more invoice lines. Please refresh and try again or contact support.',
                SnackbarTypeError
              )
            }
          })
      })
    }
  }

  const removeLines = (ids: number[]) => {
    if (ids && ids.length > 0) {
      const localLines = currentLines
      ids.forEach((id) => {
        _.remove(localLines, (line: any) => {
          return line.ID === id
        })
      })
      updateCurrentLines([...localLines])
    }
  }

  const replaceLine = (updatedLine: any) => {
    const index = _.findIndex(currentLines, (line: any) => {
      return line.ID === updatedLine.ID
    })
    const updatedLines = [...currentLines]
    updatedLines[index] = updatedLine
    updateCurrentLines([...updatedLines])
  }

  const handleCheckedChanged = (checkedRows: any) => {
    setCheckedItems(checkedRows)
  }

  const handleEdit = (_row: any, _rowKey: any) => {}

  const handleSaveRow = (row: any) => {
    let rowToSave = {
      ...row,
    }

    // Properly format date for API
    if (row.DateOfService && moment(row.DateOfService).isValid()) {
      rowToSave = {
        ...row,
        DateOfService: moment(row.DateOfService).format('YYYY-MM-DD'),
      }
    }

    // Ensure type is numeric for number input for units, if it has a value set
    rowToSave.Units = !!row.Units ? +rowToSave.Units : null

    return putReferralInvoiceLine(referralInvoiceID, row.ID, rowToSave)
      .then((res: any) => {
        // @ts-ignore
        replaceLine(res.Data)
        showSnackbar('Invoice line updated successfully!', SnackbarTypeSuccess)
      })
      .catch((err: any) => {
        if (err.Error && err.Error[0]) {
          showSnackbar(err.Error[0], SnackbarTypeError)
        } else {
          showSnackbar(
            'There was an issue updating the Invoice line. Please try again or contact us for support.',
            SnackbarTypeError
          )
        }
      })
  }

  const onCancelDialog = () => {
    setConfirmDeleteOpen(false)
  }

  const renderConfirmDelete = () => {
    return (
      <div>
        <Dialog open={confirmDeleteOpen} onClose={onCancelDialog}>
          <DialogTitle>Delete Lines(s)</DialogTitle>
          <DialogContent>
            <Typography>
              Please confirm you would like to delete the following lines:
            </Typography>
            <div>
              <List>
                {checkedItems.map((line: any) => {
                  return (
                    <ListItem>
                      {line.CptCode} - {line.Cost}
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteLines} color="primary">
              Confirm
            </Button>
            <Button onClick={onCancelDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  // Function to pass down to the data table that determines if a row is editable
  const isRowEditable = (row: any) => {
    if (row.FeeSchedulePriceID) {
      return false
    }

    return true
  }

  const Total = () => {
    let total = 0
    if (currentLines && currentLines.length > 0) {
      currentLines.forEach((l: any) => {
        total += Number(l.Cost)
      })
    }
    return <span>Invoice Total: ${total.toFixed(2)}</span>
  }

  return (
    <>
      <InvoiceLineAdd
        PracticeFacilityID={practiceFacilityID}
        referralInvoiceID={referralInvoiceID}
        dateOfService={dateOfService}
        enabled={isEditable}
        onSuccess={(line: any) => {
          const newArray = [line, ...currentLines]
          updateCurrentLines(newArray)
        }}
      />
      <DataTable
        data={currentLines}
        columns={columnsToUse}
        keyProp={'ID'}
        getError={''}
        allowEditing={isEditable}
        onEditRow={handleEdit}
        checkHandler={handleCheckedChanged}
        onSaveRow={handleSaveRow}
        count={currentLines.length}
        initPage={1}
        isRowEditable={isRowEditable}
        sortable={undefined}
        onChangePage={() => {}}
        onChangeRowsPerPage={() => {}}
        initPageSize={10}
        LeftFooterItems={<Total />}
      />
      {checkedItems.length > 0 && isEditable && (
        <Button
          onClick={() => setConfirmDeleteOpen(true)}
          variant="contained"
          color="secondary">
          Delete Lines
        </Button>
      )}
      {renderConfirmDelete()}
    </>
  )
}
