import React from 'react'
import { Typography } from '@material-ui/core'
import { ReferralPrices } from '../ReferralPrices'
import useApi from '../../hooks/useApi'
import { Referral } from '../ReferralForm/types'

interface Props {
  ID?: number | null
  viewOnly?: boolean
}

const Message = () => {
  return (
    <Typography variant="caption">
      Before attaching bundles, the referral must be saved with a Practice
      Facility and Service Date.
    </Typography>
  )
}

const AttachedBundles: React.FC<Props> = (props) => {
  // cannot use form data here, we need the referral state from server
  const { data }: { data: { Referral: Referral } } = useApi(
    props.ID
      ? {
          route: `/referral/${props.ID}`,
        }
      : undefined
  )

  const canManageBundles =
    data && data.Referral.ID && data.Referral.PracticeFacilityID

  if (!canManageBundles) {
    return <Message />
  }

  return (
    <ReferralPrices
      referralId={data.Referral.ID as number}
      hintMainCPT={data.Referral.MainCptCode}
      viewOnly={props.viewOnly}
    />
  )
}

export default AttachedBundles
