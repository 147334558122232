import React from 'react'

type AnyObject = { [key: string]: any }

export type Validators<T> = {
  [key in keyof Partial<T>]: {
    isValid: (val: T[key], data?: T) => boolean
    msg: string
  }
}

type Setters<T> = {
  [key in keyof T]: (val: NonNullable<T[key]>) => void
}

type Errors<T> = {
  [key in keyof T]: string | null
}

export default function useForm<T extends AnyObject>(
  initialData: T,
  validator?: Validators<T>
) {
  const [data, setData] = React.useState<T>(initialData)

  const setField = (field: string) => (val: any) => {
    setData({ ...data, [field]: val })
  }

  const fields: Array<keyof T> = Object.keys(initialData)
  let setters: Setters<T> = {} as T
  let errors: Errors<T> = {} as T

  fields.forEach((field) => {
    const val = initialData[field]
    const valType = typeof val

    // does not support nested objects
    if (!!val && !Array.isArray(val) && valType === 'object') {
      console.debug(`useForm hook does not support value for field: ${field}`)
      return
    }

    setters[field] = (val: any) => {
      setData({ ...data, [field]: val })
    }
  })

  fields.forEach((field) => {
    if (!validator || !validator[field]) return
    const val = data[field]
    const validation = validator[field]
    const err = validation.isValid(val, data) ? null : validation.msg
    errors[field] = err
  })

  const reset = () => setData(initialData)

  const isValid = () => {
    if (!validator) return true
    const validity = Object.keys(validator).map((key) =>
      validator[key].isValid(data[key], data)
    )
    return validity.includes(false) ? false : true
  }

  return {
    data,
    setData,
    setField,
    setters,
    errors,
    reset,
    isValid,
  }
}
