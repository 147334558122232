import React, { useEffect, useState } from 'react'
import AutocompleteField from '../AutocompleteField'
import { Grid, Tab, Tabs } from '@material-ui/core'
import ReportDisplay from './reportDisplay'
import * as reportActions from '../../actions/ReportActions'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'

export default function ReferrerReports() {
  const [autoCompleteReferrerList, setAutoCompleteReferrerList] = useState([])
  const [selectedReferrerID, setSelectedReferrerID] = useState<number | null>(
    null
  )
  const [selectedReportHandle, setSelectedReportHandle] = useState<
    string | null
  >(null)
  const [reportList, setReportList] = useState([])
  const [reportURL, setReportURL] = useState<string | null>(null)
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    setSelectedReportHandle(null)
    setReportList([])
    setReportURL(null)
    loadReferrerList()
  }, [])

  useEffect(() => {
    if (!selectedReferrerID) return
    setSelectedReportHandle(null)
    setReportList([])
    setReportURL(null)
    loadReportsByReferrerID(selectedReferrerID)
  }, [selectedReferrerID])

  useEffect(() => {
    if (!selectedReferrerID || !selectedReportHandle) {
      setReportURL(null)
      return
    }
    loadReportData(selectedReferrerID, selectedReportHandle)
  }, [selectedReferrerID, selectedReportHandle])

  function loadReferrerList() {
    return reportActions
      .getReportableReferrers()
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setAutoCompleteReferrerList(
          res.Data.map((rfr: any) => ({
            name: rfr.ReferrerName,
            value: rfr.ReferrerID,
          }))
        )
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar('Failed loading list of referrers', SnackbarTypeError)
      })
  }

  function loadReportsByReferrerID(rfrID: number) {
    return reportActions
      .getReportsByReferrerID(rfrID)
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setReportList(res.Data)
        setSelectedReportHandle(res.Data[0].Handle)
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar(
          'Failed loading reports for selected referrer',
          SnackbarTypeError
        )
      })
  }

  function loadReportData(rfrID: number, reportHandle: string) {
    return reportActions
      .getReferrerReport(rfrID, reportHandle)
      .then((res: { Data?: any } & Partial<any>) => {
        if (res.error) throw res
        setReportURL(res.Data.URL)
      })
      .catch((e: { Error: string[]; error: boolean }) => {
        showSnackbar('Failed loading report', SnackbarTypeError)
      })
  }

  function onClickReport(_: any, value: string) {
    setSelectedReportHandle(value)
  }

  const tabsVisible =
    !!selectedReferrerID &&
    !!selectedReportHandle &&
    (reportList || []).length > 0

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <AutocompleteField
            label="Referrer"
            value={selectedReferrerID}
            onChange={(orgID: any) => {
              setSelectedReportHandle(null)
              setSelectedReferrerID(orgID || null)
            }}
            options={autoCompleteReferrerList}
            PassthroughProps={{
              defaultValue: null,
              getOptionSelected: (v: any): boolean =>
                +v.value === selectedReferrerID,
            }}
          />
        </Grid>
        <Grid item>
          {tabsVisible && (
            <Tabs
              value={selectedReportHandle}
              onChange={onClickReport}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto">
              {reportList.map((r: any) => {
                return <Tab key={r.Handle} value={r.Handle} label={r.Name} />
              })}
            </Tabs>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {reportURL && <ReportDisplay reportURL={reportURL} />}
        </Grid>
      </Grid>
    </>
  )
}
