import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
} from '@material-ui/core'
import { Check, Clear, Delete as DeleteIcon } from '@material-ui/icons'
import { nameFormatter } from '../utils'
import { deleteUser, upsertPermission } from '../services/UserManagementService'
import * as T from '../components/UserManagement/types'

const ActiveIndicator: React.FC<{ active: boolean }> = ({ active }) => {
  if (active) {
    return <Check style={{ color: 'green' }} />
  }
  return <Clear style={{ color: 'firebrick' }} />
}

interface PFChipsProps {
  practiceFacilities: { Name: string; truncated?: boolean }[]
}

const TruncatedChips: React.FC<PFChipsProps> = ({ practiceFacilities }) => {
  const limit = 3
  const facilities =
    practiceFacilities.length > limit
      ? practiceFacilities.slice(0, limit).concat([
          {
            Name: `and ${practiceFacilities.length - limit} more...`,
            truncated: true,
          },
        ])
      : practiceFacilities

  return (
    <>
      {facilities.map((pf: any) => (
        <Chip
          key={pf.Name}
          size="small"
          label={pf.Name}
          style={{
            marginRight: '5px',
            marginBottom: '5px',
            backgroundColor: pf.truncated ? 'transparent' : 'whitesmoke',
          }}
        />
      ))}
    </>
  )
}

const ConfirmDeleteDialog: React.FC<{
  open: boolean
  onClose: (evt: any) => void
  onConfirm: (evt: any) => void
  userFullName: string
}> = ({ open, onClose, onConfirm, userFullName }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm User Deletion</DialogTitle>
      <DialogContent>
        This action cannot be undone, please confirm that you would like to
        delete {userFullName}.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const DeleteButton: React.FC<{
  userId: number
  userFullName: string
  refetch: Function
}> = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const params: any = useParams()

  const onClose = (evt: any) => {
    // prevent the parent row click
    // see DataTable _onRowClick
    evt.currentTarget.preventRowClick = true
    evt.stopPropagation()
    setDialogOpen(false)
  }

  return (
    <>
      <Button
        size="small"
        color="primary"
        startIcon={<DeleteIcon style={{ color: 'firebrick' }} />}
        onClick={(evt) => {
          evt.stopPropagation()
          setDialogOpen(true)
        }}
        style={{ color: 'firebrick', zIndex: 100 }}>
        Delete
      </Button>
      <ConfirmDeleteDialog
        userFullName={props.userFullName}
        open={dialogOpen}
        onClose={onClose}
        onConfirm={async (evt: any) => {
          evt.stopPropagation()
          await deleteUser(params.organizationId, props.userId)
          props.refetch()
          onClose(evt)
        }}
      />
    </>
  )
}

const createUpsertHandler = (
  row: any,
  perm: string,
  refetchPermissions: Function,
  notify: Function
) => {
  return async () => {
    const current = row[perm]
    const flipped = !row[perm]

    // eager toggling for ux performance
    row[perm] = flipped
    try {
      await upsertPermission(row.ID, {
        ...row,
        [perm]: flipped,
      })
      refetchPermissions()
    } catch (err) {
      row[perm] = current
      notify('Error updating permission')
      console.warn('Error updating permission', err)
    }
  }
}

const notificationText = ['Off', 'Instant', 'Nightly']
export const UserManagementModel = (refetchUsers: Function) => ({
  Name: {
    name: 'Name',
    details: {
      // sortName: 'EmployerMember.Name',
      dataFormat: (_col: any, row: T.User) => {
        return nameFormatter(row.FirstName, row.LastName)
      },
    },
  },
  Email: {
    name: 'Email',
    details: {
      // sortName: 'Email',
    },
  },
  // IsActive: {
  //   name: 'Active',
  //   details: {
  //     // sortName: 'IsActive',
  //     dataFormat: (_col: any, row: T.User) => {
  //       return <ActiveIndicator active={row.IsActive} />
  //     },
  //   },
  // },
  GoZeroNotifications: {
    name: 'Notifications',
    details: {
      dataFormat: (_col: any, row: any) =>
        notificationText[row.GoZeroNotifications]
          ? notificationText[row.GoZeroNotifications]
          : 'N/A',
    },
  },
  PracticeFacilities: {
    name: 'Practice Facilities',
    details: {
      dataFormat: (_col: any, row: T.User) => {
        return (
          <div style={{ width: '400px' }}>
            {row.AllFacilities ? (
              <Chip
                size="small"
                label="All Facilities"
                style={{ backgroundColor: '#ffce74' }}
              />
            ) : (
              <TruncatedChips
                practiceFacilities={row.PracticeFacilities || []}
              />
            )}
          </div>
        )
      },
    },
  },
  Actions: {
    name: 'Actions',
    details: {
      dataFormat: (_col: any, row: T.User) => {
        return (
          <DeleteButton
            userId={row.UserID}
            userFullName={`${row.FirstName} ${row.LastName}`}
            refetch={refetchUsers}
          />
        )
      },
    },
  },
})

export const PracticeFacilityManagementModel = () => ({
  Name: {
    name: 'Name',
    details: {
      // sortName: 'Name',
    },
  },
  Address: {
    name: 'Address',
    details: {
      // sortName: 'Address',
      dataFormat: (_col: any, row: any) => row.Address.DisplayAddress,
    },
  },
  City: {
    name: 'City',
    details: {
      // sortName: 'City',
      dataFormat: (_col: any, row: any) => row.Address.City,
    },
  },
  State: {
    name: 'State',
    details: {
      // sortName: 'State',
      dataFormat: (_col: any, row: any) => row.Address.State,
    },
  },
})

export const AllPracticeFacilitiesPermissionsModel = (
  refetchPermissions: Function,
  notify: Function
) => ({
  Name: {
    name: '',
    details: {
      dataFormat: () => {
        return 'All Practice Facilities'
      },
    },
  },
  ReferralViewer: {
    name: 'Referral Viewer',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.ReferralViewer}
            onChange={createUpsertHandler(
              row,
              'ReferralViewer',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  ReferralCoordinator: {
    name: 'Referral Coordinator',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.ReferralCoordinator}
            onChange={createUpsertHandler(
              row,
              'ReferralCoordinator',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  InvoiceSubmitter: {
    name: 'Invoice Submitter',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.InvoiceSubmitter}
            onChange={createUpsertHandler(
              row,
              'InvoiceSubmitter',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  ViewQuarterlyReport: {
    name: 'View Quarterly Report',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.ViewQuarterlyReport}
            onChange={createUpsertHandler(
              row,
              'ViewQuarterlyReport',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  ViewPaidClaimsReport: {
    name: 'View Paid Claims Report',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.ViewPaidClaimsReport}
            onChange={createUpsertHandler(
              row,
              'ViewPaidClaimsReport',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
})

export const PracticeFacilityPermissionsModel = (
  refetchPermissions: Function,
  notify: Function
) => ({
  PracticeFacilityName: {
    name: 'Name',
  },
  ReferralViewer: {
    name: 'Referral Viewer',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.ReferralViewer}
            onChange={createUpsertHandler(
              row,
              'ReferralViewer',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  ReferralCoordinator: {
    name: 'Referral Coordinator',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.ReferralCoordinator}
            onChange={createUpsertHandler(
              row,
              'ReferralCoordinator',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  InvoiceSubmitter: {
    name: 'Invoice Submitter',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            checked={row.InvoiceSubmitter}
            onChange={createUpsertHandler(
              row,
              'InvoiceSubmitter',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
})

export const UserPermissionsModel = (
  refetchPermissions: Function,
  notify: Function
) => ({
  UserFullName: {
    name: 'Name',
    details: {
      dataFormat: (_col: any, row: any) => {
        return nameFormatter(row.FirstName, row.LastName)
      },
    },
  },
  ReferralViewer: {
    name: 'Referral Viewer',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            disabled={row.AllFacilities}
            checked={row.ReferralViewer}
            onChange={createUpsertHandler(
              row,
              'ReferralViewer',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  ReferralCoordinator: {
    name: 'Referral Coordinator',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            disabled={row.AllFacilities}
            checked={row.ReferralCoordinator}
            onChange={createUpsertHandler(
              row,
              'ReferralCoordinator',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
  InvoiceSubmitter: {
    name: 'Invoice Submitter',
    details: {
      dataFormat: (_col: any, row: any) => {
        return (
          <Switch
            disabled={row.AllFacilities}
            checked={row.InvoiceSubmitter}
            onChange={createUpsertHandler(
              row,
              'InvoiceSubmitter',
              refetchPermissions,
              notify
            )}
          />
        )
      },
    },
  },
})
