// import moment from 'moment'
import dateTime from '../../utils/dateTime'
import {
  STATUS_DRAFT,
  STATUS_SUBMITTED,
  STATUS_REJECTED,
  STATUS_ACCEPTED,
  STATUS_INVOICED,
  STATUS_IGNORED,
} from './statuses'

export const isEditableStatus = (statusID: number) => {
  switch (statusID) {
    case STATUS_DRAFT:
    case STATUS_IGNORED:
    case STATUS_REJECTED:
      return true
  }
  return false
}

export const validateDateOfService = (val: string | null) => {
  if (!val) return false
  const d = dateTime.parse(val)
  if (!d.isValid()) return false
  if (d.isAfter(dateTime.now())) return false
  if (d.isBefore(dateTime.now().add(-5, 'years'))) return false
  return true
}
