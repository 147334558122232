import React, { useEffect } from 'react'
import { Prompt } from 'react-router'

interface UnsavedChangesProps {
  message: string
  isDirty: boolean
}

export const UnsavedChanges = ({
  message = 'There are unsaved changes, are you sure you want to navigate off this page?',
  isDirty = false,
}: UnsavedChangesProps) => {
  useEffect(() => {
    setNavAwayMsg(isDirty, message)

    return () => {
      window.onbeforeunload = null
    }
  })

  const setNavAwayMsg = (dirty: boolean = false, message: string) => {
    if (dirty) {
      window.onbeforeunload = dirty && (() => message)
    }
  }

  return <Prompt when={isDirty} message={message} />
}
