import moment from 'moment'

export const date = {
  format: (date: string) => {
    const m = moment(date)
    if (m.isValid()) {
      return m.format('MM/DD/YYYY')
    }
    return ''
  },
  deformat: (date: string) => {
    const m = moment(date)
    if (m.isValid()) {
      return m.format('YYYY-MM-DD')
    }
    return null
  },
}
