/*
Usage:

  import useErrorHandlers from '../../hooks/useErrorHandlers

  export default function MyComponent() : any {
    const { catchAPIError } = useErrorHandlers()

    useEffect(() => {
      <someAJAXpromise>
        .then(() => {
          // whatever
        })
        .catch(catchAPIError({
          defaultMessage: 'No fun for you!',
          withError(err : any) {
            console.log('the original error passed to .catch is: ', err)
          }
        }))
    }, [catchAPIError])
  }
*/
import React, { useCallback } from 'react'
import useSnackbar, { SnackbarTypeError } from './useSnackbar'
import styled from 'styled-components'

interface catchAPIErrorOptions {
  defaultMessage?: string

  withError?(fn: (err: any) => {}): void

  duration?: number
}

const defaultDelay = 12000
const defaultMsg = 'Operation failed; please contact engineering'

export default function useErrorHandlers() {
  const { showForDuration: showSnackbarDuration } = useSnackbar()

  const catchAPIError = useCallback((opts: catchAPIErrorOptions = {}) => {
    return (err: any): void => {
      let display: any

      switch (true) {
        case Array.isArray(err.errList) && err.errList.length > 0:
          display = (
            <MultiErrorList>
              <MultiErrorTitle>One or more errors occurred:</MultiErrorTitle>
              {err.errList.map((m: any) => (
                <ErrorText key={m}>{m}</ErrorText>
              ))}
            </MultiErrorList>
          )
          break

        case !!err.Error?.Message:
          display = <ErrorText>{err.Error.Message}</ErrorText>
          break

        default:
          display = <ErrorText>{opts.defaultMessage || defaultMsg}</ErrorText>
      }

      showSnackbarDuration(
        display,
        SnackbarTypeError,
        opts.duration || defaultDelay
      )
      opts.withError && opts.withError(err)
    }
  }, [])

  return {
    catchAPIError,
  }
}

const MultiErrorList = styled.div``

const MultiErrorTitle = styled.span`
  display: block;
  font-size: 90%;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const ErrorText = styled.span`
  display: block;
  max-width: 65vw;
`
