import * as api from '../services/ApiService'

export const CLEAR_REFERRAL_FILES = 'CLEAR_REFERRAL_FILES'
export const GET_REFERRAL_FILES = 'GET_REFERRAL_FILES'
export const GET_REFERRAL_FILES_SUCCESS = 'GET_REFERRAL_FILES_SUCCESS'
export const GET_REFERRAL_FILES_ERROR = 'GET_REFERRAL_FILES_ERROR'
export const DEL_REF_FILE = 'DEL_REF_FILE'
export const DEL_REF_FILE_SUCCESS = 'DEL_REF_FILE_SUCCESS'
export const DEL_REF_FILE_ERR = 'DEL_REF_FILE_ERR'
export const REFERRAL_FILE = 'ref_file'
export const REFERRAL_INVOICE_FILE = 'referral_invoice'
export const REFERRAL_REQUEST_FILE = 'referral_request'

export const getReferralFileDownloadUrl = ({ id, fileId }: any) => {
  return api.get(`/referral/${id}/presign_download/${fileId}`, '')
}

export const getReferralInvoiceFileDownloadUrl = ({ id, fileId }: any) => {
  return api.get(`/referral_invoice/${id}/presign_download/${fileId}`, '')
}

export const getReferralRequestFileDownloadUrl = ({ id, fileId }: any) => {
  return api.get(`/referral_request/${id}/presign_download/${fileId}`, '')
}

export const clearRefFiles = () => {
  return {
    type: CLEAR_REFERRAL_FILES,
  }
}

export const getReferralFiles = ({ refId }: any) => {
  return api.get(`/referral/${refId}/file`, '')
}

export const getReferralFilesSuccess = (data: any) => {
  return {
    type: GET_REFERRAL_FILES_SUCCESS,
    payload: data.Data,
  }
}

export const getReferralFilesError = (data: any) => {
  const { Meta } = data
  return {
    type: GET_REFERRAL_FILES_ERROR,
    payload: data.Error,
    meta: {
      notification: {
        body: `Error getting files for this referral. Try to load the page again or contact your administrator with this code: ${Meta.RequestId}`,
        level: 'error',
      },
    },
  }
}

export const saveReferralFile = (params: any) => {
  const { id, refId, body } = params
  return api.put(`/referral/${refId}/file/${id}`, body)
}

export const saveNewReferralFile = ({ refId, body }: any) => {
  return api.post(`/referral/${refId}/file`, body)
}

export const delRefFile = ({ refId, id }: any) => {
  return api.del(`/referral/${refId}/file/${id}`)
}

export const delRefFileErr = (err: any) => {
  const { Meta } = err
  return {
    type: DEL_REF_FILE_ERR,
    payload: err.Error,
    meta: {
      notification: {
        body: `Error deleting file. Try again or contact your administrator with this code: ${Meta.RequestId}`,
        level: 'error',
      },
    },
  }
}

export const delRefFileSuccess = () => {
  return (data: any) => {
    return {
      type: DEL_REF_FILE_SUCCESS,
      payload: data.Data,
    }
  }
}

export const getPresignedUrlForUpload = (notifier: any) => {
  return (file: any, cb: Function) => {
    const params = {
      objectName: file.name,
      contentType: file.type,
      make_unique: true,
    }

    api
      .get('/presign_file', params)
      .then((data: any) => {
        if (data && data.Data) {
          cb({ signedUrl: data.Data.Url })
          notifier({ S3Key: data.Data.S3Key })
        } else {
          console.error('No signedUrl on response')
        }
      })
      .catch((error: any) => {
        console.error(error)
      })
  }
}

export const postPresignedUploadURL = (params: any) => {
  return api.post('/presign_file', params)
}
