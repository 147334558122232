import React, { useState } from 'react'
import { ReferralInvoiceFile } from '../Files'
import FileUploadModal from '../Files/FileUploadModal'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { AttachFile } from '@material-ui/icons'
import _ from 'lodash'
import useApi from '../../hooks/useApi'
import { ReferralInvoiceActions } from '../../actions'
import { parseS3Key } from '../../utils'
import { Chip, Grid } from '@material-ui/core'
import styled from 'styled-components'
import { postFileResponse } from '../FileUpload'
import { REFERRAL_INVOICE_FILE } from '../FileUpload/types'

const ChipGrid = styled(Grid)`
  margin-top: 5px;
`

const { delRefInvoiceFile, saveNewReferralInvoiceFile } = ReferralInvoiceActions

interface InvoiceFileUploadProps {
  files: any[]
  id: number
}

const invoiceFileUpload = (props: InvoiceFileUploadProps) => {
  const { show: showSnackbar } = useSnackbar()
  const [files, setFiles] = useState(props.files)
  const { data: fileTypes } = useApi({
    route: `/file_types`,
  })

  const handleFileDelete = (file: any) => {
    const { id } = props

    // SD-1486: monthly audit log surfaced that on some occassions, we'd been sending [DELETE]/gozero/referral_invoice/9519/file/undefined;
    // whereas fileID is undefined.
    const fileID = file.ID || file.FileID
    if (!fileID) {
      showSnackbar(
        'Unable to remove file at this time. If you see this message after multiple attempts, please contact your administrator.',
        SnackbarTypeError
      )
      return
    }

    delRefInvoiceFile({ refInvoiceId: id, id: fileID })
      .then((res: any) => {
        const filtered = _.filter(files, (f) => {
          return f.ID !== fileID && f.FileID !== fileID
        })
        setFiles(filtered)
      })
      .catch((err: any) => {
        if (
          !(
            err.Error &&
            err.Error.Message &&
            err.Error.Message.startsWith('User declined confirmation')
          )
        ) {
          showSnackbar(
            'Unable to remove file. Please try again or contact your administrator.',
            SnackbarTypeError
          )
        }
      })
  }

  const saveFile = (file: ReferralInvoiceFile) => {
    const { id } = props

    const body = {
      ReferralInvoiceID: id,
      FileTypeID: fileTypes.ref_inv_file,
      S3Key: parseS3Key(file.S3Key),
      Size: file.Size,
    }
    return new Promise<postFileResponse>((resolve, reject) => {
      saveNewReferralInvoiceFile({ refInvoiceId: id, body })
        .then((saved: any) => {
          const { Data } = saved
          if (Data && Data.FileID) {
            files.push(Data)
            setFiles(files)
            resolve(Data as postFileResponse)
          }
        })
        .catch((err: any) => {
          showSnackbar(
            'Unable to upload file. Please try again and contact your administrator if the issue continues.',
            SnackbarTypeError
          )
          reject(err)
        })
    })
  }

  const refreshFiles = (newFiles: Array<any>) => {
    setFiles([...files])
  }

  const handleFileClick = (file: any) => {
    const ID = file.ID ? file.ID : file.FileID
    // mini-hack - when a file is added it is returned as a decorated object with a FileID. When a file is loaded normally it just has an ID.
    window.open(
      `/download?id=${props.id}&type=${REFERRAL_INVOICE_FILE}&fileId=${ID}`,
      '_blank'
    )
  }

  const renderFiles = () => {
    if (files && files.length > 0) {
      return files.map((file: ReferralInvoiceFile) => {
        return (
          <ChipGrid container key={file.S3Key}>
            <Chip
              icon={<AttachFile />}
              clickable
              label={parseS3Key(file.S3Key)}
              onClick={() => handleFileClick(file)}
              onDelete={() => handleFileDelete(file)}
              variant="outlined"
              color="primary"
            />
          </ChipGrid>
        )
      })
    }
  }

  if (!fileTypes) return <></>
  return (
    <>
      <FileUploadModal
        FileUploadProps={{
          onSuccess: refreshFiles,
          defaultFileData: { FileTypeID: fileTypes.ref_inv_file },
          apiPostFile: saveFile,
          autoUniqueName: true,
        }}
      />
      {renderFiles()}
    </>
  )
}

export default invoiceFileUpload
