import * as api from './ApiService'

interface Permissions {
  ReferralViewer: boolean
  ReferralAdministrator: boolean
  ReferralCoordinator: boolean
  InvoiceSubmitter: boolean
  ViewQuarterlyReport: boolean
}

interface NewUser {
  FirstName: string
  LastName: string
  Email: string
}

export const createUser = async (organizationId: number, newUser: NewUser) => {
  return api.post(
    `/user-management/organization/${organizationId}/user`,
    newUser
  )
}

export const upsertPermission = async (
  permissionId: number,
  newPermissions: Permissions
) => {
  if (permissionId) {
    return api.put(
      `/user-management/permission/${permissionId}`,
      newPermissions
    )
  } else {
    return api.post(`/user-management/permission`, newPermissions)
  }
}

export const deletePermissions = async (
  organizationId: number,
  userId: number
) => {
  return api.del(
    `/user-management/organization/${organizationId}/user/${userId}/permissions`
  )
}

export const deleteUser = async (organizationId: number, userId: number) => {
  return api.del(
    `/user-management/organization/${organizationId}/user/${userId}`
  )
}
