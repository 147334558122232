import React from 'react'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useHistory } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'

import SearchField from '../SearchField'
import AutocompleteField from '../AutocompleteField'
import DataTable from '../DataTable'
import AddUserButton from './AddUserButton'
import { UserManagementModel } from '../../models/userManagement'
import useApi from '../../hooks/useApi'
import useQueryParams, { utils } from '../../hooks/useQueryParams'
import { User, UserListProps } from './types'
import { SortDirection } from '@material-ui/core'

const ActionsGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: auto auto 160px;
  margin: 10px 0px;
`

const LoadingUserList = () => {
  return (
    <div>
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
      <Skeleton width={800} height={60} />
    </div>
  )
}

const UserList: React.FC<UserListProps> = ({
  users,
  organizationId,
  refetch,
  count,
}) => {
  const hist = useHistory()
  const { params, setParams } = useQueryParams()
  const [range, setRange] = React.useState<any>()
  const [sort, setSort] = React.useState<any>()

  const onRowClick = (_evt: any, row: User) => {
    if (row.IsActive) {
      const { UserID } = row
      hist.push(
        `/user_management/organization/${organizationId}/users/${UserID}/facility_permissions`
      )
    }
  }

  const onChangePage = (change: any) => {
    const { range } = change
    const newParams = { ...params, range }
    refetch(newParams)
    setParams(newParams)
    setRange(range)
  }

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    const newParams = { ...params, sort: [col, dir] }
    refetch(newParams)
    setParams(newParams)
    setSort([col, dir])
  }

  const refetchWithParams = () => {
    refetch({ range, sort })
  }

  return (
    <DataTable
      keyProp="UserID"
      data={users}
      columns={UserManagementModel(refetchWithParams)}
      count={count}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangePage}
      onRowClick={onRowClick}
      isRowEditable={(row) => row.IsActive}
      sortHandler={sortColumns}
      sortable={undefined}
      allowEditing={false}
      initPage={1}
      initPageSize={10}
    />
  )
}

const Users: React.FC<{ organizationId: number }> = ({ organizationId }) => {
  const [searchText, setSearchText] = React.useState('')
  const [selectedFacility, setSelectedFacility] = React.useState<number>()
  const { params, setParams } = useQueryParams()

  const usersRequest = {
    route: `/user-management/organization/${organizationId}/users`,
  }

  const facilitiesRequest = {
    route: `/user-management/organization/${organizationId}/facilities_dropdown`,
  }

  const {
    data: users,
    meta,
    refetch: refetchUsers,
  } = useApi(organizationId ? usersRequest : undefined)

  const { data: facilities, refetch: refetchFacilities } = useApi(
    organizationId ? facilitiesRequest : undefined
  )

  const debouncedRefetchUsers = React.useCallback(debounce(refetchUsers, 500), [
    organizationId,
  ])

  React.useEffect(() => {
    refetchUsers(usersRequest)
    refetchFacilities(facilitiesRequest)
  }, [organizationId])

  const onSearch = (val: string) => {
    setSearchText(val)

    if (val && val.length > 3) {
      const newParams = utils.setFilter(params, { q: val })
      setParams(newParams)
      debouncedRefetchUsers(newParams)
    } else {
      const newParams = utils.removeFilter(params, 'q')
      setParams(newParams)
      debouncedRefetchUsers(newParams)
    }
  }

  const onFacilitySelect = (val: number) => {
    setSelectedFacility(val)

    const newParams = val
      ? utils.setFilter(params, { PracticeFacilityIDs: [val] })
      : utils.removeFilter(params, 'PracticeFacilityIDs')

    setParams(newParams)
    debouncedRefetchUsers(newParams)
  }

  const facilityOptions = facilities
    ? facilities.map((f: any) => ({ name: f.Name, value: f.ID }))
    : [{ name: 'Loading Options...', value: 0 }]

  return (
    <div>
      <ActionsGrid>
        <SearchField
          label="Search users"
          value={searchText}
          onChange={onSearch}
        />
        <AutocompleteField
          label="Select practice facilities"
          value={selectedFacility}
          onChange={onFacilitySelect}
          options={facilityOptions}
        />
        <AddUserButton />
      </ActionsGrid>
      {users ? (
        <UserList
          users={users}
          organizationId={organizationId}
          refetch={refetchUsers}
          count={(meta && meta.Total) || 0}
        />
      ) : (
        <LoadingUserList />
      )}
    </div>
  )
}

export default Users
