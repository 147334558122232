import React, { useState } from 'react'
import { Button, SortDirection } from '@material-ui/core'
import DataTable from '../DataTable'
import { ReferralModel } from '../../models/referral'
import { useReferralFilters, useReferralList } from './hooks'
import AddNewReferralBtn from './AddNewReferralButton'
import useQueryParams from '../../hooks/useQueryParams'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import { Archive } from '@material-ui/icons'
import { archiveReferral } from '../../actions/ReferralActions'
import SearchField from '../SearchField'
import AutocompleteField from '../AutocompleteField'
import SelectField from '../SelectField'
import useApi from '../../hooks/useApi'
import SwitchField from '../SwitchField'
import Tooltip from '../Tooltip'
import ManagedDateInput from '../Inputs/managedDateInput'

const ReferralList: React.FC = () => {
  const { params, setParams } = useQueryParams()
  const { data, meta, refetch, loading } = useReferralList()
  const [selected, setSelected] = useState<Array<number>>([])
  const { show: showSnackbar } = useSnackbar()

  const onChangePage = (change: any) => {
    const { range, page, pageSize } = change
    setParams({ ...params, range })
    refetch({ ...params, range })
  }

  const handleCheck = (rows: Array<any>) => {
    setSelected(rows.map((row) => row.ID))
  }

  const isRowCheckable = (row: any) => {
    return row.ArchivedAt === null
  }

  const onArchiveRow = (id: number) => {
    refetch({ ...params })
  }

  const handleBulkArchive = () => {
    archiveReferral(selected)
      .then(() => {
        refetch({ ...params })
        showSnackbar('Successfully Archived Referrals', SnackbarTypeSuccess)
      })
      .catch((err: string) => {
        showSnackbar(err, SnackbarTypeError)
      })
  }

  const {
    searchText,
    setSearchText,
    searchDob,
    setSearchDob,
    selectedOrg,
    setSelectedOrg,
    selectedStatus,
    setSelectedStatus,
    selectedEmployer,
    selectedReferrer,
    setSelectedEmployer,
    setSelectedReferrer,
    archived,
    setArchived,
    isUrgent,
    setIsUrgent,
  } = useReferralFilters(refetch)

  const { data: organizations } = useApi({
    route: '/referral_organization_filter',
  })

  const { data: statuses } = useApi({
    route: '/combined_statuses',
  })

  const { data: employers } = useApi({
    route: '/referral_employers',
  })

  const { data: referrers } = useApi({
    route: '/referral_referrers',
  })

  const sortColumns = (sortable: {
    col: string
    dir: 'ASC' | 'DESC' | SortDirection | false
  }) => {
    const { col, dir } = sortable
    setParams({ ...params, sort: [col, dir] })
    refetch({ ...params, sort: [col, dir] })
  }

  const sortable = params.sort
    ? { col: params.sort[0], dir: params.sort[1] }
    : undefined

  if (!data || !meta) return null
  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flexWrap: 'wrap',
          alignItems: 'flex-end',
          marginTop: '15px',
        }}>
        <SearchField
          label="Search Referrals"
          value={searchText}
          onChange={setSearchText}
        />
        <ManagedDateInput
          label="DOB"
          value={searchDob}
          setter={({ name, value }) => setSearchDob(value)}
          fullWidth={false}
        />
        {organizations && organizations.length > 1 && (
          <AutocompleteField
            label="Organization"
            value={selectedOrg}
            onChange={setSelectedOrg}
            options={organizations.map((org: any) => ({
              name: org.OrganizationName,
              value: org.OrganizationID,
            }))}
          />
        )}
        <SelectField
          multiple
          label="Status"
          value={selectedStatus}
          onChange={setSelectedStatus}
          options={statuses.map((stat: any) => ({
            name: stat.Descr,
            value: stat.ID,
          }))}
        />
        {employers && employers.length > 1 && (
          <SelectField
            multiple
            label="Employer"
            value={selectedEmployer}
            onChange={setSelectedEmployer}
            options={employers.map((stat: any) => ({
              name: stat.EmployerName,
              value: stat.EmployerID,
            }))}
          />
        )}
        {referrers && referrers.length > 1 && (
          <SelectField
            multiple
            label="Referrer"
            value={selectedReferrer}
            onChange={setSelectedReferrer}
            options={referrers.map((stat: any) => ({
              name: stat.ReferrerName,
              value: stat.ReferrerID,
            }))}
          />
        )}
      </div>
      <div style={{ display: 'flex', marginTop: 15 }}>
        <AddNewReferralBtn />
        {selected.length ? (
          <Button
            style={{ marginLeft: 20 }}
            color="primary"
            variant="outlined"
            onClick={handleBulkArchive}
            startIcon={<Archive />}>
            Archive Selected Referrals
          </Button>
        ) : null}
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          <SwitchField
            label="Show Archived"
            value={archived}
            onChange={setArchived}
          />
          <Tooltip title="Applies a filter to display only referrals marked as urgent">
            {/* SwitchField doesn't accept refs, which is required for tooltips; wrapping in a div fixes this */}
            <div>
              <SwitchField
                label="Show Only Urgent"
                value={isUrgent}
                onChange={setIsUrgent}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <DataTable
        keyProp="ID"
        data={data}
        checkHandler={handleCheck}
        isRowCheckable={isRowCheckable}
        columns={ReferralModel({ onArchive: onArchiveRow })}
        count={meta.Total}
        initPage={params.page || 1}
        initPageSize={params.pageSize || 10}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangePage}
        sortHandler={sortColumns}
        sortable={sortable}
        loading={loading}
        allowEditing={false}
      />
    </>
  )
}

export default ReferralList
