import React, { useState, useCallback, useEffect } from 'react'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import { green, amber } from '@material-ui/core/colors'
import useSnackbar from '../../hooks/useSnackbar'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

enum TYPES {
  ERROR = 'error',
  SUCCESS = 'success',
}

const styles = (theme: any) => {
  return {
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconType: {
      fontSize: 20,
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}

interface SnackMessageComponentProps {
  classes: ClassNameMap<string>
}

const SnackMessageComponent = ({ classes }: SnackMessageComponentProps) => {
  const [init, setInit] = useState(false)
  const [msg, setMsg] = useState('')
  const [type, setType] = useState<TYPES | undefined>()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!init) {
      setInit(true)
      console.debug('initializing...')
      addEventListener('zeroShowSnackbar', ((e: CustomEvent) => {
        const { detail } = e
        const { type, msg } = detail
        setType(type)
        setMsg(msg)
        setOpen(true)
      }) as EventListener)
      addEventListener('zeroCloseSnackbar', (e) => {
        setOpen(false)
      })
    }
  }, [])

  const { close } = useSnackbar()
  const handleClose = useCallback((_event?: any, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    close()
  }, [])

  const duration = type === TYPES.SUCCESS ? 4000 : 15000

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={duration}>
      <SnackbarContentWrapper
        classes={classes}
        onClose={handleClose}
        type={type}
        message={msg}
      />
    </Snackbar>
  )
}

const typeIcons = {
  success: CheckCircleIcon,
  error: ErrorIcon,
}

interface SnackbarContentWrapperProps {
  classes: ClassNameMap<string>
  type: TYPES | undefined
  message: string
  onClose: (event?: any, reason?: string) => void
}

const SnackbarContentWrapper = ({
  classes,
  type,
  message,
  onClose,
}: SnackbarContentWrapperProps) => {
  if (!type || !message) return <div />

  const Icon = typeIcons[type]

  return (
    <SnackbarContent
      className={classes[type]}
      message={
        <span className={classes.message}>
          <Icon className={classes.iconType} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          color="inherit"
          className={classes.close}
          onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  )
}

export const SnackMessage = withStyles(styles)(SnackMessageComponent)
