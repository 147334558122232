import React from 'react'
import { FormControl, FormControlLabel, Switch } from '@material-ui/core'

interface Props {
  value: boolean
  onChange: (val: boolean) => void
  label?: string
  disabled?: boolean
}

const SwitchField: React.FC<Props> = (props) => {
  return (
    <FormControl>
      <FormControlLabel
        label={props.label}
        control={
          <Switch
            color="primary"
            checked={props.value}
            onChange={(evt) => props.onChange(evt.target.checked)}
            disabled={props.disabled}
          />
        }
      />
    </FormControl>
  )
}

export default SwitchField
