import * as api from '../services/ApiService'

export type GetOrgFacilitiesParams = {
  CalcDistanceForEmployerMemberID?: number
}

export const getOrgFacilities = (
  orgID: number,
  params: GetOrgFacilitiesParams
) => {
  return api.get(`/organization/${orgID}/practice_facility`, params)
}
