// @ts-ignore
import React from 'react'
// @ts-ignore
import dayjs from 'dayjs'
// @ts-ignore
import pluginCustomParseFormat from 'dayjs/plugin/customParseFormat'
// @ts-ignore
import pluginUTC from 'dayjs/plugin/utc'
// @ts-ignore
import pluginIsSameOrBefore from 'dayjs/plugin/isSameOrBefore'
// @ts-ignore
import pluginIsSameOrAfter from 'dayjs/plugin/isSameOrAfter'
// @ts-ignore
import pluginCalendar from 'dayjs/plugin/calendar'
// @ts-ignore
import pluginAdvancedFormat from 'dayjs/plugin/advancedFormat'
// @ts-ignore
import pluginTimezone from 'dayjs/plugin/timezone'
// @ts-ignore
import pluginRelativeTime from 'dayjs/plugin/relativeTime'

// load + register all plugins/extensions for dayjs
dayjs.extend(pluginCustomParseFormat)
dayjs.extend(pluginUTC)
dayjs.extend(pluginIsSameOrBefore)
dayjs.extend(pluginIsSameOrAfter)
dayjs.extend(pluginCalendar)
dayjs.extend(pluginAdvancedFormat)
dayjs.extend(pluginTimezone)
dayjs.extend(pluginRelativeTime)

// enumerates the specific values the formatter can accept, and is purposefully very limited.
// Notably, calendar is a keyword here the formatter knows about specifically, whereas all the
// other values are actual strings
type displayFormatOptions =
  | formats.ISODate
  | formats.AmericanDate
  | formats.ISOTimestamp
  | formats.PrettyVerbose
  | formats.YYYYMMDD_HHmmss
  | formats.Calendar

enum formats {
  ISODate = 'YYYY-MM-DD',
  AmericanDate = 'MM/DD/YYYY',
  ISOTimestamp = 'YYYY-MM-DDTHH:mm:ss',
  PrettyVerbose = 'MMM Do YYYY, h:mm:ss a',
  YYYYMMDD_HHmmss = 'YYYYMMDD_HHmmss',
  Calendar = 'calendar',
}

const presets = {
  Y1890: dayjs().year(1890).month(0).date(1),
  Y1980: dayjs().year(1980).month(0).date(1),
  Y2015: dayjs().year(2015).month(0).date(1),
}

class Restricted {
  #djs: dayjs.Dayjs // "private"
  constructor(djs: dayjs.Dayjs) {
    this.#djs = djs
  }
  isValid(): boolean {
    return this.#djs.isValid()
  }
  format(
    f: displayFormatOptions = formats.AmericanDate,
    returnOnInvalid: any = null
  ): string | any {
    if (!this.#djs.isValid()) return returnOnInvalid
    return f === formats.Calendar ? this.#djs.calendar() : this.#djs.format(f)
  }
  isEmpty(): boolean {
    if (!this.isValid()) return true
    if (this.isBefore(presets.Y1890)) return true
    return false
  }
  isAfter(a: any, b?: any): boolean {
    // https://day.js.org/docs/en/query/is-after
    return this.#djs.isAfter(a instanceof Restricted ? a.#djs : a, b)
  }
  isBefore(a: any, b?: any): boolean {
    // https://day.js.org/docs/en/query/is-before
    return this.#djs.isBefore(a instanceof Restricted ? a.#djs : a, b)
  }
  isSameOrBefore(a: any, b?: any): boolean {
    // https://day.js.org/docs/en/query/is-same-or-before
    return this.#djs.isSameOrBefore(a instanceof Restricted ? a.#djs : a, b)
  }
  isSameOrAfter(a: any, b?: any): boolean {
    // https://day.js.org/docs/en/query/is-same-or-after
    return this.#djs.isSameOrAfter(a instanceof Restricted ? a.#djs : a, b)
  }
  isSame(a: any, b?: any): boolean {
    // https://day.js.org/docs/en/query/is-same
    return this.#djs.isSame(a instanceof Restricted ? a.#djs : a, b)
  }
  add(a: any, b?: any): Restricted {
    // https://day.js.org/docs/en/manipulate/add
    return new Restricted(this.#djs.add(a, b))
  }
  fromNow(): string {
    // https://day.js.org/docs/en/display/from-now
    if (!this.#djs.isValid()) return '(?)'
    return this.#djs.fromNow()
  }
  diff(x: Restricted, unit: dayjs.UnitType = 'millisecond'): any {
    // https://day.js.org/docs/en/display/difference
    if (!x) return '(?)'
    if (!this.#djs.isValid() || !x.#djs.isValid()) return '(?)'
    return this.#djs.diff(x.#djs, unit) // unit defaults to milliseconds, and this matches the dayjs API default WITHOUT the unit argument
  }
  year(): number {
    return this.#djs.year()
  }
  local(): Restricted {
    return new Restricted(this.#djs.local())
  }
  // Notably absent from this class is any notion of magic, like auto .toString()
  // or any other methods. THIS IS ON PURPOSE. We want consumers of this to be forced
  // to use it correctly (eg. having to call .format()). Also, this makes it so that
  // if the object ever gets marshalled to json, it'll send `{}`, which the back end
  // should fail on, therefore we know we have to do something more.
}

const validationFns = {
  dateOfBirth(x: any): boolean {
    const d = parse(x)
    if (!d.isValid()) return false
    return d.isAfter(presets.Y1890) && d.isSameOrBefore(now(), 'day')
  },
  benefitStart(x: any): boolean {
    const d = parse(x)
    if (!d.isValid()) return false
    return d.isAfter(presets.Y1980) && d.isBefore(now().add(3, 'months'), 'day')
  },
  benefitEnd(x: any): boolean {
    const d = parse(x)
    if (!d.isValid()) return false
    return d.isAfter(presets.Y1980) && d.isBefore(now().add(6, 'months'), 'day')
  },
  isActive(start: any, end: any): boolean {
    const s = parse(start)
    if (!s.isValid()) return false
    const n = now()
    if (s.isAfter(n, 'day')) return false
    const e = parse(end)
    if (e.isValid()) {
      if (e.isSameOrBefore(s, 'day')) return false
      if (e.isSameOrBefore(n, 'day')) return false
    }
    return true
  },
}

/*
parse takes a <anything> and tries to turn it into a dayjs object, that is then returned
bundled (privately) within a Restricted class - and that class has a very limited subset
of methods callers can do with it - purposefully. Notably, when its a string (most of the 
time), it quacks to see if its a date-ish (does it lack indicators of a timestamp component), 
and if so, it returns the UTC 00:00 time part for that date (and auto-limits the valid 
parsing formats).
*/
function parse(
  x: Restricted | dayjs.Dayjs | Date | string | null | undefined
): Restricted {
  if (!x) return new Restricted(dayjs(null)) // .isValid() will be false

  // If it's already a Restricted, send it straight back
  if (x instanceof Restricted) return x

  // If its a dayjs object, send it straight back wrapped in Restricted
  if (dayjs.isDayjs(x)) return new Restricted(x)

  // If it's a string (most likely case)...
  if (typeof x === 'string') {
    // if it quacks like a date-only string (no timestamp component)
    if (!x.includes(':')) {
      // loosen up our parsing restrictions and allow dates like 1/2/2013 (without 0-pad),
      // but don't add that to the list of enumerated formats because we never want to allow that as an output
      return new Restricted(
        dayjs(x, [formats.ISODate, formats.AmericanDate, 'M/D/YYYY'], true)
      )
    }
    return new Restricted(
      dayjs(x, [formats.ISOTimestamp, `${formats.ISOTimestamp}[Z]`], true).utc(
        true
      )
    )
  }

  // If it's a native JS date type (this is very much not ideal, and anywhere
  // that sends this, we want to update)
  if (x instanceof Date) {
    console.warn('[DATETIME.TSX] .parse() received a native Date() object')
    return new Restricted(dayjs(x))
  }

  // This seems aggressive, but anywhere trying to pass something in that doesn't
  // fit the above types, we want to throw an error and go w/ fail-fast strategy
  // (make it overly obvious the caller needs work)
  throw new Error('Invalid value passed to dateTime.parse')
}

/*
now returns a dayjs object with the current time; optionally if UTC=true it'll return
the current time in UTC. Although rare, if you ever needed to set a timestamp (which we know
should always be recorded in UTC), you'd pass utc=true.
*/
function now(utc = false): Restricted {
  return new Restricted(utc ? dayjs.utc() : dayjs())
}

/*
cellFormatter returns a function that conforms to the standard table callback formatter,
letting callers defined the formatting template to use (should be one of formats.<const>)
most of the time. This should handle 95% of use cases where tables need formatters; if you
need something more advanced for dates, build it where it's used (**DO NOT ADD IT TO THIS
FILE**).
*/
function cellFormatter(
  f: displayFormatOptions = formats.AmericanDate
): (cell: any, _row: any) => React.ReactElement {
  return (cell: any, _row: any): React.ReactElement => {
    const d = parse(cell)
    // @ts-ignore
    if (!d.isValid()) return <span>N/A</span>
    // @ts-ignore
    return (
      <span
        title={d.format(formats.PrettyVerbose)}
        style={{ whiteSpace: 'nowrap' }}>
        {d.format(f)}
      </span>
    )
  }
}

/************************************************************************************
EVERY SINGLE THING BENEATH HERE SHOULD NOT BE USED FOR ANYTHING NEW; we're trying to
standardize around a couple flexible methods with good APIs that can accommodate
whatever we need, instead of having fifty very-duplicative-but-slightly-different
methods all over the place.
************************************************************************************/
const exports = {
  _dayjs: dayjs,
  formats,
  presets,
  validationFns,
  parse,
  now,
  cellFormatter,
}
export default exports
// @ts-ignore; here for experimenting in the console
window['_dt'] = exports
