import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import axios, { AxiosResponse } from 'axios'
import { useDropzone } from 'react-dropzone'
import {
  coordinator,
  customDisplayProps,
  decoratedFile,
  fileFields,
  managedFileCollection,
  postFileArgs,
  postFileResponse,
  presignResponse,
  statuses,
} from './types'
import { postPresignedUploadURL } from '../../actions/FileActions'
import { Button } from '@material-ui/core'
import { CloudUpload as IconUpload } from '@material-ui/icons'
import DefaultSingleFileInput from './DefaultSingleFileInput'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { makeRandomString } from '../../utils'
import styled from 'styled-components'

export type {
  customDisplayProps,
  fileFields,
  postFileArgs,
  postFileResponse,
  decoratedFile,
  presignResponse,
  managedFileCollection,
  coordinator,
}

export interface props {
  multiple?: boolean
  acceptPresets?: { [index: string]: string[] } // https://react-dropzone.js.org/#section-accepting-specific-file-types
  allowCopyPaste?: boolean
  autoSign?: boolean
  autoConfirm?: boolean
  hideControlButtons?: boolean
  autoUniqueName?: boolean
  useUUIDs?: boolean
  defaultFileData?: fileFields

  capturePresignResponse?(res: presignResponse, df: decoratedFile): void

  apiPostPresignURL?(payload: any, df: decoratedFile): Promise<presignResponse>

  transformPostFilePayload?(payload: postFileArgs): postFileArgs

  apiPostFile(payload: postFileArgs): Promise<postFileResponse>

  subscribeAllProgress?(_: coordinator): void

  onSuccess?(_: postFileResponse[]): void

  CustomFileDisplay?: React.FC<customDisplayProps> & any
  debug?: boolean
}

const Uploader = forwardRef(function FileUpload(
  {
    multiple = false,
    acceptPresets = {
      'application/pdf': [],
      'image/*': [],
      'video/*': [],
      'audio/mpeg': [],
      'audio/wav': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        [],
      'text/plain': [],
      'text/csv': [],
      'application/rtf': [],
      'application/json': [],
      'application/xml': [],
      'application/zip': [],
      'application/x-rar-compressed': [],
      'application/x-tar': [],
      'application/gzip': [],
      'message/rfc822': [], // emails
    },
    allowCopyPaste = true,
    autoSign = false,
    autoConfirm = true,
    hideControlButtons = false,
    autoUniqueName = false,
    useUUIDs = false,
    defaultFileData,
    capturePresignResponse,
    apiPostPresignURL = DefaultAPIPostPresignURL,
    transformPostFilePayload = DefaultTransformPostFilePayload,
    apiPostFile,
    subscribeAllProgress = (_: coordinator) => {} /* no-op */,
    onSuccess = (_: postFileResponse[]) => {} /* no-op */,
    CustomFileDisplay = DefaultSingleFileInput,
    debug = false,
  }: props,
  ref: any
): React.ReactElement | null {
  const [domFiles, setDOMFiles] = useState<{ [index: string]: File }>({})
  const [fileManager, setFileManager] = useState<coordinator>({
    status: null,
    managedFiles: null,
  })
  const { showForDuration: showSnackbarDuration } = useSnackbar()
  const onDrop = useCallback(fnOnFilesChanged, [setDOMFiles])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    accept: acceptPresets,
    onDrop: onDrop,
  })

  /*
    Support passing a 'ref' to this component, and bind an external API
    that parent components can interact with.
  */
  useImperativeHandle(
    ref,
    () => ({
      doPresign,
      doConfirmFiles,
      reset: () => {
        setDOMFiles({})
      },
    }),
    [doPresign, doConfirmFiles, setDOMFiles]
  )

  /*
    Bind paste even handler to the *window*, so users can copy/paste
    files to upload.
  */
  useEffect(() => {
    window.addEventListener('paste', pasteHandler, false)
    return () => {
      window.removeEventListener('paste', pasteHandler, false)
      setDOMFiles({})
      setFileManager({ status: null, managedFiles: null })
    }
  }, [])

  /*
    Watch for domFiles changes; if the domFiles list changes,
    that is the "event" (a state update) that triggers setting up the
    fileManager state.
  */
  useEffect(() => {
    if (!Object.keys(domFiles).length) {
      setFileManager({ status: null, managedFiles: null })
      return
    }
    const managedFiles: managedFileCollection = fileManager.managedFiles || {}
    for (let key in domFiles) {
      managedFiles[key] = {
        key,
        domFileObj: domFiles[key],
        presignResponse: null,
        progress: 0,
        err: null,
        fileRec: null,
        postFileData: Object.assign({}, defaultFileData),
        objectURL: (window.URL || window.webkitURL).createObjectURL(
          domFiles[key]
        ),
      }
    }
    setFileManager({ status: statuses.START, managedFiles })
  }, [domFiles, setFileManager])

  /*
    This is the crux of how state is managed in this whole component.
    It's helpful to understand the three major concerns w/ coordinating state:

      1) We're working with multiple, chained (effectively, at least) promises:
         Presign -> Upload -> PostFile

      2) ... then we're tracking the outcomes of each of those asynchronous events
         across a **collection of one or more files**.

      3) ... the result of any of the chained promises determines whether the next
         phase (upload/filefile) should continue or cancel, or whether the *entire*
         operation should be paused.

    Each file that should be uploaded is decorated with a bunch of meta data, informed
    by the progress of events above ^. IOW: we track all that state together, and when
    observed with a useEffect() hook, it triggers a tonnnn of calls, and would be easy
    to create an infinite loop. The switch{} case below is the most important part of
    mitigating that.
  */
  useEffect(() => {
    if (!fileManager.managedFiles) {
      return
    }
    switch (fileManager.status) {
      case statuses.IN_FLIGHT:
        subscribeAllProgress(fileManager)
        return
      case statuses.DONE:
        subscribeAllProgress(fileManager)
        setFileManagerStatus(null)
        const c = { ...fileManager.managedFiles } as managedFileCollection
        onSuccess(
          Object.keys(c).map((key: any): postFileResponse => {
            return c[key].fileRec?.Data as postFileResponse
          })
        )
        return
      case statuses.START:
        subscribeAllProgress(fileManager)
        autoSign && doPresign()
        return
      case statuses.SIGNED:
        subscribeAllProgress(fileManager)
        doUpload()
        return
      case statuses.UPLOADED:
        subscribeAllProgress(fileManager)
        autoConfirm && doConfirmFiles()
        return
    }
  }, [fileManager, setFileManager, subscribeAllProgress, autoSign, autoConfirm])

  /*
    State helper: merge the passed object with the existing decoratedFile, by key
  */
  const assignToDecoratedFileByKey = useCallback(
    (key: string, v: decoratedFile & any) => {
      setFileManager((manager: coordinator): coordinator => {
        if (!manager.managedFiles) {
          return manager
        }
        Object.assign(manager.managedFiles[key], v)
        return { ...manager }
      })
    },
    [setFileManager]
  )

  /*
     State helper: set the 'postFileData' on a decoratedFile -- UNUSED CURRENTLY
   */
  // const setPostFileData = useCallback((key:string, data:any) => {
  //   setFileManager((v:coordinator) : coordinator => {
  //     if (!v.managedFiles) { return v }
  //     v.managedFiles[key].postFileData = Object.assign({}, v.managedFiles[key].postFileData, data, defaultFileData)
  //     return {...v}
  //   })
  // }, [setFileManager])

  /*
    State helper: set the 'err' property on a decoratedFile
  */
  const setErrOnManageFile = useCallback(
    (key: string, err: Error & any) => {
      assignToDecoratedFileByKey(key, {
        err:
          (Array.isArray(err?.errList) && err.errList[0]) ||
          ((err as any)?.message && err.message) ||
          err,
      })
    },
    [setFileManager, assignToDecoratedFileByKey]
  )

  /*
    State helper: set the 'status' property on the fileManager
  */
  const setFileManagerStatus = useCallback(
    (s: statuses | null) => {
      setFileManager((c: coordinator) => {
        return { ...c, status: s }
      })
    },
    [setFileManager]
  )

  function pasteHandler({ clipboardData }: React.ClipboardEvent & any) {
    // if copy/paste is disabled, do nothing
    if (!allowCopyPaste) {
      return
    }
    // if the status is **anything at all** besides null (the initial default state),
    // then DO NOT accept copy/pasted items
    if (!fileManager.status === null) {
      return
    }

    const { items } = clipboardData
    if (!(items || []).length) {
      return
    }

    const ff: File[] = []
    for (let i = 0, l = items.length; i < l; i++) {
      if (items[i].kind !== 'file') {
        continue
      }
      ff.push(items[i].getAsFile() as File)
    }
    if (!ff.length) {
      return
    }

    fnOnFilesChanged(ff)
  }

  function fnOnFilesChanged(files: File[]) {
    if (!files.length) {
      setDOMFiles({})
      return
    }
    const newFile = files.reduce((curr: any, f: File): any => {
      const key = makeRandomString()
      // @ts-ignore
      f._insurance = key
      curr[key] = f
      return curr
    }, {})
    setDOMFiles((oldFiles) => {
      return { ...oldFiles, ...newFile }
    })
  }

  /*
    Kickoff presign phase for all the tracked files
  */
  async function doPresign(): Promise<void> {
    setFileManagerStatus(statuses.IN_FLIGHT)

    try {
      const managedFiles = {
        ...fileManager.managedFiles,
      } as managedFileCollection
      await Promise.all(
        Object.keys(managedFiles).map(async (key: string): Promise<void> => {
          const curr = managedFiles[key]
          /*
            Scenario: three files, two errored at some point, but one made it all the way through
            the Presign->Upload->PostFile chain. A file record may have been successfully created. We DO NOT
            need to reupload this one. If the presign process is kicked off again (say manually, after
            fixing an error on a form input or something) and we find an instance where 'fileRec' is already
            populated - SKIP IT
          */
          if (curr.fileRec?.Data.FileID) return
          try {
            const res = await apiPostPresignURL(
              {
                ObjectName: curr.domFileObj.name,
                MakeUnique: autoUniqueName,
                UseUUID: useUUIDs,
                // This bit may look confusing: the FileTypeID will (read: *should*) only
                // ever be tracked in the `.postFileData` object of a managed file collection obj.
                // It's also the only other parameter the presign route cares about, and has to be
                // sent along for the presign to work.
                FileTypeID: defaultFileData?.FileTypeID || null,
              },
              curr
            )
            capturePresignResponse && capturePresignResponse(res, curr)
            assignToDecoratedFileByKey(key, {
              presignResponse: res,
              progress: 0,
              err: null,
            })
          } catch (e) {
            setErrOnManageFile(key, e)
            throw e
          }
        })
      )
      setFileManagerStatus(statuses.SIGNED)
    } catch (e) {
      setFileManagerStatus(statuses.ERRORED)
    }
  }

  /*
    Kickoff the upload phase for all the tracked files. Note: this depends on the presign(ers) having
    all completed. IOW - the promise that returns the presign URL for each file must have completed,
    for each file.
  */
  async function doUpload(): Promise<void> {
    setFileManagerStatus(statuses.IN_FLIGHT)

    try {
      const managedFiles = {
        ...fileManager.managedFiles,
      } as managedFileCollection

      for (let key in managedFiles) {
        if (!managedFiles[key].presignResponse?.Url) {
          throw new Error('Upload cannot proceed')
        }
      }

      if (!domFiles) {
        throw new Error(
          'Selected file list appears to be out-of-sync (uploading cannot proceed). Please contact engineering ASAP.'
        )
      }

      /*
      In English, since this isn't particularly readable:
        "a(wait) for <every tracked file from the collection>, which is mapped
        into a Promise, to complete"
      */
      await Promise.all(
        Object.keys(managedFiles).map(async (key: string): Promise<any> => {
          const f = domFiles[key]
          /*
          SUPER FREAKING IMPORTANT (and why state management is such a pain in the ass):
          notice that 'f' here comes from the 'domFiles' state (a key value POJO of
          {randomString : <HTMLFile>, ...}), and THAT is the file that we're passing to
          axios.put, to ship off to S3. A copy of that *also* lives in each <decoratedFile>
          of fileManager.managedFiles. However, since we're updating state so frequently (and asynchronously),
          if we were to assign: f = managedFiles[key].domFileObj, then pass to axios.put, _then state
          updates (like progress) occurred while it was already being uploaded_, then everything
          goes to hell fast.

          The ._insurance property we use here takes advantage of the fact that javascript has
          no rules, and you can randomly assign properties to anything (even native types, like an HTMLFile).
          So - when we initially populate the domFiles state, we assign ._insurance with a
          random string - which is *also* used as the key for fileManager.managedFiles[key]. We do this
          to not rely on numeric indices, where it'd be really easy to mix up iteration and
          accidentally send file[0] as the data for file[3].
        */
          // @ts-ignore
          if (!f || f?._insurance !== key) {
            throw new Error(
              'Selected file list appears to be out-of-sync (uploading cannot proceed). Please contact engineering ASAP.'
            )
          }
          /*
          Scenario: three files, two errored at some point, but one made it all the way through
          the Presign->Upload->PostFile chain. A file record may have been successfully created. We DO NOT
          need to reupload this one. If the presign process is kicked off again (say manually, after
          fixing an error on a form input or something) and we find an instance where 'fileRec' is already
          populated - SKIP IT
        */
          if (managedFiles[key].fileRec?.Data?.FileID) return
          try {
            await axios.put(managedFiles[key].presignResponse?.Url + '', f, {
              onUploadProgress: (ev: Event) => {
                const { loaded, total } = ev as any
                assignToDecoratedFileByKey(key, {
                  progress: Math.round((loaded / total) * 100),
                })
              },
            })
            assignToDecoratedFileByKey(key, { progress: 100 })
          } catch (e) {
            setErrOnManageFile(
              key,
              `File upload failed (${(e as any)?.message || e})`
            )
            throw e
          }
        })
      )
      // at this point, we've waited for all uploads to complete
      setFileManagerStatus(statuses.UPLOADED)
    } catch (e) {
      setFileManagerStatus(statuses.ERRORED)
      showSnackbarDuration(
        (e as any)?.message ||
          'Unhandled error occurred during upload (this operation likely failed). Please contact engineering ASAP.',
        SnackbarTypeError,
        3500
      )
    }
  }

  /*
    Kickoff the POST (create file record) route. Note: this depends on the uploaders having all
    completed (and the presigners before that).
  */
  async function doConfirmFiles(): Promise<any> {
    /*
      this is super important to set the status to IN_FLIGHT at the beginning here,
      so that the useEffect() up at the top that drives this whole coordination
      process doesn't receive an update with STATUS_UPLOAD, and re-trigger (concurrently)
      this doConfirmFiles stuff again.
    */
    setFileManagerStatus(statuses.IN_FLIGHT)

    /*
      Note: we collect all the payloads FIRST, then in a separate Promise.all, send all the
      payloads off. We **HAVE** to do it this way in order to support the transformPostFilePayload
      throwing an Error and not trigger any uploads (which is by design). Use case: three files, and
      the second one has invalid data: we should prevent any upload from occurring until that one is
      fixed, then we can do them all together and stay in sync.
    */
    try {
      const copied = { ...fileManager.managedFiles } as managedFileCollection
      const payloads: { [index: string]: postFileArgs } = {}
      for (let key in fileManager.managedFiles) {
        try {
          const curr = copied[key]
          if (!curr.presignResponse) {
            throw new Error(
              'Upload cannot proceed (missing presign information)'
            )
          }
          /*
            Scenario: three files, two errored at some point, but one made it all the way through
            the Presign->Upload->PostFile chain. A file record may have been successfully created. We DO NOT
            need to reupload this one. If the presign process is kicked off again (say manually, after
            fixing an error on a form input or something) and we find an instance where 'fileRec' is already
            populated - SKIP IT
          */
          if (curr.fileRec?.Data.FileID) return
          /*
            Three phases here to allow customizing the payload that ultimately
            gets sent to the POST file route:
              1) create a default ('merged') object, combing all options defaulted
                to null, and merging in any values stored in the .postFileData field (which
                should be one or more of any of the default fields)
              2) pass that to optional transformPostFilePayload, which is a function passed as
                a prop that can change the merged data; for example: setting the EmployerID,
                or formatting the EffectiveDate field
              3) send that to the apiPostFile (which is itself, possibly customized),
                if the uploader should be calling the final POST to a different endpoint
          */
          const merged = Object.assign(
            {
              FileTypeID: defaultFileData?.FileTypeID || null,
              S3Key: null,
              Size: null,
            },
            curr.postFileData
          )
          const payload = transformPostFilePayload(merged)
          // notice: we assign S3Key and Size *after* the customizer funcs
          // because these really shouldn't ever be mucked with (but if a
          // custom POST api route is used, it could happen there... just don't though)
          payload.S3Key = curr.presignResponse?.S3Key
          payload.Size = curr.domFileObj.size || null
          payloads[key] = payload
        } catch (e) {
          setErrOnManageFile(key, e)
          throw e
        }
      }

      await Promise.all(
        Object.keys(payloads).map(async (key: string): Promise<void> => {
          try {
            const res = await apiPostFile(payloads[key])
            assignToDecoratedFileByKey(key, { fileRec: { Data: res } })
          } catch (e) {
            setErrOnManageFile(key, e)
            throw e
          }
        })
      )
      setFileManagerStatus(statuses.DONE)
    } catch (e) {
      setFileManagerStatus(statuses.ERRORED)
      showSnackbarDuration(
        (e as any)?.message ||
          'Unhandled error occurred during upload (this operation likely failed). Please contact engineering ASAP.',
        SnackbarTypeError,
        3500
      )
    }
  }

  function statusIs(...ss: any): boolean {
    for (let i = 0, l = (ss || []).length; i < l; i++) {
      if (fileManager.status === ss[i]) return true
    }
    return false
  }

  const filesCanBeUploaded = () => {
    return (
      statusIs(statuses.START) || (statusIs(null) && !fileManager.managedFiles)
    )
  }

  return (
    <StyledUploadWrapper>
      {filesCanBeUploaded() ? (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="upload-trigger">
              <IconUpload fontSize="large" />
              <h5>
                {isDragActive
                  ? 'Drop file(s) here'
                  : 'Drop file(s) here or click to select'}
              </h5>
            </div>
          </div>
        </section>
      ) : null}

      {fileManager.managedFiles && (
        <>
          {Object.keys(fileManager.managedFiles).map((key: any) => {
            // @ts-ignore
            const f = fileManager.managedFiles[key]
            return <CustomFileDisplay key={`${key}`} file={f} />
          })}

          {!hideControlButtons &&
            (!autoSign || !autoConfirm) &&
            !statusIs(null, statuses.DONE, statuses.IN_FLIGHT) && (
              <div className="action-buttons">
                {!autoSign && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={doPresign}>
                    Upload File(s)
                  </Button>
                )}
                {!autoConfirm && fileManager.status === statuses.UPLOADED && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={doConfirmFiles}>
                    Save File(s)
                  </Button>
                )}
              </div>
            )}
        </>
      )}

      {debug && (
        <>
          <pre className="debug">{JSON.stringify(fileManager, null, '  ')}</pre>
        </>
      )}
    </StyledUploadWrapper>
  )
})

export default Uploader
export {
  Uploader,
  DefaultSingleFileInput,
  DefaultTransformPostFilePayload,
  DefaultAPIPostPresignURL,
}

function DefaultTransformPostFilePayload(payload: postFileArgs): postFileArgs {
  return payload
}

/*
Wrap/normalize API actions
*/
async function DefaultAPIPostPresignURL(
  payload: any,
  df: decoratedFile
): Promise<presignResponse> {
  return postPresignedUploadURL(payload).then((res: AxiosResponse & any) => {
    return res.Data as presignResponse
  })
}

const StyledUploadWrapper = styled.div`
  display: block;
  max-width: 100%;
  margin: 0 auto;

  .upload-trigger {
    width: 100%;
    min-height: 200px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .action-buttons {
    text-align: center;
  }

  pre.debug {
    margin: 1rem 0;
  }
`
